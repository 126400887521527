/* @flow */

import * as React from 'react';
import { logError, logInfo, logWarning } from '../../helpers/debug/debug';
import type { CombinedReducers } from '../../redux/reducers';
import { ExternalContentDisplayType } from '../../redux/appConf/types/types';
import { Localizer } from '@ntg/utils/dist/localization';
import type { SecurityInfoType } from './HeaderConstsAndTypes';
import { getBOSetting } from '../../redux/netgemApi/actions/helpers/boSettings';
import { getSettingValueByName } from '../../redux/netgemApi/actions/helpers/settings';
import { showExternalContentModal } from '../../redux/modal/actions';
import { useCallback } from 'react';

let securityInfoKey: string | null = null;
let securityInfoValue: SecurityInfoType = {};

const buildSecurityInfo: (state: CombinedReducers) => SecurityInfoType = (state) => {
  const {
    appConfiguration,
    appConfiguration: { distributorAppKeys, mainDistributorId },
    appRegistration: { applicationId, authenticationToken, deviceKey, subscriberId, userInfo },
  } = state;

  const cacheKey = [applicationId, authenticationToken, deviceKey, subscriberId].join('+');
  if (securityInfoKey !== null && cacheKey === securityInfoKey) {
    return securityInfoValue;
  }

  const customerId = getBOSetting('customerId', appConfiguration);
  const identity = getBOSetting('identity', appConfiguration);
  const name = getBOSetting('name', appConfiguration);
  const productId = getBOSetting('productId', appConfiguration);
  const device = getSettingValueByName('platform.vf', 'device', appConfiguration);
  const email = userInfo?.email;

  securityInfoKey = cacheKey;
  securityInfoValue = {
    applicationId,
    authenticationToken,
    customerId,
    device,
    deviceKey,
    email,
    identity,
    mainDistributorId,
    name,
    productId,
    subscriberId,
  };

  Object.entries(distributorAppKeys).forEach(([appKey, distributorId]) => {
    securityInfoValue[`appKey for ${((distributorId: any): string)}`] = appKey ?? '<missing appkey>';
  });

  return securityInfoValue;
};

const logSecurityInfo: (securityInfo: SecurityInfoType) => void = (securityInfo) => {
  logInfo('########### Security info ###########');
  Object.entries(securityInfo).forEach(([key, value]) => logInfo(`${key}: ${typeof value === 'string' ? value : ''}`));
  logInfo('#####################################');

  // Also copy authentication token to clipboard because it's commonly used by developers
  const { authenticationToken } = securityInfo;
  if (authenticationToken) {
    navigator.clipboard
      .writeText(authenticationToken)
      .then(() => logInfo('Authentication token was copied to clipboard.'))
      .catch(() => logError('Error copying authentication token to clipboard.'));
  }
};

const getHeaderClass: (isInTheaterMode: boolean, isLogoLoaded: boolean, isScrolling: boolean) => string = (isInTheaterMode, isLogoLoaded, isScrolling) => {
  let headerClass = 'header';

  if (isInTheaterMode) {
    headerClass = 'header slideUp';
  } else if (isLogoLoaded) {
    headerClass = 'header slideDown';
  }

  if (isScrolling) {
    headerClass = `${headerClass} scroll`;
  }

  return headerClass;
};

const renderSubscriptionPresentationButton: (displayType: ExternalContentDisplayType, url: string, dispatch: Function) => ?React.Element<any> = (displayType, url, dispatch) => {
  if (url === '') {
    return null;
  }

  if (displayType === ExternalContentDisplayType.NewTab) {
    // Link opening a new tab/page
    return (
      <a href={url} rel='noopener noreferrer' target='_blank'>
        {Localizer.localize('subscription.offers_presentation_button')}
      </a>
    );
  } else if (displayType === ExternalContentDisplayType.EmbeddedPopup) {
    // Button opening an in-app popup

    const onClickHandler = useCallback(() => {
      if (displayType === ExternalContentDisplayType.EmbeddedPopup) {
        // Open external page in in-app popup
        dispatch(showExternalContentModal(url));
      }
    }, [displayType, url]);

    return (
      <div className='link' onClick={onClickHandler}>
        {Localizer.localize('subscription.offers_presentation_button')}
      </div>
    );
  }

  // Case ExternalContentDisplayType.ExternalPopup is not supposed to happen
  logWarning(`Unexpected display type for offers presentation page: ${(displayType: string)}`);
  return null;
};

const renderFreeTrialButton: (isRegisteredAsGuest: boolean, hasButton: boolean, url: string) => ?React.Element<any> = (isRegisteredAsGuest, hasButton, url) => {
  if (!isRegisteredAsGuest || !hasButton || url === '') {
    return null;
  }

  const onClickHandler = useCallback(() => window.location.assign(url), [url]);

  return (
    <div className='freeTrial' onClick={onClickHandler}>
      {Localizer.localize('subscription.start_free_trial_button')}
    </div>
  );
};

export { buildSecurityInfo, getHeaderClass, logSecurityInfo, renderFreeTrialButton, renderSubscriptionPresentationButton };
