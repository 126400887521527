/* @flow */

import './ButtonFXBicolor.css';
import * as React from 'react';
import { HeightKind, WidthKind } from './types';
import { Theme, type ThemeType } from '@ntg/ui/dist/theme';
import ButtonFX from './ButtonFX';

type PropType = {|
  +data?: any, // eslint-disable-line react/require-default-props
  +hasPadding?: boolean, // eslint-disable-line react/require-default-props
  +heightKind?: HeightKind, // eslint-disable-line react/require-default-props
  +isDisabled?: boolean, // eslint-disable-line react/require-default-props
  +leftPart: string | React.Element<any>,
  +onClick?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>, data: any) => void, // eslint-disable-line react/require-default-props
  +rightPart: string | React.Element<any>,
  +theme?: ThemeType, // eslint-disable-line react/require-default-props
  +widthKind?: WidthKind, // eslint-disable-line react/require-default-props
|};

const ButtonFXBicolor = ({ data, hasPadding, heightKind, isDisabled = false, leftPart, onClick, rightPart, theme = Theme.Dark, widthKind }: PropType): React.Node => (
  <ButtonFX className='buttonFXBicolor' data={data} hasPadding={hasPadding} heightKind={heightKind} isDisabled={isDisabled} onClick={onClick} theme={theme} widthKind={widthKind}>
    <div className='part left'>{leftPart}</div>
    <div className='part right'>{rightPart}</div>
  </ButtonFX>
);

export default ButtonFXBicolor;
