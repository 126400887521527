/* @flow */

import {
  type NETGEM_API_V8_FEED_ITEM,
  type NETGEM_API_V8_ITEM_LOCATION,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT,
} from '../../libs/netgemLibrary/v8/types/FeedItem';
import { type NETGEM_API_V8_SCHEDULED_RECORDING, type NETGEM_RECORDINGS_MAP, type NETGEM_SCHEDULED_RECORDINGS_MAP, RecordingOutcome } from '../../libs/netgemLibrary/v8/types/Npvr';
import { type ITEM_RECORD_STATUS } from './Types';
import type { Undefined } from '@ntg/utils/dist/types';
import { hasRunningSeriesScheduledRecording } from './scheduledRecording';

const isRecordingMatching: (item: NETGEM_API_V8_FEED_ITEM, recording: NETGEM_API_V8_ITEM_LOCATION) => boolean = (item, recording) => {
  const {
    selectedLocation: { duration, scheduledEventDuration, scheduledEventStartDate, startDate },
  } = item;
  const localStart = scheduledEventStartDate || startDate;
  const localDuration = scheduledEventDuration || duration;

  const { scheduledEventDuration: recordingDuration, scheduledEventStartDate: recordingStart } = recording;

  return Boolean(localStart && localDuration) && recordingStart === localStart && recordingDuration === localDuration;
};

const searchProgramInRecordings: (
  programId: string,
  item: NETGEM_API_V8_FEED_ITEM,
  recordingList: NETGEM_RECORDINGS_MAP,
) => {
  failedRecordingId?: string,
  hasRecording: boolean,
  recordOutcome?: RecordingOutcome,
  warningScheduledEventId?: string,
  warningScheduledRecordingId?: string,
} = (programId, item, recordingList) => {
  const recordings = recordingList[programId];

  let failedRecordingId: Undefined<string> = undefined;
  let hasRecording = false;
  let recordOutcome: Undefined<RecordingOutcome> = undefined;
  let warningScheduledEventId: Undefined<string> = undefined;
  let warningScheduledRecordingId: Undefined<string> = undefined;

  if (recordings) {
    recordings.forEach((r) => {
      const { recordOutcome: outcome } = r;

      if (outcome) {
        if (recordOutcome !== RecordingOutcome.Recorded) {
          // Existing recording case
          recordOutcome = outcome;
          if (outcome !== RecordingOutcome.Recorded) {
            failedRecordingId = r.id;
          } else if (isRecordingMatching(item, r)) {
            failedRecordingId = undefined;
            hasRecording = true;
          }
        }
      } else {
        // Future recording case
        const { id, matchingScheduledRecordings } = r;
        if (matchingScheduledRecordings) {
          const scheduledRecordingInDanger = matchingScheduledRecordings.find((scheduledRecording) => scheduledRecording.recordOutcome !== RecordingOutcome.WillBeRecorded);
          if (scheduledRecordingInDanger) {
            warningScheduledEventId = id;
            ({ recordOutcome, id: warningScheduledRecordingId } = scheduledRecordingInDanger);
          }
        }
      }
    });
  }

  return {
    failedRecordingId,
    hasRecording,
    recordOutcome,
    warningScheduledEventId,
    warningScheduledRecordingId,
  };
};

const getEpisodeAndSeriesRecordStatus: (
  programId: string,
  metadataSeriesId: Undefined<string>,
  item: NETGEM_API_V8_FEED_ITEM,
  npvrRecordingsList: NETGEM_RECORDINGS_MAP,
  npvrRecordingsFuture: NETGEM_RECORDINGS_MAP,
  npvrScheduledRecordingsList: NETGEM_SCHEDULED_RECORDINGS_MAP,
  previewCatchupScheduledEventId: ?string,
) => ITEM_RECORD_STATUS = (programId, metadataSeriesId, item, npvrRecordingsList, npvrRecordingsFuture, npvrScheduledRecordingsList, previewCatchupScheduledEventId) => {
  const {
    selectedLocation: { id: scheduledEventId = '' },
    seriesId,
  } = item;

  let failedRecordingId: Undefined<string> = undefined;
  let hasRecording = false;
  let recordOutcome: Undefined<RecordingOutcome> = undefined;
  let warningScheduledEventId: Undefined<string> = undefined;
  let warningScheduledRecordingId: Undefined<string> = undefined;

  // HACK: recordings started in the user start margin does not appear in the future recording list any longer but their recordOutcome in the existing recording list is "Unknown"
  const {
    locType,
    selectedLocation: { recordOutcome: correctOutcome },
  } = item;
  const isRecordingFallback = locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING && correctOutcome === RecordingOutcome.Recorded;

  // Look into existing recordings
  ({ failedRecordingId, hasRecording, recordOutcome } = searchProgramInRecordings(programId, item, npvrRecordingsList));

  // Look into future recordings
  if (!hasRecording && !failedRecordingId && !recordOutcome) {
    ({ recordOutcome, warningScheduledEventId, warningScheduledRecordingId } = searchProgramInRecordings(programId, item, npvrRecordingsFuture));
  }

  const scheduledRecording = npvrScheduledRecordingsList[previewCatchupScheduledEventId ?? scheduledEventId];
  const hasScheduledRecording = Boolean(scheduledRecording) && ((scheduledRecording.records.length > 0 && hasRecording) || scheduledRecording.records.length === 0);
  const hasSeriesScheduledRecording = hasRunningSeriesScheduledRecording(seriesId ?? metadataSeriesId, npvrScheduledRecordingsList);

  if ((!hasRecording || recordOutcome === RecordingOutcome.Unknown) && isRecordingFallback) {
    return {
      failedRecordingId,
      hasRecording: true,
      hasScheduledRecording,
      hasSeriesScheduledRecording,
      recordOutcome: RecordingOutcome.Recorded,
      warningScheduledEventId,
      warningScheduledRecordingId,
    };
  }

  return {
    failedRecordingId,
    hasRecording,
    hasScheduledRecording,
    hasSeriesScheduledRecording,
    recordOutcome,
    warningScheduledEventId,
    warningScheduledRecordingId,
  };
};

const getScheduledRecordingIdFromRecordingId: (failedRecordingId: string, npvrScheduledRecordingsList: NETGEM_SCHEDULED_RECORDINGS_MAP) => ?string = (
  failedRecordingId,
  npvrScheduledRecordingsList,
) => {
  for (const scheduledRecording of Object.values(npvrScheduledRecordingsList)) {
    const { id: scheduledRecordingId, records } = ((scheduledRecording: any): NETGEM_API_V8_SCHEDULED_RECORDING);
    if (records.some((r) => r.id === failedRecordingId)) {
      return scheduledRecordingId;
    }
  }

  return null;
};

const searchScheduledRecordingInFuture: (
  scheduledRecordingId: string,
  futureRecordings: NETGEM_RECORDINGS_MAP | null,
) => {
  isScheduledRecordingFuturePresent: boolean,
  scheduledRecordingOutcome: RecordingOutcome,
} = (scheduledRecordingId, futureRecordings) => {
  let isScheduledRecordingFuturePresent = false;
  let scheduledRecordingOutcome: RecordingOutcome = RecordingOutcome.WillBeRecorded;

  if (futureRecordings === null) {
    // Latest retrieved future recordings have not changed (HTTP 304)
    return { isScheduledRecordingFuturePresent, scheduledRecordingOutcome };
  }

  const futureValues = ((Object.values(futureRecordings): any): Array<Array<NETGEM_API_V8_ITEM_LOCATION>>);

  for (let i = 0; i < futureValues.length; ++i) {
    const { [i]: locations } = futureValues;

    for (let j = 0; j < locations.length; ++j) {
      const {
        [j]: { matchingScheduledRecordings },
      } = locations;
      if (matchingScheduledRecordings) {
        for (let k = 0; k < matchingScheduledRecordings.length; ++k) {
          const {
            [k]: { id, recordOutcome },
          } = matchingScheduledRecordings;

          if (id === scheduledRecordingId) {
            isScheduledRecordingFuturePresent = true;
            if (recordOutcome !== RecordingOutcome.WillBeRecorded && recordOutcome !== RecordingOutcome.Rebroadcast && recordOutcome !== RecordingOutcome.OutdatedEpisode) {
              scheduledRecordingOutcome = recordOutcome;
              return { isScheduledRecordingFuturePresent, scheduledRecordingOutcome };
            }
          }
        }
      }
    }
  }

  return { isScheduledRecordingFuturePresent, scheduledRecordingOutcome };
};

const isItemRecordingOrScheduledRecording: (item: NETGEM_API_V8_FEED_ITEM) => boolean = (item) => {
  const { locType, selectedLocation } = item;

  return (
    // Recordings
    locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING ||
    // Scheduled recordings
    (locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT && typeof selectedLocation.matchingScheduledRecordings !== 'undefined' && selectedLocation.matchingScheduledRecordings.length > 0)
  );
};

export { getEpisodeAndSeriesRecordStatus, getScheduledRecordingIdFromRecordingId, isItemRecordingOrScheduledRecording, isRecordingMatching, searchScheduledRecordingInFuture };
