/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import {
  REDUX_MSG_REQUEST_NPVR_RECORDINGS_DELETE,
  REDUX_MSG_REQUEST_NPVR_RECORDINGS_FUTURE,
  REDUX_MSG_REQUEST_NPVR_RECORDINGS_LIST,
  REDUX_MSG_REQUEST_NPVR_RECORDINGS_METADATA,
  REDUX_MSG_REQUEST_NPVR_RECORDINGS_QUOTA,
  REDUX_MSG_REQUEST_NPVR_RECORDINGS_RETRY,
} from '../../constants';
import { filterAndSortPlaybackUrls, generateApiUrl } from '../helpers/api';
import { getRecordingsMap, getRecordingsStatus } from '../../../../libs/netgemLibrary/v8/helpers/Npvr';
import { updateExistingRecordings, updateFutureRecordings } from '../../../npvr/actions';
import type { CombinedReducers } from '../../../reducers';
import { CustomNetworkError } from '../../../../libs/netgemLibrary/helpers/CustomNetworkError';
import type { Dispatch } from '../../../types/types';
import { HttpStatus } from '../../../../libs/netgemLibrary/v8/constants/NetworkCodesAndMessages';
import type { NETGEM_API_V8_FEED_RESULT } from '../../../../libs/netgemLibrary/v8/types/FeedResult';
import type { NETGEM_API_V8_RECORDINGS_FUTURE } from '../../../../libs/netgemLibrary/v8/types/Npvr';
import { type NETGEM_API_V8_REQUEST_RESPONSE } from '../../../../libs/netgemLibrary/v8/types/RequestResponse';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';

const deleteRecording: (recordId: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (recordId, signal) =>
  (dispatch: Dispatch): Promise<any> =>
    // Get recording's eTag first
    dispatch(sendV8RecordingsMetadataRequest(recordId, signal)).then((getResponse: NETGEM_API_V8_REQUEST_RESPONSE) => {
      const { eTag } = getResponse;
      if (eTag) {
        // Then delete recording
        return dispatch(sendV8RecordingsDeleteRequest(recordId, eTag, signal));
      }

      return Promise.resolve();
    });

const deleteRecordings: (recordingIds: Array<string>, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (recordingIds, signal) =>
  (dispatch: Dispatch): Promise<any> => {
    const promises = recordingIds.map((recordingId) => dispatch(deleteRecording(recordingId, signal)));
    return Promise.all(promises);
  };

const getAllRecordings: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch): Promise<any> => {
    const promises = [dispatch(getExistingRecordings(signal)), dispatch(getFutureRecordings(signal))];
    return Promise.all(promises);
  };

const getExistingRecordings: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const state = getState();
    const {
      npvr: {
        eTagsCache: { npvrRecordingsList: initialETag },
      },
    } = state;

    return dispatch(sendV8RecordingsListRequest(initialETag, signal))
      .then((data: NETGEM_API_V8_REQUEST_RESPONSE) => {
        const { eTag: npvrRecordingsListETag, result } = data;
        const { feed: npvrRecordingsListFeed } = (result: NETGEM_API_V8_FEED_RESULT);
        const npvrRecordingsList = getRecordingsMap(npvrRecordingsListFeed);

        dispatch(updateExistingRecordings(npvrRecordingsList, npvrRecordingsListETag, npvrRecordingsListFeed));
      })
      .catch((error: CustomNetworkError) => {
        if (error.getStatus() === HttpStatus.NotModified) {
          return Promise.resolve();
        }

        // Error other than 304 (NOT MODIFIED)
        return Promise.reject(error);
      });
  };

const getFutureRecordings: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const state = getState();
    const {
      npvr: {
        eTagsCache: { npvrRecordingsFuture: initialETag },
      },
    } = state;

    return dispatch(sendV8RecordingsFutureRequest(initialETag, signal))
      .then((data: NETGEM_API_V8_REQUEST_RESPONSE) => {
        const { eTag: npvrRecordingsFutureETag, result } = data;
        const { feed: npvrRecordingsFutureFeed } = (result: NETGEM_API_V8_RECORDINGS_FUTURE);
        const npvrRecordingsFuture = getRecordingsMap(npvrRecordingsFutureFeed);
        const npvrRecordingsFutureStatus = getRecordingsStatus(result);

        dispatch(updateFutureRecordings(npvrRecordingsFuture, npvrRecordingsFutureETag, npvrRecordingsFutureFeed, npvrRecordingsFutureStatus));
        return npvrRecordingsFuture;
      })
      .catch((error: CustomNetworkError) => {
        if (error.getStatus() === HttpStatus.NotModified) {
          return Promise.resolve(null);
        }

        // Error other than 304 (NOT MODIFIED)
        return Promise.reject(error);
      });
  };

const sendV8RecordingsFutureRequest: (eTag: ?string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (eTag, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { npvrRecordingsFutureUrl },
    } = state;

    if (!npvrRecordingsFutureUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'npvrRecordingsFutureUrl' }));
    }

    const { authent, method } = npvrRecordingsFutureUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_NPVR_RECORDINGS_FUTURE, {
      authent,
      eTag,
      method,
      signal,
      uri: generateApiUrl(npvrRecordingsFutureUrl, {}, state),
    });
  };

const sendV8RecordingsListRequest: (eTag: ?string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (eTag, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { npvrRecordingsListUrl },
    } = state;

    if (!npvrRecordingsListUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'npvrRecordingsListUrl' }));
    }

    const { authent, method } = npvrRecordingsListUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_NPVR_RECORDINGS_LIST, {
      authent,
      eTag,
      method,
      signal,
      uri: generateApiUrl(npvrRecordingsListUrl, {}, state),
    });
  };

const sendV8RecordingsDeleteRequest: (assetId: string, eTag: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (assetId, eTag, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { npvrRecordingsDeleteUrl },
    } = state;

    if (!npvrRecordingsDeleteUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'npvrRecordingsDeleteUrl' }));
    }

    const { authent, method } = npvrRecordingsDeleteUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_NPVR_RECORDINGS_DELETE, {
      authent,
      eTag,
      method,
      signal,
      uri: generateApiUrl(npvrRecordingsDeleteUrl, { assetId }, state),
    });
  };

const sendV8RecordingsMetadataRequest: (assetId: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (assetId, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      appConfiguration: { playerStreamPriorities },
      netgemApi: { npvrRecordingsMetadataUrl },
      npvr,
      npvr: { eTagsCache },
    } = state;

    if (!npvrRecordingsMetadataUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'npvrRecordingsMetadataUrl' }));
    }

    const { authent, method } = npvrRecordingsMetadataUrl;
    const { [assetId]: eTag } = eTagsCache;

    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_NPVR_RECORDINGS_METADATA, {
      authent,
      eTag,
      method,
      signal,
      uri: generateApiUrl(npvrRecordingsMetadataUrl, { assetId }, state),
    })
      .then((response: NETGEM_API_V8_REQUEST_RESPONSE) => filterAndSortPlaybackUrls(response, playerStreamPriorities))
      .catch((error: CustomNetworkError) => {
        if (error.getStatus() === HttpStatus.NotModified) {
          // Get existing recording since it has not changed
          const {
            npvrRecordingsList: { [assetId]: result },
          } = npvr;
          const response: NETGEM_API_V8_REQUEST_RESPONSE = {
            cacheMaxAge: null,
            eTag: eTag ?? null,
            result,
            status: HttpStatus.NotModified,
          };
          return Promise.resolve(response);
        }

        // Error other than 304 (NOT MODIFIED)
        return Promise.reject(error);
      });
  };

const sendV8RecordingsQuotaRequest: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { npvrRecordingsQuotaUrl },
      npvr: {
        eTagsCache: { npvrRecordingsQuota: eTag },
      },
    } = state;

    if (!npvrRecordingsQuotaUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'npvrRecordingsQuotaUrl' }));
    }

    const { authent, method } = npvrRecordingsQuotaUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_NPVR_RECORDINGS_QUOTA, {
      authent,
      eTag,
      method,
      signal,
      uri: generateApiUrl(npvrRecordingsQuotaUrl, {}, state),
    });
  };

const sendV8RecordingsRetryRequest: (assetId: string, eTag: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (assetId, eTag, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { npvrRecordingsRetryUrl },
    } = state;

    if (!npvrRecordingsRetryUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'npvrRecordingsRetryUrl' }));
    }

    const { authent, method } = npvrRecordingsRetryUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_NPVR_RECORDINGS_RETRY, {
      authent,
      eTag,
      method,
      signal,
      uri: generateApiUrl(npvrRecordingsRetryUrl, { assetId }, state),
    });
  };

export { deleteRecording, deleteRecordings, getAllRecordings, getFutureRecordings, sendV8RecordingsMetadataRequest, sendV8RecordingsQuotaRequest, sendV8RecordingsRetryRequest };
