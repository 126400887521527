/* @flow */

import './Channel.css';
import * as React from 'react';
import { CHANNEL_IMAGE_HEIGHT, CHANNEL_IMAGE_WIDTH } from '../../../../helpers/ui/constants';
import { type ImageUrlType, getImageUrl } from '../../../../redux/netgemApi/actions/v8/metadataImage';
import type { Dispatch } from '../../../../redux/types/types';
import { Luminosity } from '@ntg/ui/dist/theme';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { ignoreIfAborted } from '../../../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';

type ReduxChannelDispatchToPropsType = {|
  +localGetImageUrl: (data: ImageUrlType, signal?: AbortSignal) => Promise<any>,
|};

type ReduxChannelReducerStateType = {||};

type ChannelPropType = {|
  +id: string,
  +imageId: ?string,
  +isUIDisabled: boolean,
  +name: string,
  +onClick: (id: string) => void,
|};

type CompleteChannelPropType = {|
  ...ChannelPropType,
  ...ReduxChannelDispatchToPropsType,
  ...ReduxChannelReducerStateType,
|};

type ChannelStateType = {|
  displayChannelName: boolean,
  imageUrl: string,
|};

const InitialState = Object.freeze({
  displayChannelName: false,
  imageUrl: '',
});

class ChannelView extends React.PureComponent<CompleteChannelPropType, ChannelStateType> {
  abortController: AbortController;

  constructor(props: CompleteChannelPropType) {
    super(props);

    this.abortController = new AbortController();

    this.state = { ...InitialState };
  }

  componentDidMount() {
    this.loadImage();
  }

  componentWillUnmount() {
    const { abortController } = this;

    abortController.abort('Component Channel will unmount');
  }

  loadImage = () => {
    const { imageId, localGetImageUrl } = this.props;
    const {
      abortController: { signal },
    } = this;

    if (!imageId) {
      // Channel (probably a FAST one) without image: display its name
      this.setState({ displayChannelName: true });
      return;
    }

    localGetImageUrl(
      {
        assetId: imageId,
        height: CHANNEL_IMAGE_HEIGHT,
        luminosity: Luminosity.Light,
        width: CHANNEL_IMAGE_WIDTH,
      },
      signal,
    )
      .then((imageUrl: string) => {
        signal.throwIfAborted();

        if (imageUrl !== '') {
          this.setState({ imageUrl });
        } else {
          // 404 HTTP code is generally not considered as an error for images but in the particular context of a channel image in the zapper, it is
          this.setState({ displayChannelName: true });
        }
      })
      .catch((error) => {
        ignoreIfAborted(signal, error);

        // Error loading channel image: display its name
        this.setState({ displayChannelName: true });
      });
  };

  handleOnClick = () => {
    const { id, onClick } = this.props;

    onClick(id);
  };

  render(): React.Node {
    const { isUIDisabled, name } = this.props;
    const { imageUrl, displayChannelName } = this.state;

    return (
      <div className={clsx('channel', isUIDisabled && 'disabled')} onClick={this.handleOnClick}>
        <div className='channelImage' style={{ backgroundImage: `url('${imageUrl}')` }} />
        {displayChannelName ? <div className='channelName'>{name}</div> : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxChannelDispatchToPropsType => {
  return {
    localGetImageUrl: (data: ImageUrlType, signal?: AbortSignal): Promise<any> => dispatch(getImageUrl(data, signal)),
  };
};

const Channel: React.ComponentType<ChannelPropType> = connect(null, mapDispatchToProps, null, { forwardRef: true })(ChannelView);

export default Channel;
