/* @flow */

import './PlayerController.css';
import * as React from 'react';
import { ContentType, TIMESHIFT_THRESHOLD } from '../constantsAndTypes';
import { type NETGEM_API_V8_FEED_ITEM, NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_METADATA_PROGRAM, NETGEM_API_V8_METADATA_SERIES } from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import {
  PictoBigPlay,
  PictoCog,
  PictoCompress,
  PictoExpand,
  PictoForward10,
  PictoForward30,
  PictoForward60,
  PictoInfo,
  PictoLeaf,
  PictoPause,
  PictoRecord,
  PictoRewind10,
  PictoRewind30,
  PictoRewind60,
} from '@ntg/components/dist/pictos/Element';
import RecordButton, { ContextKind, DisplayMode } from '../../recordButton/RecordButton';
import { formatSecondsToHHMMSS, formatTimeFromSeconds } from '../../../helpers/dateTime/Format';
import type { BasicCallbackFunction } from '@ntg/utils/dist/types';
import { BroadcastStatus } from '../../../helpers/ui/location/Format';
import ChannelZapper from './zapper/ChannelZapper';
import type { CombinedReducers } from '../../../redux/reducers';
import { ControlLevel } from '../../../helpers/ui/player';
import { Definition } from '../../../helpers/ui/metadata/Types';
import EmptyProgressBar from './progressBar/empty/EmptyProgressBar';
import { ExtendedItemType } from '../../../helpers/ui/item/types';
import LiveProgressBar from './progressBar/live/LiveProgressBar';
import { Localizer } from '@ntg/utils/dist/localization';
import MediaController from '../../../helpers/mediaSession/mediaController';
import type { NETGEM_API_CHANNEL } from '../../../libs/netgemLibrary/v8/types/Channel';
import type { NETGEM_API_V8_METADATA_SCHEDULE_LOCATION } from '../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import Options from './options/Options';
import PlayerVolume from './volume/Volume';
import { Setting } from '../../../helpers/settings/types';
import StandardProgressBar from './progressBar/standard/StandardProgressBar';
import StatusPicto from '../../statusPicto/StatusPicto';
import { TileConfigTileType } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import { type VideoPlayerMediaInfo } from '../implementation/types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { getTitle } from '../../../helpers/ui/metadata/Format';

// Timespan allowed to move cursor outside options element without closing it (in ms)
const OPTIONS_TIMEOUT = 500;

type ReduxPlayerControllerDispatchToPropsType = {||};

type ReduxPlayerControllerReducerStateType = {|
  +isBitrateLimited: boolean,
  +isMaxBitrateAllowed: boolean,
|};

type PlayerControllerPropType = {|
  +audioMediaInfo: Array<VideoPlayerMediaInfo>,
  +bufferedTimeRanges: TimeRanges | null,
  +channelId: string | null,
  +channelImageUrl: string,
  +channelName: string,
  +contentType: ContentType,
  +controlLevel: ControlLevel,
  +currentItem: NETGEM_API_V8_FEED_ITEM | null,
  +duration: number,
  +endMargin: number,
  +imageUrl: string | null,
  +isControllerEnabled: boolean,
  +isInFullscreen: boolean,
  +isLiveRecording: boolean,
  +isMuted: boolean,
  +isPlaying: boolean,
  +isProgramInfoHidden: boolean,
  +isTimeshiftEnabled: boolean,
  +isTrailer: boolean,
  +isVisible: boolean,
  +itemType: ExtendedItemType,
  +liveBufferLength: number,
  +location: NETGEM_API_V8_METADATA_SCHEDULE_LOCATION | null,
  +onChannelChanged: (channel: NETGEM_API_CHANNEL) => void,
  +onEnterFullScreenClick: BasicCallbackFunction,
  +onExitFullScreenClick: BasicCallbackFunction,
  +onGoToLiveClick: BasicCallbackFunction,
  +onInfoClick: BasicCallbackFunction,
  +onLiveProgressBarSeekChanged: (seekTime: number) => void,
  +onMouseEnter: BasicCallbackFunction,
  +onMouseLeave: BasicCallbackFunction,
  +onNewAudioTrackSelected: (index: number) => void,
  +onNewSubtitlesTrackSelected: (index: number) => void,
  +onPauseClick: BasicCallbackFunction,
  +onPlayClick: BasicCallbackFunction,
  +onSkipBackwardClick: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement> | SyntheticKeyboardEvent<HTMLElement>) => void,
  +onSkipForwardClick: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement> | SyntheticKeyboardEvent<HTMLElement>) => void,
  +onStandardProgressBarSeekChanged: (seekTime: number) => void,
  +onVolumeChanged: (volume: number) => void,
  +onVolumeClick: BasicCallbackFunction,
  +playheadPosition: number,
  +programMetadata: NETGEM_API_V8_METADATA_PROGRAM | null,
  +realEnd: number,
  +realStart: number,
  +selectedAudioMediaInfo: number,
  +selectedSubtitlesMediaInfo: number,
  +seriesEpisodeText: string | null,
  +seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null,
  +startMargin: number,
  +subtitlesMediaInfo: Array<VideoPlayerMediaInfo>,
  +tileType: TileConfigTileType,
  +title: string | null,
  +timeshift: number,
  +totalDuration: number,
  +userViewEndOffset: number,
  +userViewStartOffset: number,
  +videoQuality: Definition,
  +volume: number,
|};

type CompletePlayerControllerPropType = {|
  ...PlayerControllerPropType,
  ...ReduxPlayerControllerDispatchToPropsType,
  ...ReduxPlayerControllerReducerStateType,
|};

type PlayerControllerStateType = {|
  areOptionsVisible: boolean,
  isWaitingForDeleteConfirmation: boolean,
  isWaitingForRecordConfirmation: boolean,
  playingInMargin: boolean,
|};

const InitialState = Object.freeze({
  areOptionsVisible: false,
  isWaitingForDeleteConfirmation: false,
  isWaitingForRecordConfirmation: false,
  playingInMargin: false,
});

class PlayerControllerView extends React.PureComponent<CompletePlayerControllerPropType, PlayerControllerStateType> {
  channelZapper: React.ElementRef<any> | null;

  optionsTimer: TimeoutID | null;

  constructor(props: CompletePlayerControllerPropType) {
    super(props);

    this.channelZapper = null;
    this.optionsTimer = null;

    this.state = { ...InitialState };
  }

  componentDidUpdate(prevProps: CompletePlayerControllerPropType) {
    const { isVisible } = this.props;
    const { isVisible: prevIsVisible } = prevProps;

    if (!isVisible && isVisible !== prevIsVisible) {
      this.setPlayerOptionsVisibility(false);
    }
  }

  componentWillUnmount() {
    this.resetOptionsTimer();
  }

  resetOptionsTimer = () => {
    if (this.optionsTimer) {
      clearTimeout(this.optionsTimer);
      this.optionsTimer = null;
    }
  };

  startOptionsTimer = () => {
    this.resetOptionsTimer();
    this.optionsTimer = setTimeout(this.closePlayerOptions, OPTIONS_TIMEOUT);
  };

  handlePlayerOptionsOnMouseEnter = () => {
    this.resetOptionsTimer();
  };

  handlePlayerOptionsOnMouseLeave = () => {
    // Start a timer to allow the user to move the mouse cursor a short time outside the options element
    this.startOptionsTimer();
  };

  closePlayerOptions = () => {
    this.resetOptionsTimer();
    this.setPlayerOptionsVisibility(false);
  };

  setPlayerOptionsVisibility = (value: boolean) => {
    this.setState({ areOptionsVisible: value });
  };

  handleOnClickOverlay = () => {
    this.setPlayerOptionsVisibility(false);
  };

  handlePictoCogOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { areOptionsVisible } = this.state;
    this.setPlayerOptionsVisibility(!areOptionsVisible);
  };

  handleChannelZapperChannelChanged = (channel: NETGEM_API_CHANNEL) => {
    const { onChannelChanged } = this.props;

    onChannelChanged(channel);
  };

  handlePlayInMargin = (value: boolean) => {
    this.setState({ playingInMargin: value });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  goToPreviousChannel = () => {
    const { channelZapper } = this;

    if (channelZapper) {
      channelZapper.goToPreviousChannel();
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  goToNextChannel = () => {
    const { channelZapper } = this;

    if (channelZapper) {
      channelZapper.goToNextChannel();
    }
  };

  renderProgressBar = (): React.Element<any> => {
    const {
      bufferedTimeRanges,
      contentType,
      currentItem,
      duration,
      endMargin,
      isTrailer,
      liveBufferLength,
      onLiveProgressBarSeekChanged,
      onStandardProgressBarSeekChanged,
      playheadPosition,
      realEnd,
      realStart,
      startMargin,
      totalDuration,
      userViewEndOffset,
      userViewStartOffset,
    } = this.props;

    if (!currentItem && !isTrailer) {
      return <EmptyProgressBar />;
    }

    const liveStartTime = currentItem?.startTime ?? 0;
    const liveEndTime = currentItem?.endTime ?? 0;

    let progressBarElement = null;

    if (contentType === ContentType.Live) {
      // Live
      progressBarElement = (
        <LiveProgressBar
          liveBufferLength={liveBufferLength}
          liveProgramEndTime={liveEndTime}
          liveProgramStartTime={liveStartTime}
          onSeekChanged={onLiveProgressBarSeekChanged}
          playheadPosition={playheadPosition}
        />
      );
    } else {
      // Static or live recording
      progressBarElement = (
        <StandardProgressBar
          bufferedTimeRanges={bufferedTimeRanges}
          duration={duration}
          endMargin={endMargin}
          endTime={liveEndTime}
          isLiveRecording={contentType === ContentType.LiveRecording}
          onPlayingInMargin={this.handlePlayInMargin}
          onSeekChanged={onStandardProgressBarSeekChanged}
          playheadPosition={playheadPosition}
          realEnd={realEnd}
          realStart={realStart}
          startMargin={startMargin}
          startTime={liveStartTime}
          totalDuration={totalDuration}
          userViewEndOffset={userViewEndOffset}
          userViewStartOffset={userViewStartOffset}
        />
      );
    }

    return progressBarElement;
  };

  renderOptionsPicto = (): React.Element<any> => {
    const { audioMediaInfo, isMaxBitrateAllowed, subtitlesMediaInfo } = this.props;

    /*
     * Options are enabled if at least one of theses 3 things happens:
     *  - Green streaming is allowed
     *  - There are more than 1 audio track
     *  - There are at least 1 subtitles track
     */
    const areOptionsEnabled = isMaxBitrateAllowed || audioMediaInfo.length > 1 || subtitlesMediaInfo.length > 0;

    const onClickHandler = areOptionsEnabled ? this.handlePictoCogOnClick : undefined;

    return <PictoCog className={clsx(!areOptionsEnabled && 'disabled')} onClick={onClickHandler} onMouseEnter={this.handlePlayerOptionsOnMouseEnter} />;
  };

  renderFullScreenPicto = (): React.Element<any> => {
    const { isInFullscreen, onEnterFullScreenClick, onExitFullScreenClick } = this.props;

    return isInFullscreen ? <PictoCompress onClick={onExitFullScreenClick} /> : <PictoExpand onClick={onEnterFullScreenClick} />;
  };

  renderLiveIndicator = (): React.Element<any> | null => {
    const { contentType, currentItem, isLiveRecording, onGoToLiveClick, timeshift } = this.props;

    if (contentType !== ContentType.Live || !currentItem) {
      return null;
    }

    const isLive = timeshift <= TIMESHIFT_THRESHOLD;
    const { endTime, startTime } = currentItem;

    if ((!startTime && endTime) || (startTime && !endTime)) {
      return null;
    }

    const recordingPicto = isLiveRecording ? (
      <div className='liveRecording'>
        <PictoRecord className='recording live' hasBackground hasHoverEffect={false} />
        <div className='text'>{Localizer.localize('common.status.live_recording')}</div>
      </div>
    ) : null;

    return (
      <>
        <StatusPicto
          className={clsx(!isLive && 'notLive')}
          forcedText={isLive ? undefined : Localizer.localize('player.back_to_live')}
          item={currentItem}
          onClick={isLive ? undefined : onGoToLiveClick}
        />
        {recordingPicto}
      </>
    );
  };

  renderTitleElement = (liveIndicator: React.Element<any> | null): React.Element<any> | null => {
    const { isProgramInfoHidden, isTrailer, seriesEpisodeText, seriesMetadata, title } = this.props;
    const seriesTitle = getTitle(seriesMetadata, Localizer.language);
    const episodeTitle = !isTrailer && seriesEpisodeText && title ? `${seriesEpisodeText} - ${title}` : title;

    if (isProgramInfoHidden) {
      return null;
    }

    const mainLineTitle = <div className='title main'>{seriesTitle || episodeTitle || Localizer.localize('player.no_program_info')}</div>;

    const subLineTitle = seriesTitle && episodeTitle && seriesTitle !== episodeTitle ? <div className='title'>{episodeTitle}</div> : null;

    MediaController.setTitles(episodeTitle, seriesTitle);

    return (
      <div className={clsx('titlesAndStatus', subLineTitle && 'multiline')}>
        <div className='mainLine'>
          {mainLineTitle}
          {liveIndicator}
        </div>
        {subLineTitle}
      </div>
    );
  };

  renderChannelImage = (): React.Element<any> | null => {
    const { channelImageUrl, channelName, contentType, imageUrl } = this.props;

    if (contentType === ContentType.Live || imageUrl) {
      // No channel image for live program (channel list instead), trailer and VOD
      return null;
    }

    return <div className='channelImage'>{channelImageUrl ? <img alt={channelName} draggable={false} src={channelImageUrl} /> : null}</div>;
  };

  renderPictoLeaf = (): React.Element<any> | null => {
    const { isBitrateLimited } = this.props;

    if (!isBitrateLimited) {
      return null;
    }

    return <PictoLeaf hasHoverEffect={false} />;
  };

  renderVideoQuality = (): React.Element<any> => {
    const { videoQuality } = this.props;

    return (
      <div className='videoQuality'>
        {videoQuality !== Definition.SD ? <div>{(videoQuality: string)}</div> : null}
        {this.renderPictoLeaf()}
      </div>
    );
  };

  getDisplayedDuration = (): number => {
    const { duration, realEnd, realStart, totalDuration } = this.props;
    let displayedDuration = 0;

    if (duration > 0 && duration < Infinity) {
      displayedDuration = duration;
    } else if (totalDuration > 0) {
      displayedDuration = totalDuration;
    }

    displayedDuration -= realStart + realEnd;
    if (displayedDuration < 0) {
      displayedDuration = 0;
    }

    return displayedDuration;
  };

  renderTimeInfo = (): React.Element<any> | null => {
    const { contentType, currentItem, duration, isProgramInfoHidden, playheadPosition, realStart } = this.props;
    const { playingInMargin } = this.state;

    if (isProgramInfoHidden) {
      return null;
    }

    if (contentType === ContentType.Live) {
      if (!currentItem) {
        return null;
      }
      // Live program: display start and end times
      const { endTime, startTime } = currentItem;

      return (
        <div className='timeInfo'>
          <div>{formatTimeFromSeconds(startTime)}</div>
          <div className='timeSeparator'>-</div>
          <div>{formatTimeFromSeconds(endTime)}</div>
        </div>
      );
    }

    if (duration === 0) {
      return null;
    }

    const displayedSeekPosition = playheadPosition - realStart;
    const displayedDuration = this.getDisplayedDuration();

    return (
      <div className='timeInfo'>
        <div className={clsx('first', contentType !== ContentType.Live && playingInMargin && 'margin')}>{formatSecondsToHHMMSS(displayedSeekPosition)}</div>
        <div className='timeSeparator'>/</div>
        <div>{formatSecondsToHHMMSS(displayedDuration)}</div>
      </div>
    );
  };

  renderOptions = (): React.Element<any> | null => {
    const { audioMediaInfo, onNewAudioTrackSelected, onNewSubtitlesTrackSelected, selectedAudioMediaInfo, selectedSubtitlesMediaInfo, subtitlesMediaInfo } = this.props;
    const { areOptionsVisible } = this.state;

    if (!areOptionsVisible) {
      return null;
    }

    return (
      <Options
        audioMediaInfo={audioMediaInfo}
        onMouseEnter={this.handlePlayerOptionsOnMouseEnter}
        onMouseLeave={this.handlePlayerOptionsOnMouseLeave}
        onNewAudioTrackSelected={onNewAudioTrackSelected}
        onNewSubtitlesTrackSelected={onNewSubtitlesTrackSelected}
        selectedAudioMediaInfo={selectedAudioMediaInfo}
        selectedSubtitlesMediaInfo={selectedSubtitlesMediaInfo}
        subtitlesMediaInfo={subtitlesMediaInfo}
      />
    );
  };

  renderChannelZapper = (): React.Element<any> | null => {
    const { channelId, contentType } = this.props;

    if (contentType !== ContentType.Live) {
      return null;
    }

    return (
      <ChannelZapper
        channelId={channelId}
        onChannelChangedCallback={this.handleChannelZapperChannelChanged}
        ref={(instance) => {
          this.channelZapper = instance;
        }}
      />
    );
  };

  renderCover = (): React.Element<any> | null => {
    const { currentItem, imageUrl, isTrailer, itemType, onInfoClick } = this.props;

    return itemType !== ExtendedItemType.TV || currentItem?.locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD ? (
      <img alt='' className={clsx('cover', !isTrailer && 'clickable', imageUrl && 'visible')} onClick={onInfoClick} src={imageUrl} />
    ) : null;
  };

  renderRecordButton = (): React.Element<any> | null => {
    const { contentType, currentItem, isControllerEnabled, location, programMetadata, seriesMetadata, tileType, timeshift } = this.props;

    if (!currentItem || !programMetadata) {
      return null;
    }

    const broadcastStatus = contentType === ContentType.Live && timeshift <= TIMESHIFT_THRESHOLD ? BroadcastStatus.Live : BroadcastStatus.Past;

    return (
      <RecordButton
        broadcastStatus={broadcastStatus}
        context={ContextKind.Player}
        displayMode={DisplayMode.Picto}
        isDisabled={!isControllerEnabled}
        item={currentItem}
        location={location}
        previewCatchupScheduledEventId={null}
        programMetadata={programMetadata}
        seriesMetadata={seriesMetadata}
        tileType={tileType}
      />
    );
  };

  // eslint-disable-next-line consistent-return
  renderSkipBackwardButton = (): React.Element<any> => {
    const { contentType, controlLevel, isControllerEnabled, isTimeshiftEnabled, onSkipBackwardClick } = this.props;

    const isDisabled = !isControllerEnabled || (contentType === ContentType.Live && !isTimeshiftEnabled);

    switch (controlLevel) {
      case ControlLevel.Level0:
        return <PictoRewind10 className='skipPicto' isDisabled={isDisabled} onClick={onSkipBackwardClick} />;

      case ControlLevel.Level1:
        return <PictoRewind30 className='skipPicto' isDisabled={isDisabled} onClick={onSkipBackwardClick} />;

      case ControlLevel.Level2:
        return <PictoRewind60 className='skipPicto' isDisabled={isDisabled} onClick={onSkipBackwardClick} />;

      // No default
    }
  };

  // eslint-disable-next-line consistent-return
  renderSkipForwardButton = (): React.Element<any> => {
    const { contentType, controlLevel, isControllerEnabled, isTimeshiftEnabled, onSkipForwardClick, timeshift } = this.props;

    const isDisabled = !isControllerEnabled || (contentType === ContentType.Live && (timeshift <= TIMESHIFT_THRESHOLD || !isTimeshiftEnabled));

    switch (controlLevel) {
      case ControlLevel.Level0:
        return <PictoForward10 className='skipPicto' isDisabled={isDisabled} onClick={onSkipForwardClick} />;

      case ControlLevel.Level1:
        return <PictoForward30 className='skipPicto' isDisabled={isDisabled} onClick={onSkipForwardClick} />;

      case ControlLevel.Level2:
        return <PictoForward60 className='skipPicto' isDisabled={isDisabled} onClick={onSkipForwardClick} />;

      // No default
    }
  };

  renderPlayOrPauseButton = (): React.Element<any> => {
    const { contentType, isControllerEnabled, isPlaying, isTimeshiftEnabled, onPauseClick, onPlayClick } = this.props;

    const isDisabled = !isControllerEnabled || (contentType === ContentType.Live && !isTimeshiftEnabled);

    if (isPlaying) {
      // Playing: display pause button
      return <PictoPause isDisabled={isDisabled} onClick={onPauseClick} />;
    }

    // Paused: display play button
    return <PictoBigPlay isDisabled={isDisabled} onClick={onPlayClick} />;
  };

  render(): React.Node {
    const { isControllerEnabled, isMuted, isTrailer, isVisible, onInfoClick, onMouseEnter, onMouseLeave, onVolumeChanged, onVolumeClick, volume } = this.props;

    const playPauseButton = this.renderPlayOrPauseButton();
    const liveIndicator = this.renderLiveIndicator();
    const recordButton = this.renderRecordButton();

    return (
      <div className={clsx('videoController', isVisible && 'visible')} onClick={this.handleOnClickOverlay} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {this.renderChannelZapper()}
        {this.renderCover()}
        <div className='controller'>
          <div className='textInfo'>
            {this.renderChannelImage()}
            {this.renderTitleElement(liveIndicator)}
            <div className='videoQualityAndTime'>
              {this.renderVideoQuality()}
              {this.renderTimeInfo()}
            </div>
          </div>
          {this.renderProgressBar()}
          <div className='buttons'>
            <div className='controlButtonContainer'>
              {this.renderSkipBackwardButton()}
              {playPauseButton}
              {this.renderSkipForwardButton()}
              {recordButton}
              <PlayerVolume isDisabled={!isControllerEnabled} isMuted={isMuted} onVolumeClickCallback={onVolumeClick} onVolumeControllerClickCallback={onVolumeChanged} volume={volume} />
            </div>
            <div className='infoButtonContainer'>
              {this.renderOptionsPicto()}
              <PictoInfo className={clsx(isTrailer && 'disabled')} onClick={onInfoClick} />
              {this.renderFullScreenPicto()}
            </div>
          </div>
          {this.renderOptions()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxPlayerControllerReducerStateType => {
  return {
    isBitrateLimited: state.ui.settings[Setting.GreenStreaming],
    isMaxBitrateAllowed: state.appConfiguration.isMaxBitrateAllowed,
  };
};

const PlayerController: React.ComponentType<PlayerControllerPropType> = connect(mapStateToProps, null, null, { forwardRef: true })(PlayerControllerView);

export default PlayerController;
