/* @flow */

export const SettledPromiseFulfilled = 'fulfilled';
export const SettledPromiseRejected = 'rejected';

export type SettledPromise =
  | {|
      +status: typeof SettledPromiseFulfilled,
      +value: any,
    |}
  | {|
      +reason: any,
      +status: typeof SettledPromiseRejected,
    |};

export type AllSettledPromises = Array<SettledPromise>;
