/* @flow */

import type { NETGEM_API_V8_FEED, NETGEM_API_V8_FEED_ITEM } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { BasicCallbackFunction } from '@ntg/utils/dist/types';
import type { ChannelMap } from '../../../libs/netgemLibrary/v8/types/Channel';
import type { CombinedReducers } from '../../../redux/reducers';
import type { ItemData } from './Types';
import type { MetadataKind } from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import type { NETGEM_API_V8_SECTION } from '../../../libs/netgemLibrary/v8/types/Section';
import type { NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import type { STREAM_PRIORITIES_TYPE } from '../../../helpers/ui/metadata/Types';
import { SectionDisplayType } from '../../../helpers/ui/section/types';
import type { ThemeType } from '@ntg/ui/dist/theme';
import type { VideoCarouselStateType } from '../../../redux/ui/types/types';

export enum CarouselSectionType {
  Regular,
  Live,
}

// Displayed item changes every 6 seconds
export const ITEM_CHANGE_TIMEOUT: number = 6_000;

// Video carousel goes into preview mode after 4 seconds of mouse idle time
export const UI_HIDE_TIMEOUT: number = 4_000;

// Video carousel section displays a maximum of 7 items
export const VIDEO_MAX_ITEMS = 7;

// Image carousel section displays a maximum of 5 items
export const IMAGE_MAX_ITEMS = 5;

// Cannot be converted to enum because it's used as a key in an object
export type INTERVAL_TYPE = 'LIVE_CAROUSEL_SECTION' | 'LIVE_CACHED_CAROUSEL_SECTION';

export type ReduxSectionDispatchToPropsType = {|
  +localGetImageUrl: (assetId: string, width: number, height: number, theme?: ThemeType, signal?: AbortSignal) => Promise<any>,
  +localSendV8MetadataRequest: (assetId: string, type: MetadataKind, signal?: AbortSignal) => Promise<any>,
  +localSendV8SectionFeedRequest: (section: NETGEM_API_V8_SECTION, signal?: AbortSignal) => Promise<any>,
  +localUpdateVideoCarouselMuted: BasicCallbackFunction,
  +localUpdateVideoCarouselPaused: BasicCallbackFunction,
  +localUpdateVideoCarouselPlaying: BasicCallbackFunction,
  +localUpdateVideoCarouselUnmuted: BasicCallbackFunction,
|};

export type ReduxSectionReducerStateType = {|
  +channels: ChannelMap,
  +gridSectionId: string | null,
  +isDebugModeEnabled: boolean,
  +isInTheaterMode: boolean,
  +state: CombinedReducers,
  +streamPriorities: STREAM_PRIORITIES_TYPE | null,
  +videoCarousel: VideoCarouselStateType,
|};

export type DefaultProps = {|
  +onItemClick?: NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE,
  +onUIHiddenCallback?: BasicCallbackFunction,
  +onUIShownCallback?: BasicCallbackFunction,
|};

export type SectionPropType = {|
  ...DefaultProps,
  +avenueImageUri: ?string,
  +avenueIndex: number,
  +dataLoadedCallback: (sectionId: string, sectionIndex: number, avenueIndex: number, itemCount: number) => void,
  +hubItem: ?NETGEM_API_V8_FEED_ITEM,
  +section: NETGEM_API_V8_SECTION,
  +sectionIndex: number,
|};

export type CompleteSectionPropType = {|
  ...SectionPropType,
  ...ReduxSectionReducerStateType,
  ...ReduxSectionDispatchToPropsType,
|};

export type SectionStateType = {|
  currentIndex: number,
  displayType: SectionDisplayType,
  feed: NETGEM_API_V8_FEED,
  hubImageUrl: ?string,
  imageUrlList: Array<string>,
  isCollapsed: boolean,
  isUIHidden: boolean,
  itemDataList: Array<ItemData>,
  selectedItemIndices: Array<number>,
  trailerUrlList: Array<string>,
|};
