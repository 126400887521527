/* @flow */

import type { BasicCallbackFunction, KeyValuePair } from '@ntg/utils/dist/types';
import { ItemContent, type NETGEM_API_V8_FEED_ITEM, type NETGEM_API_V8_ITEM_LOCATION } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { MetadataKind, NETGEM_API_V8_METADATA_PROGRAM, NETGEM_API_V8_METADATA_SERIES } from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import { type NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE, type TILE_CONFIG_TYPE, TileConfig } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import type { BO_FAVORITE_LIST_TYPE } from '../../../redux/netgemApi/actions/videofutur/types/favorite';
import type { BO_PURCHASE_LIST_TYPE } from '../../../redux/netgemApi/actions/videofutur/types/purchase';
import { BroadcastStatus } from '../../../helpers/ui/location/Format';
import type { CARD_DATA_MODAL_TYPE } from '../../modal/cardModal/CardModalConstsAndTypes';
import type { ChannelMap } from '../../../libs/netgemLibrary/v8/types/Channel';
import type { ImageUrlType } from '../../../redux/netgemApi/actions/v8/metadataImage';
import { LoadableStatus } from '../../../helpers/loadable/loadable';
import type { NETGEM_API_V8_AUTHENT_REALM } from '../../../libs/netgemLibrary/v8/types/Realm';
import type { NETGEM_API_V8_METADATA_SCHEDULE } from '../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { NETGEM_API_V8_PURCHASE_INFO } from '../../../libs/netgemLibrary/v8/types/PurchaseInfo';
import type { NETGEM_API_V8_RIGHTS } from '../../../libs/netgemLibrary/v8/types/Rights';
import type { NETGEM_API_VIEWINGHISTORY } from '../../../libs/netgemLibrary/v8/types/ViewingHistory';
import type { VOD_STATUS_TYPE } from '../../../helpers/ui/metadata/Types';

// Hack used to prevent graphic glitches while real item image is loading
export const TRANSPARENT_PIXEL = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

export enum MetadataStatus {
  Loading,
  Loaded,
  Error,
}

// Time display and broadcast status are refreshed every second (in ms)
export const REFRESH_TIMEOUT = 1_000;

// Delay between two attempts at image loading when DOM element is not ready yet (in ms)
export const IMAGE_LOAD_TIMEOUT = 20;

export const IMAGE_URL_COUNT = 2;

export const SHORT_DEEPLINK_TEMPLATE_PROGRAM = 'https://<vod_domain>/<title>/<id>';
export const SHORT_DEEPLINK_TEMPLATE_SERIES = `${SHORT_DEEPLINK_TEMPLATE_PROGRAM}/s`;

// eslint-disable-next-line max-len
export const LEGACY_DEEPLINK_TEMPLATE_VF =
  '<protocol>//<host>/?<type>=provider%3dvideofutur%26titId%3d<titId>%26episodeNum%3d0%26vfDistributor%3d<distributorId>%26vfDevice%3d%7bvfDevice%7d%26vfProductId%3d%7bvfProductId%7d%26origin=HTML';

export const LEGACY_DEEPLINK_TEMPLATE_NETGEM = '<protocol>//<host>/?<type>=provider%3dnetgem%26id%3d<id>%26service%3d<channel>%26kind%3d<kind>%26origin=HTML';

export type ReduxItemDispatchToPropsType = {|
  +localGetImageUrl: (data: ImageUrlType, signal?: AbortSignal) => Promise<any>,
  +localGetPurchaseInfoPerAsset: (id: string, channelId: string, signal?: AbortSignal) => Promise<any>,
  +localGetVodLocations: (locations: Array<NETGEM_API_V8_ITEM_LOCATION>, signal?: AbortSignal) => Promise<any>,
  +localHideModal: BasicCallbackFunction,
  +localSendV8LocationCatchupForAssetRequest: (assetId: string, startDate: number, range: number, signal?: AbortSignal) => Promise<any>,
  +localSendV8MetadataLocationRequest: (assetId: string, signal?: AbortSignal) => Promise<any>,
  +localSendV8MetadataRequest: (assetId: string, type: MetadataKind, signal?: AbortSignal) => Promise<any>,
  +localSendV8RecordingsMetadataRequest: (recordId: string, signal?: AbortSignal) => Promise<any>,
|};

export type ReduxItemReducerStateType = {|
  +authenticationToken: string | null,
  +channels: ChannelMap,
  +commercialOffers: ?KeyValuePair<Array<string>>,
  +defaultOnItemClick: ?NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE,
  +defaultRights: ?NETGEM_API_V8_RIGHTS,
  +deviceOS: string,
  +favoriteList: BO_FAVORITE_LIST_TYPE,
  +isDebugModeEnabled: boolean,
  +isRegisteredAsGuest: boolean,
  +purchaseList: BO_PURCHASE_LIST_TYPE | null,
  +usePackPurchaseApi: boolean,
  +userRights: Array<string> | null,
  +viewingHistory: NETGEM_API_VIEWINGHISTORY,
  +viewingHistoryStatus: LoadableStatus,
  +wishlistStatus: LoadableStatus,
|};

export type DefaultProps = {|
  +cardData?: CARD_DATA_MODAL_TYPE | null,
  +isDebugModePlusForced?: boolean,
  +isInExploreModal?: boolean,
  +isInLiveSection?: boolean,
  +isSwiping?: boolean,
  +onItemClick?: NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE,
|};

export type ItemPropType = {|
  ...DefaultProps,
  +item: NETGEM_API_V8_FEED_ITEM,
  +tileConfig: TILE_CONFIG_TYPE,
  +titleFilter?: string,
|};

export type CompleteItemPropType = {|
  ...ItemPropType,
  ...ReduxItemReducerStateType,
  ...ReduxItemDispatchToPropsType,
|};

export type ItemStateType = {|
  authority?: NETGEM_API_V8_AUTHENT_REALM,
  backgroundImageDisplayIndex: number,
  broadcastStatus: BroadcastStatus,
  caption: Set<TileConfig>,
  channelImageUrl: string | null,
  channelName: string | null,
  contentType: ItemContent,
  hoverContent: Set<TileConfig>,
  imageUrls: Array<string>,
  isDebugModePlusEnabled: boolean,
  isFocused: boolean,
  now: number,
  previewCatchupScheduledEventId: string | null,
  programMetadata: NETGEM_API_V8_METADATA_PROGRAM | null,
  programMetadataStatus: MetadataStatus,
  programTitle: string,
  purchaseInfo: NETGEM_API_V8_PURCHASE_INFO | null,
  seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null,
  seriesMetadataStatus: MetadataStatus,
  seriesTitle: string,
  startoverItem: NETGEM_API_V8_FEED_ITEM | null,
  tvLocationMetadata: NETGEM_API_V8_METADATA_SCHEDULE | null,
  vodLocationsMetadata: Array<NETGEM_API_V8_METADATA_SCHEDULE> | null,
  vodStatus: VOD_STATUS_TYPE | null,
|};
