/* @flow */

import './Graph.scss';
import 'chartjs-adapter-dayjs-3';
import * as React from 'react';
// $FlowFixMe: Flow cannot resolve chart.js module (although it could until v4.1.1)
import { CategoryScale, Chart, LineElement, LinearScale, PointElement } from 'chart.js';
import AccurateTimestamp from '../../helpers/dateTime/AccurateTimestamp';
// $FlowFixMe: Flow cannot resolve react-chartjs-2 module
import { Line } from 'react-chartjs-2';
import StreamingPlugin from '@robloche/chartjs-plugin-streaming';
import clsx from 'clsx';
import { useMemo } from 'react';

Chart.register(CategoryScale, LinearScale, LineElement, PointElement, StreamingPlugin);
Chart.defaults.font.size = 8;
Chart.defaults.responsive = true;
Chart.defaults.maintainAspectRatio = false;
Chart.defaults.color = '#FFF';
Chart.defaults.backgroundColor = '#FFF';
Chart.defaults.elements.line.borderWidth = 2;

export enum Theme {
  Blue = 'blue',
  Green = 'green',
  Red = 'red',
  Yellow = 'yellow',
}

const ThemeColors: {| [string]: {| borderColor: Array<string> |} |} = Object.freeze({
  blue: {
    borderColor: ['rgb(0, 0, 157)', 'rgb(100, 100, 157)'],
  },
  green: {
    borderColor: ['rgb(0, 157, 0)', 'rgb(100, 157, 100)'],
  },
  red: {
    borderColor: ['rgb(157, 0, 0)', 'rgb(157, 100, 100)'],
  },
  yellow: {
    borderColor: ['rgb(157, 157, 0)', 'rgb(157, 157, 100)'],
  },
});

type Options = {|
  afterBuildTicks?: (axis: any) => void,
  beginAtZero: boolean,
  display: boolean,
  suggestedMax?: number,
  ticks?: {|
    callback?: (value: number) => string,
    stepSize?: number,
  |},
|};

type Dataset = {|
  borderColor: string,
  data: Array<number>,
|};

const buildInitialDatasets = (count: number, theme: Theme): Array<Dataset> => {
  const datasets: Array<Dataset> = [];

  for (let i = 0; i < count; ++i) {
    datasets.push({
      borderColor: ThemeColors[(theme: string)].borderColor[i],
      data: [],
    });
  }

  return datasets;
};

const buildYOptions = (afterBuildTicksCallback?: (axis: any) => void, beginAtZero: boolean, maxValue?: number, tickCallback?: (value: number) => string, tickStep?: number): any => {
  const options: Options = {
    beginAtZero,
    display: true,
  };

  if (typeof maxValue === 'number') {
    options.suggestedMax = maxValue;
  }

  if (afterBuildTicksCallback) {
    options.afterBuildTicks = afterBuildTicksCallback;
  }

  if (tickCallback) {
    options.ticks = {
      callback: tickCallback,
    };
  }

  if (tickStep) {
    options.ticks = {
      ...options.ticks,
      stepSize: tickStep,
    };
  }

  return options;
};

/* eslint-disable react/require-default-props */
type DefaultProps = {|
  +afterBuildTicksCallback?: (axis: any) => void,
  +beginAtZero?: boolean,
  +datasetCount?: number,
  +maxValue?: number,
  +tickCallback?: (value: number) => string,
  +tickStep?: number,
|};
/* eslint-enable react/require-default-props */

export type GraphPropType = {|
  ...DefaultProps,
  +getY: () => Array<number>,
  +theme: Theme,
  +title: string,
|};

const Graph = ({ afterBuildTicksCallback, beginAtZero = true, datasetCount = 1, getY, maxValue, theme, tickCallback, tickStep, title }: GraphPropType): React.Node => {
  const datasets = useMemo(() => buildInitialDatasets(datasetCount, theme), [datasetCount, theme]);

  const yOptions = useMemo(() => buildYOptions(afterBuildTicksCallback, beginAtZero, maxValue, tickCallback, tickStep), [afterBuildTicksCallback, beginAtZero, maxValue, tickCallback, tickStep]);

  return (
    <div className={clsx('graphRoot', (theme: string))}>
      <div className='graphTitle'>{title}</div>
      <div className='graphData'>
        <Line
          data={{ datasets }}
          options={{
            scales: {
              x: {
                display: false,
                realtime: {
                  delay: 1000,
                  onRefresh: (chart) => {
                    const x = AccurateTimestamp.now();
                    const y = getY();
                    chart.data.datasets.forEach((dataset, i) => {
                      dataset.data.push({
                        x,
                        y: y[i],
                      });
                    });
                  },
                },
                type: 'realtime',
              },
              y: yOptions,
            },
          }}
        />
      </div>
    </div>
  );
};

export default Graph;
