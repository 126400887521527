/* @flow */

import { MILLISECONDS_PER_SECOND, getIso8601DurationInSeconds } from '../../helpers/dateTime/Format';
import { logError, logInfo } from '../../helpers/debug/debug';
import { HttpStatus } from '../../libs/netgemLibrary/v8/constants/NetworkCodesAndMessages';

// Minimum heartbeat (in ms)
const MIN_HEARTBEAT = 1_000;

// Delay between 2 start retries (in ms)
const START_RETRY_DELAY = 500;

export enum KeepAliveState {
  ForbiddenError = 'error (forbidden)',
  InitializationError = 'error (initialization)',
  Initializing = 'initializing',
  TemporaryError = 'error (retrying)',
  Terminated = 'terminated',
  Working = 'working',
}

/* eslint-disable no-console */

class KeepAlive {
  // In ms
  heartbeatInterval: number;

  intervalId: IntervalID | null;

  kaType: string;

  startRetry: TimeoutID | null;

  state: KeepAliveState;

  url: string | null;

  constructor(type: string, heartbeatInterval: string) {
    this.heartbeatInterval = Math.max(MIN_HEARTBEAT, getIso8601DurationInSeconds(heartbeatInterval) * MILLISECONDS_PER_SECOND);
    this.intervalId = null;
    this.kaType = type;
    this.startRetry = null;
    this.state = KeepAliveState.Initializing;
    this.url = null;
  }

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'KeepAlive';
  }

  // Initialize URL with redirected URL
  initialize: (originalUrl: string) => Promise<string | null> = (originalUrl) =>
    fetch(originalUrl).then(({ redirected, url }) => {
      if (!redirected) {
        // Error: no redirection
        this.state = KeepAliveState.InitializationError;
        return null;
      }

      // Session successfully started
      this.state = KeepAliveState.Working;
      this.url = url;
      return url;
    });

  sendKeepAlive: () => void = () => {
    fetch(`${this.url ?? ''}/keepalive`)
      .then(({ status }) => {
        if (status === HttpStatus.Forbidden) {
          clearInterval(this.intervalId);
          this.state = KeepAliveState.ForbiddenError;
          logInfo('Keep-alive was late -> stopping');
        } else if (status !== HttpStatus.OK && status !== HttpStatus.NoContent) {
          throw new Error(`Received HTTP status ${status}`);
        } else {
          this.state = KeepAliveState.Working;
        }
      })
      .catch((error) => {
        this.state = KeepAliveState.TemporaryError;
        logError('Error sending keep-alive');
        logError(error);
      });
  };

  sendTearDown: () => void = () => {
    fetch(`${this.url ?? ''}/teardown`).catch((error) => {
      logError('Error sending teardown');
      logError(error);
    });
  };

  start: () => void = () => {
    if (this.state === KeepAliveState.InitializationError) {
      logError('Cannot start keep-alive because no redirect');
      return;
    }

    if (this.state === KeepAliveState.Terminated) {
      logError('Cannot start keep-alive because state is Terminated');
      return;
    }

    if (this.state === KeepAliveState.Initializing) {
      logError('Cannot start keep-alive because URL is being fetched');
      if (this.startRetry === null) {
        this.startRetry = setTimeout(this.start, START_RETRY_DELAY);
      }
      return;
    }

    this.intervalId = setInterval(this.sendKeepAlive, this.heartbeatInterval);
  };

  stop: () => void = () => {
    if (this.startRetry !== null) {
      clearTimeout(this.startRetry);
      this.startRetry = null;
    }
    this.state = KeepAliveState.Terminated;
    clearInterval(this.intervalId);
    this.sendTearDown();
  };

  getState: () => KeepAliveState = () => this.state;
}

export default KeepAlive;
