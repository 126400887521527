/* @flow */

import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { type NOTIFICATION, type NOTIFICATION_MESSAGE, type NOTIFICATION_SETTINGS, type NOTIFICATION_TYPE, TOAST } from './types';
import { flattenNotificationMessage, logInfo } from '../debug/debug';
import { parseBoolean } from '../jsHelpers/parser';
import { toast } from 'react-toastify';

export default class Notifier {
  // eslint-disable-next-line no-use-before-define
  static instance: Notifier;

  static isDebugModeEnabled: boolean = parseBoolean(process.env.REACT_APP_DEBUG_MODE);

  constructor() {
    Messenger.on(MessengerEvents.NOTIFY_ERROR, this.notifyError);
    Messenger.on(MessengerEvents.NOTIFY_INFO, this.notifyInfo);
    Messenger.on(MessengerEvents.NOTIFY_SUCCESS, this.notifySuccess);
    Messenger.on(MessengerEvents.NOTIFY_WARNING, this.notifyWarning);
  }

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'Notifier';
  }

  static initialize: () => void = () => {
    if (Notifier.instance) {
      return;
    }

    Notifier.instance = new Notifier();
  };

  notifyError: (message: NOTIFICATION_MESSAGE, settings?: NOTIFICATION_SETTINGS) => void = (message, settings) => {
    this.notify(
      {
        message,
        settings,
      },
      TOAST.Error,
    );
  };

  notifyInfo: (message: NOTIFICATION_MESSAGE, settings?: NOTIFICATION_SETTINGS) => void = (message, settings) => {
    this.notify(
      {
        message,
        settings,
      },
      TOAST.Info,
    );
  };

  notifySuccess: (message: NOTIFICATION_MESSAGE, settings?: NOTIFICATION_SETTINGS) => void = (message, settings) => {
    this.notify(
      {
        message,
        settings,
      },
      TOAST.Success,
    );
  };

  notifyWarning: (message: NOTIFICATION_MESSAGE, settings?: NOTIFICATION_SETTINGS) => void = (message, settings) => {
    this.notify(
      {
        message,
        settings,
      },
      TOAST.Warning,
    );
  };

  notify: (notification: NOTIFICATION, type: NOTIFICATION_TYPE) => void = (notification, type) => {
    const { message, settings = {} } = notification;

    // QA
    if (Notifier.isDebugModeEnabled && (type === 'warning' || type === 'error')) {
      logInfo(`Notification: ${type} | ${flattenNotificationMessage(notification.message)}`);
    }

    toast(message, {
      ...settings,
      type,
    });
  };
}
