/* @flow */

import * as React from 'react';
import { type ExportedPictoPropType, type PictoPropType } from './Base';
// $FlowFixMe: Flow cannot resolve clsx
import clsx from 'clsx';

const PictoHOC = (WrappedComponent: React.ComponentType<PictoPropType>, svgElement: React.Element<'svg'>, extraClasses?: string): React.ComponentType<ExportedPictoPropType> => {
  class PictoHocTemplate extends React.PureComponent<ExportedPictoPropType, any> {
    render(): React.Node {
      const { className = '', draggable, forwardedRef, hasBackground, hasHoverEffect, isDisabled, key, onClick, onMouseDown, onMouseEnter, onMouseLeave, onMouseMove, onMouseUp } = this.props;

      return (
        <WrappedComponent
          className={clsx(className, extraClasses)}
          draggable={draggable}
          forwardedRef={forwardedRef}
          hasBackground={hasBackground}
          hasHoverEffect={hasHoverEffect}
          isDisabled={isDisabled}
          key={key}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          svgElement={React.cloneElement(svgElement)}
        />
      );
    }
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return React.forwardRef((props, ref) => <PictoHocTemplate {...props} forwardedRef={ref} />);
};

export default PictoHOC;
