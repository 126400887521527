/* @flow */

// $FlowFixMe: CSS file only exists in dist folder
import './DebugPicto.css';
import * as React from 'react';
import { Theme, type ThemeType } from '@ntg/ui/dist/theme';
// $FlowFixMe: Flow cannot resolve clsx
import clsx from 'clsx';

type DefaultProps = {|
  +theme?: ThemeType,
|};

export type DebugPictoPropType = {|
  ...DefaultProps,
  +isEnabled: boolean,
|};

type DebugPictoStateType = {|
  +className: string,
|};

const InitialState = Object.freeze({
  className: 'hidden',
});

class DebugPicto extends React.PureComponent<DebugPictoPropType, DebugPictoStateType> {
  static defaultProps: DefaultProps = {
    theme: Theme.Dark,
  };

  constructor(props: DebugPictoPropType) {
    super(props);

    this.state = { ...InitialState };
  }

  componentDidMount() {
    const { isEnabled } = this.props;

    this.updateClassName(null, isEnabled);
  }

  componentDidUpdate(prevProps: DebugPictoPropType) {
    const { isEnabled } = this.props;
    const { isEnabled: prevIsEnabled } = prevProps;

    if (isEnabled !== prevIsEnabled) {
      this.updateClassName(prevIsEnabled, isEnabled);
    }
  }

  updateClassName = (prevIsEnabled: ?boolean, isEnabled: boolean): void => {
    let className = '';

    if (prevIsEnabled === null) {
      className = isEnabled ? 'visible' : 'hidden';
    } else {
      className = isEnabled ? 'appearing' : 'disappearing';
    }

    this.setState({ className });
  };

  render(): React.Node {
    const { theme = Theme.Dark } = this.props;
    const { className } = this.state;

    return (
      <svg className={clsx('debugPicto', theme, className)} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
        <path d='M8,2.5C6.6,2.5,5.5,3.6,5.5,5h5C10.5,3.6,9.4,2.5,8,2.5z M13,8.5h-1V7.4l0.7-0.7c0.4-0.4,0.4-1,0-1.4 s-1-0.4-1.4,0L10.6,6H5.4L4.7,5.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L4,7.4v1.1H3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h1.1 c0,0.3,0.1,0.6,0.2,0.8l-1,1c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0L5.4,13C6.1,13.6,7,14,8,14s1.9-0.4,2.6-1l0.7,0.7 c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4l-1-1c0.1-0.3,0.2-0.5,0.2-0.8H13c0.6,0,1-0.4,1-1 C14,8.9,13.6,8.5,13,8.5z' />
      </svg>
    );
  }
}

export default (DebugPicto: React.ComponentType<DebugPictoPropType>);
