/* @flow */

import './WatchingStatus.css';
import * as React from 'react';
import { PictoPlay } from '@ntg/components/dist/pictos/Element';
import clsx from 'clsx';
import { connect } from 'react-redux';

const ONE_HUNDRED = 100;
const DEFAULT_GRANULARITY = 1;
const TWO = 2;
const RADIUS = 40;
const TRANSITION_TIMEOUT = 50;

// Circumference is ~251.3
const CIRCUMFERENCE = Math.PI * RADIUS * TWO;

// Ensures that minimum progress is displayed when a program has been watched for a very short time (~ CIRCUMFERENCE - 13)
const MAX_CIRCUMFERENCE_PERCENTAGE = 238;

type WatchingStatusPropType = {|
  +allowZeroProgress?: boolean,
  +granularity?: number,
  +onClick?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +progress?: number,
|};

type WatchingStatusStateType = {|
  percentage: number | null,
|};

const InitialState = Object.freeze({
  percentage: null,
});

class WatchingStatusView extends React.PureComponent<WatchingStatusPropType, WatchingStatusStateType> {
  transitionTimer: TimeoutID | null;

  static defaultProps: WatchingStatusPropType = {
    allowZeroProgress: false,
    granularity: DEFAULT_GRANULARITY,
    onClick: undefined,
    progress: 0,
  };

  constructor(props: WatchingStatusPropType) {
    super(props);

    this.transitionTimer = null;
    this.state = { ...InitialState };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps: WatchingStatusPropType) {
    const { progress } = this.props;
    const { progress: prevProgress } = prevProps;

    if (progress !== prevProgress) {
      this.update();
    }
  }

  componentWillUnmount() {
    const { transitionTimer } = this;

    if (transitionTimer) {
      clearTimeout(transitionTimer);
    }
  }

  update = () => {
    const { allowZeroProgress, granularity = DEFAULT_GRANULARITY, progress = 0 } = this.props;
    const { percentage: currentPercentage } = this.state;

    let adjustedProgress = 0;

    if (progress > ONE_HUNDRED) {
      adjustedProgress = ONE_HUNDRED;
    } else if (progress > 0) {
      adjustedProgress = Math.floor(granularity * Math.round(progress / granularity));
    }

    const percentage =
      adjustedProgress > 0 || !allowZeroProgress ? Math.min(((ONE_HUNDRED - adjustedProgress) / ONE_HUNDRED) * CIRCUMFERENCE, allowZeroProgress ? CIRCUMFERENCE : MAX_CIRCUMFERENCE_PERCENTAGE) : null;

    if (currentPercentage === null && percentage !== null) {
      // First set percentage to CIRCUMFERENCE to allow CSS transition to play
      this.setState({ percentage: CIRCUMFERENCE }, () => {
        if (this.transitionTimer) {
          clearTimeout(this.transitionTimer);
        }
        this.transitionTimer = setTimeout(() => this.setState({ percentage }), TRANSITION_TIMEOUT);
      });
    }
  };

  render(): React.Node {
    const { onClick } = this.props;
    const { percentage } = this.state;

    const progressCircle = percentage !== null ? <circle cx='50' cy='50' r={RADIUS} style={{ strokeDashoffset: percentage }} /> : null;

    return (
      <div className={clsx('watchingStatus', typeof onClick !== 'undefined' && 'clickable')} onClick={onClick}>
        <svg viewBox='0 0 100 100'>
          <circle className='background' cx='50' cy='50' r={RADIUS} />
          {progressCircle}
        </svg>
        <PictoPlay />
      </div>
    );
  }
}

const WatchingStatus: React.ComponentType<WatchingStatusPropType> = connect(null, null, null, { forwardRef: true })(WatchingStatusView);

export default WatchingStatus;
