/* @flow */

import './NpvrModal.css';
import * as React from 'react';
import { type NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND, NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SERIES } from '../../../libs/netgemLibrary/v8/types/Npvr';
import ButtonFX from '../../buttons/ButtonFX';
import type { CombinedReducers } from '../../../redux/reducers';
import { Localizer } from '@ntg/utils/dist/localization';
import Modal from '../modal';
import { ModalIcon } from '../modalTypes';
import type { ModalState } from '../../../redux/modal/reducers';
import MultipleChoices from '../../multipleChoices/MultipleChoices';
import { PictoArrowLeft } from '@ntg/components/dist/pictos/Element';
import type { SCHEDULED_RECORDING_CORE_SETTINGS } from '../../../helpers/npvr/Types';
import { Setting } from '../../../helpers/settings/types';
import { WidthKind } from '../../buttons/types';
import { connect } from 'react-redux';
import { produce } from 'immer';

export enum NpvrModalDialogResult {
  Cancel,
  DeleteEpisode,
  RecordEpisode,
  RecordSeries,
  StopSeries,
}

export type NPVR_MODAL_RESULT = {|
  dialogResult: NpvrModalDialogResult,
  settings?: SCHEDULED_RECORDING_CORE_SETTINGS,
|};

export type NPVR_DATA_MODAL_TYPE = {|
  episodeIndex: ?string,
  episodeTitle: ?string,
  isEpisodeDeletable: boolean,
  isEpisodeRecordable: boolean,
  isSeriesRecordable: boolean,
  kind: ?NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND,
  seriesTitle: ?string,
|};

type ReduxNpvrModalReducerStateType = {|
  +isDebugModeEnabled: boolean,
|};

type NpvrModalStateType = {|
  increasingEpisodesIndex: number,
  isSeriesMode: boolean,
  recordsToKeepIndex: number,
  settings?: SCHEDULED_RECORDING_CORE_SETTINGS,
|};

const InitialState = Object.freeze({
  increasingEpisodesIndex: -1,
  isSeriesMode: false,
  recordsToKeepIndex: -1,
  settings: undefined,
});

type CompleteConfirmationModalPropType = {|
  ...ModalState,
  ...ReduxNpvrModalReducerStateType,
|};

class NpvrModalView extends React.PureComponent<CompleteConfirmationModalPropType, NpvrModalStateType> {
  isBackDisplayed: boolean;

  modal: React.ElementRef<any> | null;

  constructor(props: CompleteConfirmationModalPropType) {
    super(props);

    const { isDebugModeEnabled, npvrData } = props;
    const isSeriesMode = Boolean(npvrData && npvrData.kind === NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SERIES);

    this.isBackDisplayed = !isSeriesMode;
    this.modal = null;

    if (isDebugModeEnabled) {
      // Debug mode: new custom series settings
      this.state = {
        ...InitialState,
        increasingEpisodesIndex: 0,
        isSeriesMode,
        recordsToKeepIndex: 3,
        settings: {
          increasingEpisodes: true,
          recordsToKeep: 0,
        },
      };
    } else {
      // Old version: no series settings
      this.state = {
        ...InitialState,
        isSeriesMode,
      };
    }
  }

  close = (result: NPVR_MODAL_RESULT): void => {
    const { modal } = this;

    if (modal) {
      modal.close(result);
    }
  };

  handleRecordEpisode = () => {
    this.close({ dialogResult: NpvrModalDialogResult.RecordEpisode });
  };

  handleDeleteEpisode = () => {
    this.close({ dialogResult: NpvrModalDialogResult.DeleteEpisode });
  };

  handleRecordSeries = () => {
    const { isDebugModeEnabled } = this.props;

    if (isDebugModeEnabled) {
      // New series options
      this.setState({ isSeriesMode: true });
    } else {
      this.handleRecordSeriesInternal();
    }
  };

  handleStopSeries = () => {
    this.close({ dialogResult: NpvrModalDialogResult.StopSeries });
  };

  handleIncreasingEpisodesOnSelectionChanged = (value: any, setting?: Setting, increasingEpisodesIndex: number) => {
    this.setState(
      produce((draft) => {
        draft.increasingEpisodesIndex = increasingEpisodesIndex;

        if (typeof draft.settings === 'undefined') {
          draft.settings = { increasingEpisodes: Boolean(value) };
        } else {
          draft.settings.increasingEpisodes = Boolean(value);
        }
      }),
    );
  };

  handleRecordsToKeepOnSelectionChanged = (value: any, setting?: Setting, recordsToKeepIndex: number) => {
    this.setState(
      produce((draft) => {
        draft.recordsToKeepIndex = recordsToKeepIndex;

        if (typeof draft.settings === 'undefined') {
          draft.settings = { recordsToKeep: Number(value) };
        } else {
          draft.settings.recordsToKeep = Number(value);
        }
      }),
    );
  };

  renderHeader = (): React.Element<any> | null => {
    const { npvrData } = this.props;
    const { isSeriesMode } = this.state;

    if (!npvrData) {
      return null;
    }

    const { episodeIndex, episodeTitle, seriesTitle } = npvrData;
    const parts = [];
    if (episodeIndex) {
      parts.push(episodeIndex);
    }
    if (episodeTitle) {
      parts.push(episodeTitle);
    }
    const episodeTitleElement = (!isSeriesMode || !seriesTitle) && parts.length > 0 ? <div className='episodeTitle'>{parts.join(' : ')}</div> : null;

    return (
      <>
        <div className='seriesTitle'>{seriesTitle}</div>
        {episodeTitleElement}
      </>
    );
  };

  renderButtons = (): React.Element<any> | null => {
    const { npvrData } = this.props;

    if (!npvrData) {
      return null;
    }

    const { isEpisodeDeletable, isEpisodeRecordable, isSeriesRecordable } = npvrData;

    const episodeRecordBtn = isEpisodeRecordable ? (
      <ButtonFX key='recordEpisode' onClick={this.handleRecordEpisode} widthKind={WidthKind.Stretched}>
        {Localizer.localize('modal.npvr_confirmation.record_episode')}
      </ButtonFX>
    ) : null;

    const episodeDeleteBtn = isEpisodeDeletable ? (
      <ButtonFX key='deleteEpisode' onClick={this.handleDeleteEpisode} widthKind={WidthKind.Stretched}>
        {Localizer.localize('modal.npvr_confirmation.delete_episode')}
      </ButtonFX>
    ) : null;

    const seriesRecordBtn = isSeriesRecordable ? (
      <ButtonFX key='recordSeries' onClick={this.handleRecordSeries} widthKind={WidthKind.Stretched}>
        {Localizer.localize('modal.npvr_confirmation.record_series')}
      </ButtonFX>
    ) : null;

    const seriesStopBtn = !isSeriesRecordable ? (
      <ButtonFX key='stopSeries' onClick={this.handleStopSeries} widthKind={WidthKind.Stretched}>
        {Localizer.localize('modal.npvr_confirmation.stop_series')}
      </ButtonFX>
    ) : null;

    return (
      <>
        {episodeRecordBtn}
        {episodeDeleteBtn}
        {seriesRecordBtn}
        {seriesStopBtn}
      </>
    );
  };

  renderRegularBody: () => React.Element<any> | null = this.renderButtons;

  handleRecordSeriesInternal = () => {
    const { settings } = this.state;

    this.close({
      dialogResult: NpvrModalDialogResult.RecordSeries,
      settings,
    });
  };

  renderSeriesBody = (): React.Element<any> => {
    const { increasingEpisodesIndex, recordsToKeepIndex, settings } = this.state;

    const increasingEpisodesChoices = [
      {
        text: Localizer.localize('modal.npvr_confirmation.options.recent_seasons'),
        value: true,
      },
      {
        text: Localizer.localize('modal.npvr_confirmation.options.whole_series'),
        value: false,
      },
    ];

    const recordsToKeepChoices = [
      {
        text: '1',
        value: 1,
      },
      {
        text: '3',
        value: 3,
      },
      {
        text: '5',
        value: 5,
      },
      {
        text: Localizer.localize('modal.npvr_confirmation.options.all'),
        value: 0,
      },
    ];

    const seriesRecordOptions = settings ? (
      <div className='confirmationOptions'>
        <div className='confirmationOption'>
          <MultipleChoices choices={increasingEpisodesChoices} onSelectionChanged={this.handleIncreasingEpisodesOnSelectionChanged} selectedIndex={increasingEpisodesIndex} />
        </div>
        <div className='confirmationOption'>
          <span className='confirmationLabel'>{Localizer.localize('modal.npvr_confirmation.options.records_to_keep')}</span>
          <MultipleChoices choices={recordsToKeepChoices} onSelectionChanged={this.handleRecordsToKeepOnSelectionChanged} selectedIndex={recordsToKeepIndex} />
        </div>
      </div>
    ) : null;

    return (
      <>
        {seriesRecordOptions}
        <ButtonFX key='recordSeries' onClick={this.handleRecordSeriesInternal} widthKind={WidthKind.Stretched}>
          {Localizer.localize('common.actions.confirm')}
        </ButtonFX>
      </>
    );
  };

  handleBackOnClick = () => {
    this.setState({ isSeriesMode: false });
  };

  renderBackPicto = (): React.Element<any> => <PictoArrowLeft onClick={this.handleBackOnClick} />;

  renderChildren = (): React.Node => {
    const { isSeriesMode } = this.state;
    const { isBackDisplayed } = this;

    return (
      <>
        {isSeriesMode && isBackDisplayed ? this.renderBackPicto() : null}
        <div className='confirmationBody'>{isSeriesMode ? this.renderSeriesBody() : this.renderRegularBody()}</div>
      </>
    );
  };

  render(): React.Node {
    const { index } = this.props;

    return (
      <Modal
        canCloseOnEnter={false}
        className='npvrConfirmation'
        header={this.renderHeader()}
        icon={ModalIcon.Record}
        index={index}
        ref={(instance) => {
          this.modal = instance;
        }}
      >
        {this.renderChildren()}
      </Modal>
    );
  }
}

const mapStateToProps = (state: CombinedReducers) => {
  return {
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
  };
};

const NpvrModal: React.ComponentType<ModalState> = connect(mapStateToProps, null, null, { forwardRef: true })(NpvrModalView);

export default NpvrModal;
