/* @flow */

import { DmsNetworkCode, getMessageFromErrorCode } from '../constants/NetworkCodesAndMessages';
import { type DmsSettingMap, type NETGEM_API_DMS_DEVICE_INFO_SETTINGS, type NETGEM_API_DMS_PRIVATE_DEVICE_INFO_SETTINGS } from '../types/DeviceInfoSettings';
import type { ApplicationInfo } from '../../../../redux/netgemApi/actions/emitter';
import { HttpMethod } from '../../v8/types/HttpMethod';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import { type NETGEM_API_DMS_DEVICE_INFO } from '../types/DeviceInfo';
import { type NETGEM_API_DMS_REQUEST_RESPONSE_DEVICE_SETTINGS } from '../types/RequestResponseDeviceSettings';
import { createCustomNetworkError } from '../../helpers/CreateCustomNetworkError';
import createDmsCommonRequestHeader from '../helpers/CreateCommonRequestHeader';
import sendDmsPromisedXMLHttpRequest from '../helpers/PromisedXMLHttpRequest';

const DmsFlags: KeyValuePair<string> = Object.freeze({
  FLAG00_RESTART_STB_BROWSER: '0',
  FLAG01_STB_SETTING_TO_ADD: '1',
  FLAG02_STB_SETTING_TO_SET: '2',
  FLAG03_STB_SETTING_TO_DEL: '3',
  FLAG04_DISTRIBUTOR_SETTING_TO_ADD: '4',
  FLAG05_DISTRIBUTOR_SETTING_TO_SET: '5',
  FLAG06_DISTRIBUTOR_SETTING_TO_DEL: '6',
  FLAG07_SERVICE_SETTING_TO_ADD: '7',
  FLAG08_SERVICE_SETTING_TO_SET: '8',
  FLAG09_SERVICE_SETTING_TO_DEL: '9',
  FLAG10_SETTING_UPDATED: '10',
  FLAG11_SETTING_NOT_UPDATED: '11',
});

const settingCmp: (a: NETGEM_API_DMS_DEVICE_INFO_SETTINGS, b: NETGEM_API_DMS_DEVICE_INFO_SETTINGS) => number = (a, b) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  }

  return 0;
};

const createSettingsMap: (settings: Array<NETGEM_API_DMS_PRIVATE_DEVICE_INFO_SETTINGS>) => DmsSettingMap = (settings) => {
  const settingsMap: DmsSettingMap = {};

  for (let i: number = 0; i < settings.length; i++) {
    const flag = settings[i]['@flag'];

    if (
      flag !== DmsFlags.FLAG00_RESTART_STB_BROWSER &&
      flag !== DmsFlags.FLAG03_STB_SETTING_TO_DEL &&
      flag !== DmsFlags.FLAG06_DISTRIBUTOR_SETTING_TO_DEL &&
      flag !== DmsFlags.FLAG09_SERVICE_SETTING_TO_DEL
    ) {
      const sectionName: string = settings[i]['@section'];
      const setting: NETGEM_API_DMS_DEVICE_INFO_SETTINGS = {
        name: settings[i]['@name'],
        value: settings[i]['@value'],
      };

      const { [sectionName]: currentArray } = settingsMap;

      if (currentArray) {
        currentArray.push(setting);
        settingsMap[sectionName] = currentArray.sort(settingCmp);
      } else {
        settingsMap[sectionName] = [setting];
      }
    }
  }

  return settingsMap;
};

const dmsDeviceSettingsRequest: (baseAuthDeviceUrl: string, authenticationToken: string | null, appInfo: ApplicationInfo, signal?: AbortSignal) => Promise<DmsSettingMap> = (
  baseAuthDeviceUrl,
  authenticationToken,
  appInfo,
  signal,
) =>
  sendDmsPromisedXMLHttpRequest(`${baseAuthDeviceUrl}info`, HttpMethod.POST, createDmsCommonRequestHeader(authenticationToken), JSON.stringify(appInfo), null, false, signal).promise.then(
    (settings: NETGEM_API_DMS_REQUEST_RESPONSE_DEVICE_SETTINGS) => {
      const { exchange } = settings;
      const deviceInfo: ?NETGEM_API_DMS_DEVICE_INFO = JSON.parse(exchange);

      if (deviceInfo) {
        const settingsArray = deviceInfo.upgradecheck.script.setting || [];
        return Promise.resolve(createSettingsMap(settingsArray));
      }

      return Promise.reject(createCustomNetworkError((DmsNetworkCode.MalformedJson: number), getMessageFromErrorCode(DmsNetworkCode.MalformedJson)));
    },
  );

export default dmsDeviceSettingsRequest;
