/* @flow */

import './ButtonBack.css';
import * as React from 'react';
import { PictoArrowLeft, PictoArrowLeftWithShaft } from '@ntg/components/dist/pictos/Element';
import type { BasicCallbackFunction } from '@ntg/utils/dist/types';
import { Localizer } from '@ntg/utils/dist/localization';
import clsx from 'clsx';

type PropType = {|
  +className: string,
  +hasShaft?: boolean, // eslint-disable-line react/require-default-props
  +hasText?: boolean, // eslint-disable-line react/require-default-props
  +onClick: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +onMouseEnter?: BasicCallbackFunction, // eslint-disable-line react/require-default-props
  +onMouseLeave?: BasicCallbackFunction, // eslint-disable-line react/require-default-props
|};

const ButtonBack = ({ className, hasShaft = false, hasText = true, onClick, onMouseEnter, onMouseLeave }: PropType): React.Node => (
  <div className={clsx('backButtonBase', className)} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
    {hasShaft === true ? <PictoArrowLeftWithShaft /> : <PictoArrowLeft />}
    {hasText ? <div className='text'>{Localizer.localize('common.actions.back')}</div> : null}
  </div>
);

export default ButtonBack;
