/* @flow */

import { isEnglishLanguage, isFrenchLanguage, isGermanLanguage, isUndeterminedLanguage } from './metadata/Format';
import { LANGUAGE } from './metadata/Types';
import { Localizer } from '@ntg/utils/dist/localization';
import type { VideoPlayerMediaInfo } from '../../components/player/implementation/types';

const HEARING_IMPAIRED_CODE = 'HI';

const convertToHumanReadable = (language: string): string => {
  const lang = language.toUpperCase();

  switch (lang) {
    case LANGUAGE.OriginalVersion:
      return Localizer.localize('player.language.qaa');
    case LANGUAGE.VisuallyImpaired:
    case LANGUAGE.VisuallyImpairedAlt:
      return Localizer.localize('player.language.qad');
    default:
      if (isFrenchLanguage(lang)) {
        return Localizer.localize('player.language.french');
      } else if (isEnglishLanguage(lang)) {
        return Localizer.localize('player.language.english');
      } else if (isGermanLanguage(lang)) {
        return Localizer.localize('player.language.german');
      } else if (isUndeterminedLanguage(lang)) {
        return Localizer.localize('player.language.undetermined');
      }

      return lang;
  }
};

const isHearingImpairedSubtitlesTrack = (track: VideoPlayerMediaInfo | null): boolean => {
  if (!track) {
    return false;
  }

  const { accessibility, kind, roles } = track;

  return (accessibility?.some((el) => el.toLowerCase().indexOf('caption') > -1) || roles?.some((el) => el.toLowerCase().indexOf('caption') > -1) || kind === 'captions') ?? false;
};

const isVisuallyImpairedAudioTrack = (track: VideoPlayerMediaInfo | null): boolean => {
  if (!track) {
    return false;
  }

  const { lang } = track;
  const ucLang = lang.toUpperCase();
  return ucLang === LANGUAGE.VisuallyImpaired || ucLang === LANGUAGE.VisuallyImpairedAlt;
};

const getAudioSettingCode = (track: VideoPlayerMediaInfo): string => {
  const { lang } = track;

  return lang;
};

const getSubtitlesSettingCode = (track: VideoPlayerMediaInfo | null): string => {
  if (!track) {
    return '';
  }

  const { lang } = track;

  if (isHearingImpairedSubtitlesTrack(track)) {
    return `${lang}:${HEARING_IMPAIRED_CODE}`;
  }

  return lang;
};

/*
 * Find and return the track matching the given language code or the closest one, e.g.:
 *  - languageCode=fr    -> fr track > fr hearing impaired track > null
 *  - languageCode=fr:HI -> fr hearing impaired track > fr track > null
 */
const getSubtitlesTrackFromCode = (tracks: Array<VideoPlayerMediaInfo>, languageCode: string): VideoPlayerMediaInfo | null => {
  const [language, isHearingImpaired] = languageCode.split(':');

  const hearingImpairedFunc = isHearingImpaired ? (track: VideoPlayerMediaInfo) => isHearingImpairedSubtitlesTrack(track) : (track) => !isHearingImpairedSubtitlesTrack(track);

  const requestedLanguageTracks = tracks.filter((track) => track.lang === language);

  const exactRequestedLanguage = requestedLanguageTracks.find((track) => hearingImpairedFunc(track));
  if (exactRequestedLanguage) {
    // Exact language has been found (e.g. "fr" or "fr hearing impaired")
    return exactRequestedLanguage;
  }

  const closestRequestedLanguage = requestedLanguageTracks.find((track) => !hearingImpairedFunc(track));
  if (closestRequestedLanguage) {
    // Closest language has been found (e.g. "fr" for "fr hearing impaired" or "fr hearing impaired" for "fr")
    return closestRequestedLanguage;
  }

  // No matching track was found
  return null;
};

export { convertToHumanReadable, getAudioSettingCode, getSubtitlesSettingCode, getSubtitlesTrackFromCode, isHearingImpairedSubtitlesTrack, isVisuallyImpairedAudioTrack };
