/* @flow */

import type { KeyValuePair } from '@ntg/utils/dist/types';

export const StorageKeys: KeyValuePair<string> = Object.freeze({
  CardExpandedItemIndex: 'cardExpandedItemIndex',
  CrmData: 'crmData',
  DataCollectionStash: 'dataCollectionStash',
  DefaultSubtitlesApplied: 'defaultSubtitlesApplied',
  DeviceIdentifier: 'deviceIdentifier',
  LastBOTokenRenewal: 'boTokenRenewalLast',
  LastPaymentExpirationNotification: 'lastPaymentExpirationNotification',
  LastStartup: 'startupLast',
  LastTokenRenewal: 'tokenRenewalLast',
  LastViewingHistoryCleanUp: 'lastViewingHistoryCleanUp',
  NextBOTokenRenewal: 'boTokenRenewalNext',
  NextTokenRenewal: 'tokenRenewalNext',
  NextVersionCheckDate: 'nextVersionCheckDate',
  OptimisticDefaultActions: 'optimisticDefaultActions',
  OptimisticDmsSettings: 'optimisticDmsSettings',
  OptimisticDmsSettingsUsed: 'optimisticDmsSettingsUsed',
  OptimisticHub: 'optimisticHub',
  OverriddenAppConf: 'overriddenAppConf',
  PendingOperation: 'pendingOperation',
  RegisteredAsGuest: 'registeredAsGuest',
  SearchHistory: 'searchHistory',
  Settings: 'settings',
  UserDeviceInfo: 'userDeviceInfo',
});
