/* @flow */

import './TVEpisode.css';
import * as React from 'react';
import type { BasicCallbackFunction, KeyValuePair, Undefined } from '@ntg/utils/dist/types';
import { BroadcastStatus, WebAppHelpersLocationStatus, getBroadcastStatus, getLocationStatus } from '../../../helpers/ui/location/Format';
import {
  ItemContent,
  type NETGEM_API_V8_FEED_ITEM,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD,
} from '../../../libs/netgemLibrary/v8/types/FeedItem';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import type { NETGEM_API_V8_METADATA_PROGRAM, NETGEM_API_V8_METADATA_SERIES } from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import {
  NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_KEEP_FROM_REPLAY,
  NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SINGLE,
  type NETGEM_RECORDINGS_MAP,
  type NETGEM_SCHEDULED_RECORDINGS_MAP,
  RecordingOutcome,
} from '../../../libs/netgemLibrary/v8/types/Npvr';
import { type NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE, TileConfigTileType } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import { PictoArrowDown, PictoClockEmpty, PictoFailure, PictoRecord, PictoWarning } from '@ntg/components/dist/pictos/Element';
import RecordButton, { ContextKind, DisplayMode } from '../../recordButton/RecordButton';
import { formatDate, formatExpirationDate } from '../../../helpers/dateTime/Format';
import { formatSeasonEpisodeNbr, getStartAndEndTimes, getSynopsis, renderProgramDetails } from '../../../helpers/ui/metadata/Format';
import { getEpisodeAndSeriesRecordStatus, getScheduledRecordingIdFromRecordingId } from '../../../helpers/npvr/recording';
import AccurateTimestamp from '../../../helpers/dateTime/AccurateTimestamp';
import type { CARD_DATA_MODAL_TYPE } from '../../modal/cardModal/CardModalConstsAndTypes';
import type { ChannelMap } from '../../../libs/netgemLibrary/v8/types/Channel';
import type { CombinedReducers } from '../../../redux/reducers';
import type { Dispatch } from '../../../redux/types/types';
import { ExtendedItemType } from '../../../helpers/ui/item/types';
import { FEATURE_SUBSCRIPTION } from '../../../redux/appConf/constants';
import { LoadableStatus } from '../../../helpers/loadable/loadable';
import { Localizer } from '@ntg/utils/dist/localization';
import type { NETGEM_API_V8_AUTHENT_REALM } from '../../../libs/netgemLibrary/v8/types/Realm';
import type { NETGEM_API_V8_METADATA_SCHEDULE } from '../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { NETGEM_API_V8_REQUEST_RESPONSE } from '../../../libs/netgemLibrary/v8/types/RequestResponse';
import type { NETGEM_API_V8_RIGHTS } from '../../../libs/netgemLibrary/v8/types/Rights';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import type { NETGEM_API_VIEWINGHISTORY } from '../../../libs/netgemLibrary/v8/types/ViewingHistory';
import { PROGRAM_INFO } from '../../../helpers/ui/metadata/Types';
import { RegistrationType } from '../../../redux/appRegistration/types/types';
import StatusPicto from '../../statusPicto/StatusPicto';
import WatchingStatus from '../../watchingStatus/WatchingStatus';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { getImageUrl } from '../../../redux/netgemApi/actions/v8/metadataImage';
import { getProgress } from '../../../helpers/viewingHistory/ViewingHistory';
import { getSeriesCardUrlDefinition } from '../../../helpers/ui/section/tile';
import getTranslatedText from '../../../libs/netgemLibrary/v8/helpers/Lang';
import { hideModal } from '../../../redux/modal/actions';
import { ignoreIfAborted } from '../../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import { isPlaybackGranted } from '../../../helpers/rights/playback';
import sendV8MetadataLocationRequest from '../../../redux/netgemApi/actions/v8/metadataLocation';
import { sendV8RecordingsMetadataRequest } from '../../../redux/netgemApi/actions/v8/recordings';
import { showDebug } from '../../../helpers/debug/debug';

// Time display and broadcast status are refreshed every second (in ms)
const REFRESH_TIMEOUT = 1_000;

const IMAGE_WIDTH = 284;
const IMAGE_HEIGHT = 160;

type ReduxTVEpisodeDispatchToPropsType = {|
  +localGetImageUrl: (assetId: string, width: number, height: number, signal?: AbortSignal) => Promise<any>,
  +localHideModal: BasicCallbackFunction,
  +localSendV8MetadataLocationRequest: (assetId: string, signal?: AbortSignal) => Promise<any>,
  +localSendV8RecordingsMetadataRequest: (recordId: string, signal?: AbortSignal) => Promise<any>,
|};

type ReduxTVEpisodeReducerStateType = {|
  +channels: ChannelMap,
  +commercialOffers: ?KeyValuePair<Array<string>>,
  +defaultOnItemClick: ?NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE,
  +defaultRights: ?NETGEM_API_V8_RIGHTS,
  +isDebugModeEnabled: boolean,
  +isRegisteredAsGuest: boolean,
  +isSubscriptionFeatureEnabled: boolean,
  +npvrRecordingsFuture: NETGEM_RECORDINGS_MAP,
  +npvrRecordingsList: NETGEM_RECORDINGS_MAP,
  +npvrScheduledRecordingsList: NETGEM_SCHEDULED_RECORDINGS_MAP,
  +userRights: Array<string> | null,
  +viewingHistory: NETGEM_API_VIEWINGHISTORY,
  +viewingHistoryStatus: LoadableStatus,
|};

type DefaultProps = {|
  +focusedLocationId?: string,
  +onItemClick?: NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE,
|};

type TVEpisodePropType = {|
  ...DefaultProps,
  +cardData: CARD_DATA_MODAL_TYPE,
  +isExpanded: boolean,
  +index: number,
  +item: NETGEM_API_V8_FEED_ITEM,
  +onToggleExpandedState: (index: number) => void,
  +programMetadata: NETGEM_API_V8_METADATA_PROGRAM | null,
  +seriesMetadata: NETGEM_API_V8_METADATA_SERIES,
  +tileType: TileConfigTileType,
|};

type CompleteTVEpisodePropType = {|
  ...TVEpisodePropType,
  ...ReduxTVEpisodeReducerStateType,
  ...ReduxTVEpisodeDispatchToPropsType,
|};

type TVEpisodeStateType = {|
  authority?: NETGEM_API_V8_AUTHENT_REALM,
  broadcastStatus: BroadcastStatus,
  canBePlayed: boolean,
  imageUrl: ?string,
  isLiveRecording: boolean,
  now: number,
  previewCatchupScheduledEventId: string | null,
  tvLocationMetadata: ?NETGEM_API_V8_METADATA_SCHEDULE,
|};

const InitialState = Object.freeze({
  authority: undefined,
  broadcastStatus: BroadcastStatus.Unknown,
  canBePlayed: false,
  imageUrl: null,
  isLiveRecording: false,
  previewCatchupScheduledEventId: null,
  tvLocationMetadata: null,
});

class TVEpisode extends React.PureComponent<CompleteTVEpisodePropType, TVEpisodeStateType> {
  abortController: AbortController;

  broadcastStatusRefreshTimer: TimeoutID | null;

  failedRecordingId: ?string;

  isSvodDisplayedAsCatchup: boolean;

  recordOutcome: ?RecordingOutcome;

  synopsis: ?string;

  timeRefreshTimer: TimeoutID | null;

  warningScheduledEventId: ?string;

  warningScheduledRecordingId: ?string;

  static defaultProps: DefaultProps = {
    focusedLocationId: undefined,
    onItemClick: undefined,
  };

  constructor(props: CompleteTVEpisodePropType) {
    super(props);

    const {
      item: { locType },
    } = props;

    this.abortController = new AbortController();
    this.broadcastStatusRefreshTimer = null;
    this.failedRecordingId = null;
    this.isSvodDisplayedAsCatchup = locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD;
    this.recordOutcome = null;
    this.synopsis = null;
    this.timeRefreshTimer = null;
    this.warningScheduledEventId = null;
    this.warningScheduledRecordingId = null;

    this.state = {
      ...InitialState,
      now: AccurateTimestamp.nowInSeconds(),
    };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps: CompleteTVEpisodePropType, prevState: TVEpisodeStateType) {
    const { npvrRecordingsList, npvrScheduledRecordingsList } = this.props;
    const { npvrRecordingsList: prevNpvrRecordingsList, npvrScheduledRecordingsList: prevNpvrScheduledRecordingsList } = prevProps;
    const { broadcastStatus, previewCatchupScheduledEventId } = this.state;
    const { broadcastStatus: prevBroadcastStatus, previewCatchupScheduledEventId: prevPreviewCatchupScheduledEventId } = prevState;

    if (broadcastStatus !== prevBroadcastStatus) {
      this.loadTVLocationMetadata();

      if (broadcastStatus === BroadcastStatus.Live) {
        // Item became live
        this.startTimeRefreshTimer();
      } else {
        // Item is not live anymore
        this.resetTimeRefreshTimer();
      }

      this.updateRecordStatus();
    }

    if (npvrRecordingsList !== prevNpvrRecordingsList || npvrScheduledRecordingsList !== prevNpvrScheduledRecordingsList || previewCatchupScheduledEventId !== prevPreviewCatchupScheduledEventId) {
      this.updateRecordStatus();
    }
  }

  componentWillUnmount() {
    const { abortController } = this;

    abortController.abort('Component TVEpisode will unmount');

    this.resetTimeRefreshTimer();
    this.resetBroadcastStatusRefreshTimer();
  }

  showDebugInfo: () => void = () => {
    const { props, state } = this;

    showDebug('SeriesSection TVEpisode', {
      instance: this,
      instanceFields: ['failedRecordingId', 'isSvodDisplayedAsCatchup', 'recordOutcome', 'synopsis', 'warningScheduledRecordingId', 'warningScheduledEventId'],
      misc: { watchingStatus: this.getWatchingStatus() },
      props,
      propsFields: ['focusedLocationId', 'index', 'isExpanded', 'item', 'onItemClick', 'programMetadata', 'seriesMetadata'],
      state,
      stateFields: ['authority', 'broadcastStatus', 'canBePlayed', 'imageUrl', 'isLiveRecording', 'now', 'previewCatchupScheduledEventId', 'tvLocationMetadata'],
    });
  };

  startTimeRefreshTimer: () => void = () => {
    this.resetTimeRefreshTimer();
    this.timeRefreshTimer = setTimeout(this.updateTime, REFRESH_TIMEOUT);
  };

  resetTimeRefreshTimer: () => void = () => {
    if (this.timeRefreshTimer) {
      clearTimeout(this.timeRefreshTimer);
      this.timeRefreshTimer = null;
    }
  };

  startBroadcastStatusRefreshTimer: () => void = () => {
    const { broadcastStatus } = this.state;

    this.resetBroadcastStatusRefreshTimer();

    if (broadcastStatus === BroadcastStatus.Past) {
      // A past item will never change, no need to check again
      return;
    }

    this.broadcastStatusRefreshTimer = setTimeout(this.updateBroadcastStatus, REFRESH_TIMEOUT);
  };

  resetBroadcastStatusRefreshTimer: () => void = () => {
    if (this.broadcastStatusRefreshTimer) {
      clearTimeout(this.broadcastStatusRefreshTimer);
      this.broadcastStatusRefreshTimer = null;
    }
  };

  updateTime: () => void = () => {
    this.setState({ now: AccurateTimestamp.nowInSeconds() }, this.startTimeRefreshTimer);
  };

  updateBroadcastStatus: () => void = () => {
    const { item } = this.props;
    const { isSvodDisplayedAsCatchup } = this;

    // Item is an SVOD but is displayed as a catchup (e.g. Okoo)
    this.setState({ broadcastStatus: isSvodDisplayedAsCatchup ? BroadcastStatus.Past : getBroadcastStatus(item) }, this.startBroadcastStatusRefreshTimer);
  };

  update: () => void = () => {
    const { programMetadata } = this.props;

    this.synopsis = getSynopsis(programMetadata, Localizer.language);

    this.setState({
      ...InitialState,
      authority: programMetadata?.authority,
    });

    this.loadImage();
    this.updateBroadcastStatus();
  };

  isPlayable = (): boolean => {
    const {
      item: { locType },
    } = this.props;
    const { broadcastStatus } = this.state;
    const { isSvodDisplayedAsCatchup, recordOutcome } = this;

    return (
      (broadcastStatus === BroadcastStatus.Past && locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP) ||
      broadcastStatus === BroadcastStatus.Live ||
      broadcastStatus === BroadcastStatus.Preview ||
      (locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING && recordOutcome === RecordingOutcome.Recorded) ||
      isSvodDisplayedAsCatchup
    );
  };

  updateRecordStatus: () => void = () => {
    const {
      item,
      item: { selectedProgramId },
      npvrRecordingsFuture,
      npvrRecordingsList,
      npvrScheduledRecordingsList,
      seriesMetadata,
    } = this.props;
    const { broadcastStatus, previewCatchupScheduledEventId } = this.state;

    const { failedRecordingId, hasRecording, recordOutcome, warningScheduledEventId, warningScheduledRecordingId } = getEpisodeAndSeriesRecordStatus(
      selectedProgramId,
      seriesMetadata?.id,
      item,
      npvrRecordingsList,
      npvrRecordingsFuture,
      npvrScheduledRecordingsList,
      previewCatchupScheduledEventId,
    );

    const scheduledRecordingId = warningScheduledRecordingId || (failedRecordingId ? getScheduledRecordingIdFromRecordingId(failedRecordingId, npvrScheduledRecordingsList) : null);

    this.recordOutcome = recordOutcome;
    this.failedRecordingId = failedRecordingId;
    this.warningScheduledRecordingId = scheduledRecordingId;
    this.warningScheduledEventId = warningScheduledEventId;

    this.setState({
      canBePlayed: this.isPlayable(),
      isLiveRecording: hasRecording && broadcastStatus === BroadcastStatus.Live,
    });
  };

  loadImage: () => void = () => {
    const {
      item: { selectedProgramId },
      localGetImageUrl,
    } = this.props;
    const {
      abortController: { signal },
    } = this;

    localGetImageUrl(selectedProgramId, IMAGE_WIDTH, IMAGE_HEIGHT, signal)
      .then((imageUrl: string) => {
        signal.throwIfAborted();

        this.setState({ imageUrl });
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  loadTVLocationMetadata: () => void = () => {
    const {
      item,
      item: {
        locType,
        selectedLocation: { id },
      },
      localSendV8MetadataLocationRequest,
      localSendV8RecordingsMetadataRequest,
    } = this.props;
    const { authority, broadcastStatus } = this.state;
    const {
      abortController: { signal },
    } = this;

    if (!id) {
      return;
    }

    const locationStatus = getLocationStatus(item, authority);
    const request =
      locationStatus === WebAppHelpersLocationStatus.Recording || (locationStatus === WebAppHelpersLocationStatus.Live && locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING)
        ? localSendV8RecordingsMetadataRequest
        : localSendV8MetadataLocationRequest;

    request(id, signal)
      .then((requestResponse: NETGEM_API_V8_REQUEST_RESPONSE) => {
        signal.throwIfAborted();

        const {
          result,
          result: {
            location: { target },
          },
        } = requestResponse;
        this.setState({
          previewCatchupScheduledEventId: broadcastStatus === BroadcastStatus.Preview ? target : null,
          tvLocationMetadata: result,
        });

        const { focusedLocationId, index, isExpanded, onToggleExpandedState } = this.props;

        /*
         * In the case of a live episode being recorded, the focused location is a scheduled event, while the episode
         * location is a recording, so we have to look at the target (GM-2513)
         */
        if (!isExpanded && result.location.target === focusedLocationId) {
          onToggleExpandedState(index);
        }
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  handlePlayButtonOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    const { cardData, channels, commercialOffers, defaultRights, isRegisteredAsGuest, isSubscriptionFeatureEnabled, item, localHideModal, programMetadata, seriesMetadata, userRights } = this.props;
    const { authority, previewCatchupScheduledEventId, tvLocationMetadata } = this.state;

    if (!programMetadata) {
      return;
    }

    const localCardData: CARD_DATA_MODAL_TYPE = {
      ...cardData,
      item,
      programMetadata,
      seriesMetadata,
    };

    if (
      !isPlaybackGranted(
        isRegisteredAsGuest,
        isSubscriptionFeatureEnabled,
        localCardData,
        authority,
        ItemContent.TV,
        tvLocationMetadata?.location,
        channels,
        commercialOffers,
        defaultRights,
        userRights,
      )
    ) {
      // Playback denied: log-in or subscribe page has been requested
      localHideModal();
      return;
    }

    Messenger.emit(MessengerEvents.OPEN_PLAYER, {
      authority,
      item,
      openFromCard: true,
      previewCatchupScheduledEventId,
      programMetadata,
      seriesMetadata,
      type: ExtendedItemType.TV,
    });
  };

  handleOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    const { index, isDebugModeEnabled, onToggleExpandedState } = this.props;
    const { altKey, ctrlKey } = event;

    if (isDebugModeEnabled && (ctrlKey || altKey)) {
      this.showDebugInfo();
    } else {
      onToggleExpandedState(index);
    }
  };

  getWatchingStatus = (): number | null => {
    const { item, programMetadata, seriesMetadata, viewingHistory, viewingHistoryStatus } = this.props;

    if (viewingHistoryStatus !== LoadableStatus.Loaded) {
      return null;
    }

    return getProgress(viewingHistory, item, programMetadata, seriesMetadata, false, null);
  };

  handleErrorPictoOnClick: () => void = () => {
    const { failedRecordingId, warningScheduledRecordingId, warningScheduledEventId } = this;

    Messenger.emit(MessengerEvents.OPEN_NPVR_MANAGEMENT_SCREEN, warningScheduledRecordingId, failedRecordingId, warningScheduledEventId);
  };

  renderRecordErrorPicto = (recordOutcome: ?RecordingOutcome): React.Element<any> | null => {
    const { broadcastStatus } = this.state;

    if (!recordOutcome) {
      return null;
    }

    let description: ?string = null;
    if (recordOutcome === RecordingOutcome.OutOfQuota) {
      description = Localizer.localize('modal.card.status_picto.warning_out_of_quota');
    } else if (recordOutcome === RecordingOutcome.ExceededConcurrency) {
      description = Localizer.localize('modal.card.status_picto.warning_exceeded_concurrency');
    } else if (recordOutcome === RecordingOutcome.ServerError) {
      description = Localizer.localize('modal.card.status_picto.warning_server');
    } else {
      return null;
    }

    let pictoElement = null;
    if (broadcastStatus === BroadcastStatus.Past || broadcastStatus === BroadcastStatus.Live) {
      description = Localizer.localize('modal.card.status_picto.failure');
      pictoElement = <PictoFailure className='alertPicto failure' />;
    } else {
      pictoElement = <PictoWarning className='alertPicto warning' />;
    }

    return (
      <div className='recordError' onClick={this.handleErrorPictoOnClick}>
        {pictoElement}
        <div className='description'>{description}</div>
      </div>
    );
  };

  renderTitle = (): React.Element<any> | null => {
    const { item, programMetadata } = this.props;
    const { now } = this.state;

    if (!programMetadata) {
      return null;
    }

    const { titles } = programMetadata;
    const { endTime, startTime } = getStartAndEndTimes(item);
    const date = formatDate(startTime, endTime, now);

    let title = getTranslatedText(titles, Localizer.language);
    const episodeIndex = formatSeasonEpisodeNbr(programMetadata);
    if (episodeIndex) {
      title = `${episodeIndex}${title ? ' : ' : ''}${title}`;
    }

    return (
      <div className='dateAndTitle'>
        <div className='date'>{date}</div>
        <div className='title'>{title}</div>
      </div>
    );
  };

  renderActions = (): React.Element<any> => {
    const {
      defaultOnItemClick,
      item,
      item: { locType },
      onItemClick,
      programMetadata,
      seriesMetadata,
      tileType,
    } = this.props;
    const { broadcastStatus, canBePlayed, previewCatchupScheduledEventId, tvLocationMetadata } = this.state;

    let progress: number | null = null;
    if (broadcastStatus === BroadcastStatus.Live) {
      progress = 0;
    } else if (broadcastStatus === BroadcastStatus.Past || broadcastStatus === BroadcastStatus.Preview) {
      progress = this.getWatchingStatus();
    }

    const watchingStatus = canBePlayed ? <WatchingStatus allowZeroProgress onClick={this.handlePlayButtonOnClick} progress={progress ?? 0} /> : null;

    let cardDataUrlDefinition: Undefined<NETGEM_API_V8_URL_DEFINITION> = undefined;
    if (seriesMetadata && tvLocationMetadata) {
      // In case of TV series item, add the series card URL definition (if found) so that the episodes will be displayed on the card
      const urlDefinition = getSeriesCardUrlDefinition(onItemClick, defaultOnItemClick);
      if (urlDefinition) {
        cardDataUrlDefinition = urlDefinition;
      }
    }

    return (
      <>
        <RecordButton
          broadcastStatus={broadcastStatus}
          cardDataUrlDefinition={cardDataUrlDefinition}
          context={ContextKind.TVEpisode}
          displayMode={DisplayMode.Picto}
          item={item}
          key='recordButton'
          kind={locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP ? NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_KEEP_FROM_REPLAY : NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SINGLE}
          location={tvLocationMetadata?.location ?? null}
          previewCatchupScheduledEventId={previewCatchupScheduledEventId}
          programMetadata={programMetadata}
          seriesMetadata={seriesMetadata}
          tileType={tileType}
        />
        {watchingStatus}
      </>
    );
  };

  renderTime = (): React.Element<any> => {
    const {
      item,
      item: { locType, selectedLocation },
    } = this.props;
    const { availabilityEndDate } = selectedLocation || {};

    if ((locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP || locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING) && availabilityEndDate) {
      // Expiration date
      return (
        <div className='date expiration' key='expirationDate'>
          {formatExpirationDate(availabilityEndDate)}
        </div>
      );
    }

    // Broadcast date and time
    const { endTime, startTime } = getStartAndEndTimes(item);

    return (
      <div className='date broadcast' key='broadcastDate'>
        {formatDate(startTime, endTime, AccurateTimestamp.nowInSeconds())}
      </div>
    );
  };

  renderHeader = (): React.Element<any> => {
    const { broadcastStatus } = this.state;

    const isFuture = broadcastStatus === BroadcastStatus.Unknown || broadcastStatus === BroadcastStatus.Future;

    return (
      <div className={clsx('header', isFuture && 'future')} onClick={this.handleOnClick}>
        {isFuture ? <PictoClockEmpty className='clock' hasHoverEffect={false} /> : null}
        {this.renderTitle()}
        <div className='actions'>
          {this.renderActions()}
          <PictoArrowDown className='arrow' />
        </div>
      </div>
    );
  };

  renderContent = (): React.Element<any> => {
    const { item, programMetadata } = this.props;
    const { imageUrl, isLiveRecording } = this.state;
    const { recordOutcome, synopsis } = this;

    const imageStyle = imageUrl ? { backgroundImage: `url(${imageUrl})` } : {};
    const recordErrorPicto = this.renderRecordErrorPicto(recordOutcome);

    const liveRecordingElement = isLiveRecording ? (
      <div className='liveRecording'>
        <PictoRecord className='recording live' hasHoverEffect={false} />
        <div className='text'>{Localizer.localize('common.status.live_recording')}</div>
      </div>
    ) : null;

    return (
      <div className='content'>
        <div className='cover' style={imageStyle} />
        <div className='details'>
          <div className='programInfo'>
            <StatusPicto item={item} />
            {liveRecordingElement}
            {recordErrorPicto}
            {this.renderTime()}
          </div>
          <div className='programInfo'>
            {/* eslint-disable-next-line no-bitwise */}
            {renderProgramDetails(item, programMetadata, null, PROGRAM_INFO.Duration | PROGRAM_INFO.ReleaseDate | PROGRAM_INFO.Language | PROGRAM_INFO.ParentalGuidance)}
          </div>
          <div className='synopsis'>{synopsis}</div>
        </div>
      </div>
    );
  };

  render(): React.Node {
    const { isExpanded } = this.props;

    return (
      <div className={clsx('episode', 'tv', isExpanded && 'expanded')}>
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxTVEpisodeReducerStateType => {
  return {
    channels: state.appConfiguration.deviceChannels,
    commercialOffers: state.appConfiguration.rightsCommercialOffers,
    defaultOnItemClick: state.ui.defaultActions ? state.ui.defaultActions.onItemClick : null,
    defaultRights: state.appConfiguration.rightsDefault,
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
    isRegisteredAsGuest: state.appRegistration.registration === RegistrationType.RegisteredAsGuest,
    isSubscriptionFeatureEnabled: state.appConfiguration.features[FEATURE_SUBSCRIPTION],
    npvrRecordingsFuture: state.npvr.npvrRecordingsFuture,
    npvrRecordingsList: state.npvr.npvrRecordingsList,
    npvrScheduledRecordingsList: state.npvr.npvrScheduledRecordingsList,
    userRights: state.appConfiguration.rightsUser,
    viewingHistory: state.ui.viewingHistory,
    viewingHistoryStatus: state.ui.viewingHistoryStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxTVEpisodeDispatchToPropsType => {
  return {
    localGetImageUrl: (assetId: string, width: number, height: number, signal?: AbortSignal): Promise<any> =>
      dispatch(
        getImageUrl(
          {
            assetId,
            height,
            width,
          },
          signal,
        ),
      ),

    localHideModal: () => dispatch(hideModal()),

    localSendV8MetadataLocationRequest: (assetId: string, signal?: AbortSignal): Promise<any> => dispatch(sendV8MetadataLocationRequest(assetId, signal)),

    localSendV8RecordingsMetadataRequest: (recordId: string, signal?: AbortSignal): Promise<any> => dispatch(sendV8RecordingsMetadataRequest(recordId, signal)),
  };
};

const TVEpisodeView: React.ComponentType<TVEpisodePropType> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TVEpisode);

export default TVEpisodeView;
