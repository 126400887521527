/* @flow */

import './NpvrHelpModal.css';
import * as React from 'react';
import { PictoFailure, PictoWarning } from '@ntg/components/dist/pictos/Element';
import ButtonFX from '../../buttons/ButtonFX';
import type { CombinedReducers } from '../../../redux/reducers';
import { HeightKind } from '../../buttons/types';
import { Localizer } from '@ntg/utils/dist/localization';
import Modal from '../modal';
import { ModalIcon } from '../modalTypes';
import type { ModalState } from '../../../redux/modal/reducers';
import type { NETGEM_FUTURE_RECORDINGS_STATUS } from '../../../libs/netgemLibrary/v8/types/Npvr';
import { connect } from 'react-redux';
import { formatDuration } from '../../../helpers/dateTime/Format';

// 80 hours of recordings
const DEFAULT_QUOTA = 80;

type ReduxNpvrHelpModalReducerStateType = {|
  +npvrTotalQuota: string | null,
|};

type CompleteNpvrHelpModalPropType = {|
  ...ReduxNpvrHelpModalReducerStateType,
  ...ModalState,
|};

class NpvrHelpModalView extends React.PureComponent<CompleteNpvrHelpModalPropType> {
  render(): React.Node {
    const { index, npvrTotalQuota } = this.props;

    // Recordings are deleted after 3 months
    const recordingAvailability = 1;
    // Up to 2 recordings at the same time
    const maxConcurrency = 2;

    const children = (
      <>
        <div className='header'>{Localizer.localize('modal.npvr_help.title')}</div>
        <div className='helpContent'>
          <div className='sectionTitle'>{Localizer.localize('modal.npvr_help.principles.title')}</div>
          <ul>
            <li>{Localizer.localize('modal.npvr_help.principles.quota', { quota: npvrTotalQuota ?? DEFAULT_QUOTA })}</li>
            <li>{Localizer.localize('modal.npvr_help.principles.availability', { availability: recordingAvailability })}</li>
            <li>{Localizer.localize('modal.npvr_help.principles.concurrency', { concurrency: maxConcurrency })}</li>
            <li>{Localizer.localize('modal.npvr_help.principles.series_recording')}</li>
            <li>{Localizer.localize('modal.npvr_help.principles.series_episode_cancel')}</li>
            <li>{Localizer.localize('modal.npvr_help.principles.channels_without_npvr')}</li>
          </ul>
          <div className='sectionTitle'>{Localizer.localize('modal.npvr_help.visual_indicators.title')}</div>
          <ul className='noBullet'>
            <li>
              <div>
                <ButtonFX heightKind={HeightKind.Medium} isDisabled>
                  {Localizer.localize('modal.npvr_help.visual_indicators.record_action')}
                </ButtonFX>
                <div className='hint'>{Localizer.localize('modal.npvr_help.visual_indicators.record')}</div>
              </div>
            </li>
            <li>
              <div>
                <ButtonFX heightKind={HeightKind.Medium} isDisabled>
                  {Localizer.localize('modal.npvr_help.visual_indicators.delete_recording_action')}
                </ButtonFX>
                <div className='hint'>{Localizer.localize('modal.npvr_help.visual_indicators.delete_recording')}</div>
              </div>
            </li>
            <li>
              <div>
                <PictoFailure className='alertPicto failure' hasHoverEffect={false} />
                <div className='hint'>{Localizer.localize('modal.npvr_help.visual_indicators.failure_exceeded_concurrency')}</div>
              </div>
            </li>
            <li>
              <div>
                <PictoFailure className='alertPicto failure' hasHoverEffect={false} />
                <div className='hint'>{Localizer.localize('modal.npvr_help.visual_indicators.failure_out_of_quota')}</div>
              </div>
            </li>
            <li>
              <div>
                <PictoFailure className='alertPicto failure' hasHoverEffect={false} />
                <div className='hint'>{Localizer.localize('modal.npvr_help.visual_indicators.failure_server')}</div>
              </div>
            </li>
            <li>
              <div>
                <PictoWarning className='alertPicto warning' hasHoverEffect={false} />
                <div className='hint'>{Localizer.localize('modal.npvr_help.visual_indicators.warning_exceeded_concurrency')}</div>
              </div>
            </li>
            <li>
              <div>
                <PictoWarning className='alertPicto warning' hasHoverEffect={false} />
                <div className='hint'>{Localizer.localize('modal.npvr_help.visual_indicators.warning_out_of_quota')}</div>
              </div>
            </li>
          </ul>
        </div>
      </>
    );

    return (
      <Modal className='npvrHelp' icon={ModalIcon.Info} index={index}>
        {children}
      </Modal>
    );
  }
}

const getNpvrTotalQuota = (status: ?NETGEM_FUTURE_RECORDINGS_STATUS): string | null => {
  if (!status) {
    return null;
  }

  const {
    maxQuota: { duration },
  } = status;

  return formatDuration(duration);
};

const mapStateToProps = (state: CombinedReducers): ReduxNpvrHelpModalReducerStateType => {
  return {
    npvrTotalQuota: getNpvrTotalQuota(state.npvr.npvrRecordingsFutureStatus),
  };
};

const NpvrHelpModal: React.ComponentType<ModalState> = connect(mapStateToProps, null, null, { forwardRef: true })(NpvrHelpModalView);

export default NpvrHelpModal;
