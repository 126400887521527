/* @flow */

// $FlowFixMe: CSS file only exists in dist folder
import './Base.css';
import * as React from 'react';
// $FlowFixMe: Flow cannot resolve clsx
import clsx from 'clsx';

export type ExportedPictoPropType = {|
  +className?: string,
  +draggable?: boolean,
  +forwardedRef?: any,
  +hasBackground?: boolean,
  +hasHoverEffect?: boolean,
  +isDisabled?: boolean,
  +key?: string,
  +onClick?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +onMouseDown?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +onMouseEnter?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +onMouseLeave?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +onMouseMove?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +onMouseUp?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
|};

export type PictoPropType = {|
  ...ExportedPictoPropType,
  +svgElement: React.Element<'svg'>,
|};

class PictoView extends React.PureComponent<PictoPropType> {
  static defaultProps: ExportedPictoPropType = {
    className: '',
    draggable: false,
    forwardedRef: undefined,
    hasBackground: false,
    hasHoverEffect: true,
    isDisabled: false,
    key: undefined,
    onClick: undefined,
    onMouseDown: undefined,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
    onMouseMove: undefined,
    onMouseUp: undefined,
  };

  render(): React.Node {
    const {
      className = '',
      draggable,
      forwardedRef,
      hasBackground,
      hasHoverEffect,
      key,
      isDisabled,
      onClick,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onMouseMove,
      onMouseUp,
      svgElement,
    } = this.props;

    const onClickHandler = onClick && !isDisabled ? onClick : undefined;
    const onMouseDownHandler = onMouseDown && !isDisabled ? onMouseDown : undefined;
    const onMouseEnterHandler = onMouseEnter && !isDisabled ? onMouseEnter : undefined;
    const onMouseLeaveHandler = onMouseLeave && !isDisabled ? onMouseLeave : undefined;
    const onMouseMoveHandler = onMouseMove && !isDisabled ? onMouseMove : undefined;
    const onMouseUpHandler = onMouseUp && !isDisabled ? onMouseUp : undefined;

    const background = hasBackground ? <div className='background' /> : null;

    return (
      <div
        className={clsx('pictoElement', className, hasHoverEffect && 'hoverEffect', isDisabled && 'disabled')}
        draggable={draggable}
        key={key}
        onClick={onClickHandler}
        onMouseDown={onMouseDownHandler}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        onMouseMove={onMouseMoveHandler}
        onMouseUp={onMouseUpHandler}
        ref={forwardedRef}
      >
        {background}
        {svgElement}
      </div>
    );
  }
}

export { PictoView };
