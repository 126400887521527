/* @flow */

import * as React from 'react';
import type { BasicCallbackFunction, KeyValuePair } from '@ntg/utils/dist/types';
import { ItemContent, type NETGEM_API_V8_FEED_ITEM } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_METADATA_PROGRAM, NETGEM_API_V8_METADATA_SERIES } from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import { type NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE, type TILE_CONFIG_TYPE, TileConfig } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import type { NETGEM_RECORDINGS_MAP, NETGEM_SCHEDULED_RECORDINGS_MAP } from '../../../libs/netgemLibrary/v8/types/Npvr';
import type { STREAM_PRIORITIES_TYPE, VOD_STATUS_TYPE } from '../../../helpers/ui/metadata/Types';
import type { BO_FAVORITE_LIST_TYPE } from '../../../redux/netgemApi/actions/videofutur/types/favorite';
import type { BO_PURCHASE_LIST_TYPE } from '../../../redux/netgemApi/actions/videofutur/types/purchase';
import { BroadcastStatus } from '../../../helpers/ui/location/Format';
import type { ChannelMap } from '../../../libs/netgemLibrary/v8/types/Channel';
import { LoadableStatus } from '../../../helpers/loadable/loadable';
import { MetadataStatus } from './ItemConstsAndTypes';
import type { NETGEM_API_V8_AUTHENT_REALM } from '../../../libs/netgemLibrary/v8/types/Realm';
import type { NETGEM_API_V8_METADATA_SCHEDULE } from '../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { NETGEM_API_V8_PURCHASE_INFO } from '../../../libs/netgemLibrary/v8/types/PurchaseInfo';
import type { NETGEM_API_V8_RIGHTS } from '../../../libs/netgemLibrary/v8/types/Rights';
import type { NETGEM_API_VIEWINGHISTORY } from '../../../libs/netgemLibrary/v8/types/ViewingHistory';
import type { VOD_PURCHASE_DATA_TYPE } from '../../../helpers/rights/pendingOperations';

export enum TruncateState {
  Initializing,
  NotTruncated,
  Truncated,
}

export type ActionsType = {|
  actions: Array<React.Element<any> | null>,
  mainButton: React.Element<any> | null,
|};

export type ReduxItemOverlayDispatchToPropsType = {|
  +localAddToFavoriteList: (titId: string, signal?: AbortSignal) => Promise<any>,
  +localAddToWishlist: (assetId: string, channelId: string, signal?: AbortSignal) => Promise<any>,
  +localHideModal: BasicCallbackFunction,
  +localRemoveFromFavoriteList: (titId: string, signal?: AbortSignal) => Promise<any>,
  +localRemoveFromWishlist: (assetId: string, channelId: string, signal?: AbortSignal) => Promise<any>,
  +showVodPurchaseDialog: (vodPurchaseData: VOD_PURCHASE_DATA_TYPE) => void,
|};

export type ReduxItemOverlayReducerStateType = {|
  +channels: ChannelMap,
  +commercialOffers: KeyValuePair<Array<string>> | null,
  +defaultOnItemClick: NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE | null,
  +defaultRights: NETGEM_API_V8_RIGHTS | null,
  +favoriteList: BO_FAVORITE_LIST_TYPE,
  +isDebugModeEnabled: boolean,
  +isRegisteredAsGuest: boolean,
  +isSubscriptionFeatureEnabled: boolean,
  +npvrRecordingsFuture: NETGEM_RECORDINGS_MAP,
  +npvrRecordingsList: NETGEM_RECORDINGS_MAP,
  +npvrScheduledRecordingsList: NETGEM_SCHEDULED_RECORDINGS_MAP,
  +purchaseList: BO_PURCHASE_LIST_TYPE | null,
  +streamPriorities: STREAM_PRIORITIES_TYPE | null,
  +userRights: Array<string> | null,
  +viewingHistory: NETGEM_API_VIEWINGHISTORY,
  +viewingHistoryStatus: LoadableStatus,
  +wishlist: Set<string>,
  +wishlistStatus: LoadableStatus,
|};

export type ItemOverlayPropType = {|
  +authority?: NETGEM_API_V8_AUTHENT_REALM,
  +broadcastStatus: BroadcastStatus,
  +contentType: ItemContent,
  +distributorId: string | null,
  +hoverContent: Set<TileConfig>,
  +isFocused: boolean,
  +isInLiveSection?: boolean,
  +item: NETGEM_API_V8_FEED_ITEM,
  +now: number,
  +onClick: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +onItemClick?: NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE,
  +previewCatchupScheduledEventId: string | null,
  +programMetadata: NETGEM_API_V8_METADATA_PROGRAM | null,
  +programMetadataStatus: MetadataStatus,
  +programTitle: string,
  +purchaseInfo: NETGEM_API_V8_PURCHASE_INFO | null,
  +seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null,
  +seriesMetadataStatus: MetadataStatus,
  +seriesTitle: string,
  +startoverItem: NETGEM_API_V8_FEED_ITEM | null,
  +tileConfig: TILE_CONFIG_TYPE,
  +tvLocationMetadata: NETGEM_API_V8_METADATA_SCHEDULE | null,
  +viewingHistoryId?: string | null,
  +vodLocationsMetadata: Array<NETGEM_API_V8_METADATA_SCHEDULE> | null,
  +vodStatus: VOD_STATUS_TYPE | null,
  +vtiId: ?number,
|};

export type CompleteItemOverlayPropType = {|
  ...ItemOverlayPropType,
  ...ReduxItemOverlayReducerStateType,
  ...ReduxItemOverlayDispatchToPropsType,
|};

export type ItemOverlayStateType = {|
  isLoading: boolean,
  synopsis: string | null,
  textHeight: number,
  truncateState: TruncateState,
|};
