/* @flow */

import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './TextScroller.module.scss';

// Delay between mouse hovering the text and start of scrolling (in ms)
const START_DELAY = 500;

// When trying to scroll on mount, retry every 50 ms while DOM elements get ready
const SCROLL_ON_MOUNT_DELAY = 50;

// Interval between 2 scroll steps (in ms)
const SCROLL_INTERVAL = 20;

export type TextScrollerStyle = {| [string]: string |};

const getRecursiveChildText = (node: React.Element<any> | null): string => {
  if (node === null) {
    return '';
  }

  if (Array.isArray(node)) {
    const joinedNodes = [];
    node.forEach((n: React.Element<any>) => {
      if (typeof n === 'object') {
        joinedNodes.push(getRecursiveChildText(n));
      } else if (typeof n === 'string') {
        joinedNodes.push(n);
      }
    });
    return joinedNodes.join('');
  }

  const {
    // $FlowFixMe: after updating to flow 0.219.4, "props" seems to be missing in React.Element
    props: { children },
  } = node;

  if (typeof children === 'object') {
    return getRecursiveChildText(children);
  }

  if (typeof children === 'string') {
    return children;
  }

  return '';
};

const measureTextWidth = (availableWidth: number, style: TextScrollerStyle, text: string = '') => {
  if (text === '') {
    return { displayedTextWidth: 0, realTextWidth: 0 };
  }

  const div = document.createElement('DIV');
  div.innerHTML = text;
  Object.entries(style).forEach(([key, value]) => {
    // $FlowFixMe: Flow is strangely expecting key to be an index (number)
    div.style[key] = value;
  });
  div.classList.add(styles.elementMeasure);

  document.body?.appendChild(div);
  const { width: displayedTextWidth } = div.getBoundingClientRect();
  const realTextWidth = div.offsetWidth;
  document.body?.removeChild(div);

  // Displayed width takes into account any "transform: scale(...)"
  return { displayedTextWidth, realTextWidth };
};

type PropType = {|
  +children: React.Element<any> | null,
  +scrollOnMount?: boolean, // eslint-disable-line react/require-default-props
  +style: TextScrollerStyle,
|};

const TextScroller = ({ children, scrollOnMount, style }: PropType): React.Node => {
  const [leftPct, setLeftPct] = useState(0);
  const elementRef = useRef<HTMLElement | null>(null);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);
  const [scrollStop, setScrollStop] = useState<number>(0);
  const mountDelayTimerId = useRef<TimeoutID | null>(null);
  const hoverTimerId = useRef<TimeoutID | null>(null);
  const scrollingIntervalId = useRef<IntervalID | null>(null);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      if (mountDelayTimerId.current !== null) {
        clearTimeout(mountDelayTimerId.current);
      }
      if (hoverTimerId.current !== null) {
        clearTimeout(hoverTimerId.current);
      }
      if (scrollingIntervalId.current !== null) {
        clearInterval(scrollingIntervalId.current);
      }
    };
  }, []);

  const getWidths = useCallback(() => {
    if (elementRef.current) {
      const { width } = elementRef.current.getBoundingClientRect();
      const text = getRecursiveChildText(children);
      const { displayedTextWidth, realTextWidth } = measureTextWidth(width, style, text);

      return { displayedTextWidth, realTextWidth, width };
    }

    return null;
  }, [children, style]);

  const translate = useCallback(() => {
    setLeftPct((currentLeftPct) => currentLeftPct - 1);
  }, []);

  const stopScrolling = useCallback(() => {
    if (hoverTimerId.current !== null) {
      clearTimeout(hoverTimerId.current);
      hoverTimerId.current = null;
    }

    if (scrollingIntervalId.current !== null) {
      clearInterval(scrollingIntervalId.current);
      scrollingIntervalId.current = null;
    }

    setLeftPct(0);
  }, []);

  const startScrolling: () => void = useCallback(() => {
    const widths = getWidths();

    if (widths === null) {
      if (mountDelayTimerId.current === null) {
        mountDelayTimerId.current = setTimeout(startScrolling, SCROLL_ON_MOUNT_DELAY);
      }
      return;
    }

    mountDelayTimerId.current = null;

    const { displayedTextWidth, realTextWidth, width } = widths;
    const truncated = displayedTextWidth > width;
    setIsTruncated(truncated);
    setScrollStop(-realTextWidth);

    if (!truncated) {
      // Not truncated: don't start scrolling
      return;
    }

    if (scrollingIntervalId.current === null) {
      scrollingIntervalId.current = setInterval(translate, SCROLL_INTERVAL);
    }
  }, [getWidths]);

  useEffect(() => {
    if (scrollStop === 0) {
      // Not ready yet
      return;
    }

    if (!isTruncated) {
      return;
    }

    if (leftPct <= scrollStop) {
      // Animation finished
      stopScrolling();
    }
  }, [isTruncated, leftPct, scrollStop, stopScrolling]);

  useEffect(() => {
    if (scrollOnMount && hoverTimerId.current === null) {
      // Start animation on mount
      hoverTimerId.current = setTimeout(startScrolling, START_DELAY);
    }
  }, [scrollOnMount]);

  const handleOnMouseEnter = useCallback(() => {
    if (hoverTimerId.current === null) {
      hoverTimerId.current = setTimeout(startScrolling, START_DELAY);
    }
  }, [startScrolling]);

  const handleOnMouseLeave = useCallback(() => {
    stopScrolling();
  }, [stopScrolling]);

  return (
    <div className={styles.wrapper} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} ref={elementRef}>
      {isTruncated ? <div style={{ transform: `translateX(${leftPct}px)` }}>{children}</div> : children}
    </div>
  );
};

export default TextScroller;
