/* @flow */

import * as React from 'react';
import type { KeyValuePair } from '@ntg/utils/dist/types';

export type NOTIFICATION_MESSAGE = string | React.Element<any>;
export type NOTIFICATION_SETTINGS = KeyValuePair<any>;

export type NOTIFICATION = {|
  message: NOTIFICATION_MESSAGE,
  settings?: NOTIFICATION_SETTINGS,
|};

// Cannot be converted to enum because strings come from Toastify
export const TOAST = Object.freeze({
  Error: 'error',
  Info: 'info',
  Success: 'success',
  Warning: 'warning',
});
export type NOTIFICATION_TYPE = 'info' | 'success' | 'warning' | 'error';
