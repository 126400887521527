/* @flow */

import { type NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND, RecordingOutcome } from '../../libs/netgemLibrary/v8/types/Npvr';

// All times below are in seconds
export type NPVR_QUOTA = {|
  formattedRecordingTime: string,
  formattedRemainingTime: string,
  formattedScheduledRecordingTime: string,
  formattedTotalQuota: string,
  recordingTime: number,
  remainingTime: number,
  scheduledRecordingTime: number,
  totalQuota: number,
|};

export const SERIES_RECORDING: 'SERIES_RECORDING' = 'SERIES_RECORDING';
export const SERIES_RECORDING_WITH_SINGLE: 'SERIES_RECORDING_WITH_SINGLE' = 'SERIES_RECORDING_WITH_SINGLE';
export const SINGLE_RECORDING: 'SINGLE_RECORDING' = 'SINGLE_RECORDING';
export type RECORDING_TYPE = typeof SERIES_RECORDING | typeof SERIES_RECORDING_WITH_SINGLE | typeof SINGLE_RECORDING;

export type ITEM_RECORD_STATUS = {|
  failedRecordingId?: string,
  hasRecording: boolean,
  hasScheduledRecording: boolean,
  hasSeriesScheduledRecording: boolean,
  recordOutcome?: RecordingOutcome,
  warningScheduledEventId?: string,
  warningScheduledRecordingId?: string,
|};

export type SCHEDULED_RECORDING_CORE_SETTINGS = {|
  fromUtc?: string | null,
  increasingEpisodes?: boolean,
  recordsToKeep?: number,
  toUtc?: string | null,
|};

export type SCHEDULED_RECORDING_CREATION_SETTINGS = {|
  ...SCHEDULED_RECORDING_CORE_SETTINGS,
  channelId?: string | null,
  fromBeginning?: boolean | null,
  scheduledRecordKind: NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND,
  target: string,
|};

export type SCHEDULED_RECORDING_UPDATE_SETTINGS = {|
  ...SCHEDULED_RECORDING_CORE_SETTINGS,
  assetId: string,
|};

export const RECORD_BUTTON_ACTION_TYPE_DELETE: 'delete' = 'delete';
export const RECORD_BUTTON_ACTION_TYPE_NONE: 'none' = 'none';
export const RECORD_BUTTON_ACTION_TYPE_RECORD: 'record' = 'record';
export type RECORD_BUTTON_ACTION_TYPE = typeof RECORD_BUTTON_ACTION_TYPE_DELETE | typeof RECORD_BUTTON_ACTION_TYPE_NONE | typeof RECORD_BUTTON_ACTION_TYPE_RECORD;
