/* @flow */

import './Avenue.css';
import * as React from 'react';
import { AVENUE_HEADER_HEIGHT, AVENUE_HEADER_WIDTH, AVENUE_IMAGE_HEIGHT, AVENUE_IMAGE_WIDTH } from '../../helpers/ui/constants';
import { FEATURE_NPVR, FEATURE_TV, FEATURE_VOD } from '../../redux/appConf/constants';
import { type NETGEM_API_V8_WIDGET_PANEL_IMAGE_URL_TYPE, WidgetLayoutType } from '../../libs/netgemLibrary/v8/types/WidgetConfig';
import { PictoMagnifier, PictoNoContent, PictoSadFace } from '@ntg/components/dist/pictos/Element';
import { Theme, type ThemeType } from '@ntg/ui/dist/theme';
import { getImageUrl, getImageUrlFromDefinition } from '../../redux/netgemApi/actions/v8/metadataImage';
import { goBackToFocusedAvenue, updateGridSectionId, updateSearchString } from '../../redux/ui/actions';
import { AVENUE_ID_EXPLORE } from '../../helpers/ui/avenue/constants';
import type { AppConfigurationFeatures } from '../../redux/appConf/types/types';
import { AvenueType } from '../../helpers/ui/avenue/types';
import { type BasicCallbackFunction } from '@ntg/utils/dist/types';
import ButtonBack from '../buttons/ButtonBack';
import type { CombinedReducers } from '../../redux/reducers';
import type { Dispatch } from '../../redux/types/types';
import HotKeys from '../../helpers/hotKeys/hotKeys';
import { KEY } from '../../helpers/hotKeys/keysDefinition';
import { Localizer } from '@ntg/utils/dist/localization';
import { type NETGEM_API_V8_AVENUE } from '../../libs/netgemLibrary/v8/types/Avenue';
import { type NETGEM_API_V8_FEED_ITEM } from '../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_HUB } from '../../libs/netgemLibrary/v8/types/Hub';
import type { NETGEM_API_V8_SECTION } from '../../libs/netgemLibrary/v8/types/Section';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import { SEARCH_STRING_MIN_LENGTH } from '../../helpers/search/constants';
import SearchHistory from '../searchHistory/SearchHistory';
import Section from './section/Section';
import SectionCarousel from './sectionCarousel/Section';
import SectionChannelGrid from './sectionChannelGrid/Section';
import SectionChannelGroup from './sectionChannelGroup/Section';
import { SectionType } from '../../helpers/ui/section/types';
import { areAvenuesDifferent } from '../../helpers/ui/section/comparisons';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { getLocalizedString } from '../../helpers/ui/metadata/Format';
import { ignoreIfAborted } from '../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import { produce } from 'immer';
import { saveSearchString } from '../../helpers/search/search';

// After the following amount of time (in ms), we act as if the first 3 sections have been loaded and start loading the remaining ones
const FIRST_SECTIONS_LOAD_TIMEOUT = 5_000;

const VISIBLE_SECTION_COUNT = 3;

const EMPTY_AVENUE_IMAGE_WIDTH = 224;
const EMPTY_AVENUE_IMAGE_HEIGHT = 192;

type ReduxAvenueDispatchToPropsType = {|
  +localGetImageUrl: (assetId: string, width: number, height: number, theme?: ThemeType, signal?: AbortSignal) => Promise<any>,
  +localGetImageUrlFromDefinition: (urlDefinition: NETGEM_API_V8_URL_DEFINITION) => Promise<any>,
  +localGoBackToFocusedAvenue: BasicCallbackFunction,
  +localUpdateGridSectionId: (gridSectionId: string | null) => void,
  +localUpdateSearchString: (searchString: string) => void,
|};

type ReduxAvenueReducerStateType = {|
  +avenueList: ?NETGEM_API_V8_HUB, // eslint-disable-line react/no-unused-prop-types
  +features: AppConfigurationFeatures,
  +gridSectionId: string | null,
  +searchString: string | null,
|};

type DefaultProps = {|
  +hasBackButton?: boolean,
  +loadedCallback?: BasicCallbackFunction,
|};

export type AvenuePropType = {|
  ...DefaultProps,
  +hub: ?NETGEM_API_V8_HUB, // eslint-disable-line react/no-unused-prop-types
  +hubItem: ?NETGEM_API_V8_FEED_ITEM,
  +index: number,
  +isInExploreModal: boolean,
  +type: AvenueType,
|};

type CompleteAvenuePropType = {|
  ...AvenuePropType,
  ...ReduxAvenueReducerStateType,
  ...ReduxAvenueDispatchToPropsType,
|};

type AvenueStateType = {|
  avenue: ?NETGEM_API_V8_AVENUE,
  avenueHeaderUri: string | null,
  avenueHeaderUrl: string | null,
  avenueImageUri: string | null,
  avenueImageUrl: string | null,
  avenueItemCount: number,
  emptyAvenueImageUrl: string | null,
  isGridAvenue: boolean,
  isLoadingSearchResults: boolean,
  isMyVideosEmptyVisible: boolean,
  isNoResultVisible: boolean,
  isSectionRenderingLimited: boolean,
  newSearchString: string | null,
  sectionElements: Array<React.Element<any>>,
  sections: Array<NETGEM_API_V8_SECTION>,
|};

const InitialState: AvenueStateType = Object.freeze({
  avenue: null,
  avenueHeaderUri: null,
  avenueHeaderUrl: null,
  avenueImageUri: null,
  avenueImageUrl: null,
  avenueItemCount: 0,
  emptyAvenueImageUrl: null,
  isGridAvenue: false,
  isLoadingSearchResults: true,
  isMyVideosEmptyVisible: false,
  isNoResultVisible: false,
  isSectionRenderingLimited: true,
  newSearchString: null,
  sectionElements: [],
  sections: [],
});

class AvenueView extends React.PureComponent<CompleteAvenuePropType, AvenueStateType> {
  abortController: AbortController;

  avenueVisibleSectionCountdown: number;

  carouselSectionIndex: number;

  firstSectionsLoadTimer: TimeoutID | null;

  isLoadingEmptyAvenueImageUrl: boolean;

  loadedSectionCount: number;

  searchBox: HTMLElement | null;

  sectionMapRef: Map<number, React.ElementRef<any> | null>;

  // Section Id -> item count
  sectionItemCountMap: Map<string, number>;

  static defaultProps: DefaultProps = {
    hasBackButton: false,
    loadedCallback: undefined,
  };

  constructor(props: CompleteAvenuePropType) {
    super(props);

    this.abortController = new AbortController();
    this.avenueVisibleSectionCountdown = VISIBLE_SECTION_COUNT;
    this.carouselSectionIndex = -1;
    this.firstSectionsLoadTimer = null;
    this.isLoadingEmptyAvenueImageUrl = false;
    this.loadedSectionCount = 0;
    this.searchBox = null;
    this.sectionMapRef = new Map();
    this.sectionItemCountMap = new Map();

    const { searchString } = props;
    const { avenueHeaderUri, avenueImageUri } = this.getAvenueImageAndHeaderUri(props);

    this.state = {
      ...InitialState,
      // Avenue image and header URIs are initialized here to prevent layout shift
      avenueHeaderUri,
      avenueImageUri,
      newSearchString: searchString,
    };
  }

  componentDidMount() {
    const { gridSectionId, hasBackButton, type } = this.props;
    const { searchBox } = this;

    if (hasBackButton && !gridSectionId) {
      HotKeys.register('escape', this.handleGoBackToFocusedAvenueHotKey, { name: 'Avenue.goBackToFocusedAvenue' });
    }

    this.loadAvenue();

    if (type === AvenueType.Search && !gridSectionId && searchBox) {
      HotKeys.register('s', this.handleFocusSearchBoxHotKey, { disableOthers: true, name: 'Avenue.focusSearch' });
      searchBox.focus();
    }
  }

  componentDidUpdate(prevProps: CompleteAvenuePropType, prevState: AvenueStateType) {
    const { avenueList, gridSectionId, hasBackButton, hub, hubItem, index, searchString, type } = this.props;
    const { avenue, isSectionRenderingLimited, sections } = this.state;
    const {
      avenueList: prevAvenueList,
      gridSectionId: prevGridSectionId,
      hasBackButton: prevHasBackButton,
      hubItem: prevHubItem,
      index: prevIndex,
      searchString: prevSearchString,
      type: prevType,
    } = prevProps;
    const { avenue: prevAvenue, isSectionRenderingLimited: prevIsSectionRenderingLimited, sections: prevSections } = prevState;

    if (!hub && index === prevIndex && avenueList && prevAvenueList && areAvenuesDifferent(avenueList[index], prevAvenueList[index])) {
      // Hub has changed
      this.loadAvenue(false);
      return;
    }

    if (prevIndex !== index || hubItem !== prevHubItem) {
      this.loadAvenue();
    } else if (prevIsSectionRenderingLimited !== isSectionRenderingLimited && !isSectionRenderingLimited) {
      this.renderRemainingSectionElements();
    } else if (prevAvenue !== avenue || prevGridSectionId !== gridSectionId || prevSections !== sections || prevSearchString !== searchString) {
      this.renderSectionElements(prevSearchString !== searchString);
    }

    if (hasBackButton !== prevHasBackButton || gridSectionId !== prevGridSectionId) {
      if (hasBackButton && !gridSectionId) {
        HotKeys.register('escape', this.handleGoBackToFocusedAvenueHotKey, { name: 'Avenue.goBackToFocusedAvenue' });
      } else {
        HotKeys.unregister('escape', this.handleGoBackToFocusedAvenueHotKey);
      }
    }

    if (type !== prevType || gridSectionId !== prevGridSectionId) {
      if (type !== AvenueType.Search || gridSectionId) {
        HotKeys.unregister('s', this.handleFocusSearchBoxHotKey);
      } else {
        HotKeys.register('s', this.handleFocusSearchBoxHotKey, { disableOthers: true, name: 'Avenue.focusSearch' });
      }
    }
  }

  componentWillUnmount() {
    const { abortController } = this;

    abortController.abort('Component Avenue will unmount');

    this.resetFirstSectionsLoadTimer();

    HotKeys.unregister('s', this.handleFocusSearchBoxHotKey);
    HotKeys.unregister('escape', this.handleGoBackToFocusedAvenueHotKey);
  }

  resetFirstSectionsLoadTimer = () => {
    const { firstSectionsLoadTimer } = this;

    if (firstSectionsLoadTimer) {
      clearTimeout(firstSectionsLoadTimer);
      this.firstSectionsLoadTimer = null;
    }
  };

  handleFocusSearchBoxHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { searchBox } = this;

    if (searchBox) {
      event.preventDefault();
      event.stopPropagation();
      searchBox.focus();
    }
  };

  handleGoBackToFocusedAvenueHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { localGoBackToFocusedAvenue } = this.props;

    event.preventDefault();
    event.stopPropagation();

    localGoBackToFocusedAvenue();
  };

  getAvenue = (props?: CompleteAvenuePropType): NETGEM_API_V8_AVENUE | null => {
    const { avenueList, hub, index } = props ?? this.props;
    const localHub = hub ?? avenueList;

    if (!localHub || localHub.length === 0) {
      return null;
    }

    return localHub[hub ? 0 : index];
  };

  getAvenueImageAndHeaderUri = (props?: CompleteAvenuePropType): { avenueHeaderUri: string | null, avenueImageUri: string | null } => {
    const { hubItem } = props ?? this.props;
    const avenue = this.getAvenue(props);

    if (!avenue) {
      return {
        avenueHeaderUri: null,
        avenueImageUri: null,
      };
    }

    const { widgetConfig } = avenue;
    const avenueHeaderUri = widgetConfig?.headerUri;
    let avenueImageUri = widgetConfig?.imageUri;

    if (!avenueHeaderUri && !avenueImageUri && hubItem) {
      ({ id: avenueImageUri } = hubItem);
    }

    return {
      avenueHeaderUri: avenueHeaderUri ?? null,
      avenueImageUri: avenueImageUri ?? null,
    };
  };

  // Default is true
  loadAvenue = (isSectionRenderingLimited: boolean = true) => {
    const { localUpdateGridSectionId } = this.props;

    const avenue = this.getAvenue();

    if (!avenue) {
      return;
    }

    const { sections = [] } = avenue;

    // In case of avenue with layout 'grid', only the first section (if there are more) is kept
    const isGridAvenue = avenue.widgetConfig?.layout === WidgetLayoutType.Grid;
    const localSections = isGridAvenue ? sections.slice(0, 1) : [...sections];
    if (isGridAvenue) {
      localUpdateGridSectionId(localSections[0].id);
    }

    this.avenueVisibleSectionCountdown = isSectionRenderingLimited ? Math.min(VISIBLE_SECTION_COUNT, localSections.length) : localSections.length;
    this.loadedSectionCount = 0;
    this.sectionItemCountMap.clear();

    const { avenueHeaderUri, avenueImageUri } = this.getAvenueImageAndHeaderUri();

    this.setState({
      avenue,
      avenueHeaderUri,
      avenueImageUri,
      avenueItemCount: 0,
      emptyAvenueImageUrl: null,
      isGridAvenue,
      isMyVideosEmptyVisible: false,
      isNoResultVisible: false,
      isSectionRenderingLimited,
      sections: localSections,
    });
  };

  loadAvenueHeaderOrImage = () => {
    const { avenueHeaderUri } = this.state;

    if (avenueHeaderUri) {
      this.setState({ avenueImageUrl: null });
      this.loadAvenueHeader();
    } else {
      this.loadAvenueImage();
    }
  };

  loadAvenueHeader = () => {
    const { localGetImageUrl } = this.props;
    const { avenueHeaderUri } = this.state;
    const {
      abortController: { signal },
    } = this;

    if (!avenueHeaderUri) {
      this.setState({ avenueHeaderUrl: null });
      return;
    }

    localGetImageUrl(avenueHeaderUri, AVENUE_HEADER_WIDTH, AVENUE_HEADER_HEIGHT, undefined, signal)
      .then((avenueHeaderUrl: string) => {
        signal.throwIfAborted();

        this.setState({ avenueHeaderUrl });
      })
      .catch((error) => ignoreIfAborted(signal, error, () => this.setState({ avenueHeaderUrl: null })));
  };

  loadAvenueImage = () => {
    const { localGetImageUrl } = this.props;
    const { avenueImageUri } = this.state;
    const {
      abortController: { signal },
    } = this;

    this.setState({ avenueHeaderUrl: null });

    if (!avenueImageUri) {
      this.setState({ avenueImageUrl: null });
      return;
    }

    localGetImageUrl(avenueImageUri, AVENUE_IMAGE_WIDTH, AVENUE_IMAGE_HEIGHT, Theme.Dark, signal)
      .then((avenueImageUrl: string) => {
        signal.throwIfAborted();

        this.setState({ avenueImageUrl });
      })
      .catch((error) => ignoreIfAborted(signal, error, () => this.setState({ avenueImageUrl: null })));
  };

  handleBackOnClick = () => {
    const { localGoBackToFocusedAvenue } = this.props;

    localGoBackToFocusedAvenue();
  };

  handleOnSearchChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ newSearchString: event.currentTarget.value });
  };

  handleSearchStringOnClick = (newSearchString: string) => {
    this.setState({ newSearchString }, this.launchSearch);
  };

  handleOnSearchKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { keyCode } = event;

    if (keyCode === KEY.ENTER) {
      this.launchSearch();
    }
  };

  handleSearchOnClick = () => {
    this.launchSearch();
  };

  launchSearch = () => {
    const { searchString, localUpdateSearchString } = this.props;
    const { newSearchString } = this.state;

    if (newSearchString) {
      const trimmedSearchString = newSearchString.trim();
      if (trimmedSearchString.length >= SEARCH_STRING_MIN_LENGTH && trimmedSearchString !== searchString) {
        saveSearchString(trimmedSearchString);
        localUpdateSearchString(trimmedSearchString);
      }
    }
  };

  handleOnUIHiddenCallback = () => {
    this.toggleSectionNextToCarousel(true);
  };

  handleOnUIShownCallback = () => {
    this.toggleSectionNextToCarousel(false);
  };

  toggleSectionNextToCarousel = (isHidden: boolean) => {
    const { carouselSectionIndex, sectionMapRef } = this;

    if (carouselSectionIndex === -1) {
      return;
    }

    const section = sectionMapRef.get(carouselSectionIndex + 1);
    if (section) {
      if (isHidden) {
        section.hide();
      } else {
        section.show();
      }
    }
  };

  dataLoadedCallback = (sectionId: string, sectionIndex: number, avenueIndex: number, sectionItemCount: number) => {
    const { index: currentAvenueIndex } = this.props;
    const { sectionItemCountMap } = this;

    if (avenueIndex !== currentAvenueIndex) {
      // Old avenue: ignore
      return;
    }

    let diff = sectionItemCount;

    if (sectionItemCountMap.has(sectionId)) {
      // Section already loaded: its items were updated
      diff -= sectionItemCountMap.get(sectionId) || 0;
    } else {
      // Section freshly loaded
      this.increaseLoadedSectionCount(sectionIndex);
    }

    sectionItemCountMap.set(sectionId, sectionItemCount);

    this.setState(
      produce((draft) => {
        draft.avenueItemCount += diff;
      }),
      this.checkForEmptyAvenue,
    );
  };

  increaseLoadedSectionCount = (sectionIndex: number) => {
    this.loadedSectionCount += 1;

    if (sectionIndex < VISIBLE_SECTION_COUNT) {
      this.avenueVisibleSectionCountdown -= 1;

      if (this.avenueVisibleSectionCountdown === 0) {
        this.resetFirstSectionsLoadTimer();
        this.setState({ isSectionRenderingLimited: false });
      }
    }
  };

  checkForEmptyAvenue = () => {
    const { loadedCallback, type } = this.props;
    const { avenueItemCount, sections } = this.state;
    const { loadedSectionCount } = this;

    if (loadedSectionCount < sections.length) {
      return;
    }

    // All sections loaded
    if (type === AvenueType.Search) {
      // All sections have been loaded: check if the search came back with items
      this.setState({
        isLoadingSearchResults: false,
        isNoResultVisible: avenueItemCount === 0,
      });
    } else if (type === AvenueType.Myvideos) {
      // All sections have been loaded: check if MY VIDEOS is empty
      this.setState({
        isMyVideosEmptyVisible: avenueItemCount === 0,
      });
    }

    if (loadedCallback) {
      loadedCallback();
    }
  };

  renderSectionElement = (avenueId: string, sectionIndex: number): React.Element<any> => {
    const { hubItem, index: avenueIndex, isInExploreModal, searchString } = this.props;
    const { avenueImageUri, isGridAvenue, sections } = this.state;

    const { [sectionIndex]: section } = sections;
    const { id, kind, widgetConfig } = section;

    const layout = widgetConfig?.layout ?? WidgetLayoutType.List;
    const onItemClick = widgetConfig?.onItemClick;

    let displayType: SectionType = kind;
    if (layout === WidgetLayoutType.Grid) {
      displayType = SectionType.Grid;
    } else if (avenueId === AVENUE_ID_EXPLORE) {
      displayType = SectionType.ChannelGroup;
    }

    if (this.carouselSectionIndex > -1) {
      // Ensure no more than 1 carousel is displayed
      displayType = SectionType.Regular;
    }

    // Section key is made of section Id plus avenue index to avoid the issue where same section Id exists on multiple avenues
    const key = `${id}_${avenueIndex}`;

    switch (displayType) {
      case SectionType.HeroPanel:
      case SectionType.Carousel:
        this.carouselSectionIndex = sectionIndex;
        return (
          <SectionCarousel
            avenueImageUri={avenueImageUri}
            avenueIndex={avenueIndex}
            dataLoadedCallback={this.dataLoadedCallback}
            hubItem={hubItem}
            key={key}
            onItemClick={onItemClick}
            onUIHiddenCallback={this.handleOnUIHiddenCallback}
            onUIShownCallback={this.handleOnUIShownCallback}
            ref={(instance) => {
              this.sectionMapRef.set(sectionIndex, instance);
            }}
            section={section}
            sectionIndex={sectionIndex}
          />
        );

      case SectionType.ChannelGroup:
        return (
          <SectionChannelGroup
            isInExploreModal={isInExploreModal}
            key={key}
            onItemClick={onItemClick}
            ref={(instance) => {
              this.sectionMapRef.set(sectionIndex, instance);
            }}
            section={section}
          />
        );

      case SectionType.Grid:
        return (
          <SectionChannelGrid
            isInExploreModal={isInExploreModal}
            key={key}
            onItemClick={onItemClick}
            ref={(instance) => {
              this.sectionMapRef.set(sectionIndex, instance);
            }}
            section={section}
          />
        );

      default:
        return (
          <Section
            avenueIndex={avenueIndex}
            dataLoadedCallback={this.dataLoadedCallback}
            hubItem={hubItem}
            isGridAvenue={isGridAvenue}
            isInExploreModal={isInExploreModal}
            key={key}
            onItemClick={onItemClick}
            ref={(instance) => {
              this.sectionMapRef.set(sectionIndex, instance);
            }}
            searchString={searchString}
            section={section}
            sectionIndex={sectionIndex}
          />
        );
    }
  };

  renderSectionElements = (isSearching: boolean) => {
    const { hubItem } = this.props;
    const { avenue, isSectionRenderingLimited, sections } = this.state;

    const sectionElements = [];

    if (!avenue) {
      this.setState({ sectionElements });
      return;
    }

    if (isSearching) {
      this.setState({ isLoadingSearchResults: true });
    }

    const { id: avenueId } = avenue;
    this.sectionMapRef = new Map();
    this.carouselSectionIndex = -1;

    const renderedSectionCount = isSectionRenderingLimited ? Math.min(VISIBLE_SECTION_COUNT, sections.length) : sections.length;

    for (let i = 0; i < renderedSectionCount; i++) {
      const sectionView = this.renderSectionElement(avenueId, i);

      if (sectionView) {
        sectionElements.push(sectionView);
      }
    }

    if (this.carouselSectionIndex === -1) {
      // No carousel
      this.loadAvenueHeaderOrImage();
    } else if (!hubItem) {
      // Carousel without hub image
      this.loadAvenueImage();
    }

    this.setState({ sectionElements });

    if (isSectionRenderingLimited) {
      this.resetFirstSectionsLoadTimer();
      this.firstSectionsLoadTimer = setTimeout(this.forceLoadingRemainingSections, FIRST_SECTIONS_LOAD_TIMEOUT);
    }
  };

  forceLoadingRemainingSections = () => {
    this.setState({ isSectionRenderingLimited: false });
  };

  renderRemainingSectionElements = () => {
    const { avenue, sectionElements: currentElements, sections } = this.state;

    if (!avenue) {
      this.setState({ sectionElements: [] });
      return;
    }

    const { id: avenueId } = avenue;
    const sectionElements = [...currentElements];

    for (let i = currentElements.length; i < sections.length; i++) {
      const sectionView = this.renderSectionElement(avenueId, i);

      if (sectionView) {
        sectionElements.push(sectionView);
      }
    }

    this.setState({ sectionElements });
  };

  renderNoResult = (): React.Element<any> | null => {
    const { isNoResultVisible } = this.state;
    const { type } = this.props;

    if (type !== AvenueType.Search || !isNoResultVisible) {
      return null;
    }

    return (
      <div className='noResult'>
        <PictoSadFace hasHoverEffect={false} />
        <div className='noResultText'>{Localizer.localize('search.no_result')}</div>
      </div>
    );
  };

  renderMyVideosEmptyContent = (): React.Element<any> => {
    const { features } = this.props;

    if (features[FEATURE_TV] && !features[FEATURE_NPVR] && !features[FEATURE_VOD]) {
      // TV only
      return <div className='subtitle'>{Localizer.localize('my_videos.empty.subtitle.tv_only')}</div>;
    } else if (features[FEATURE_VOD] && !features[FEATURE_NPVR] && !features[FEATURE_TV]) {
      // VOD only
      return <div className='subtitle'>{Localizer.localize('my_videos.empty.subtitle.vod_only')}</div>;
    }

    return (
      <>
        <div className='subtitle'>{Localizer.localize('my_videos.empty.subtitle.multiple_features')}</div>
        <ul>
          {features[FEATURE_VOD] ? <li>{Localizer.localize('my_videos.empty.vod')}</li> : null}
          {features[FEATURE_NPVR] ? <li>{Localizer.localize('my_videos.empty.recordings')}</li> : null}
          {features[FEATURE_TV] ? <li>{Localizer.localize('my_videos.empty.tv')}</li> : null}
        </ul>
      </>
    );
  };

  loadEmptyAvenueImageUrl = (imageUri: ?string, imageUrl: ?NETGEM_API_V8_WIDGET_PANEL_IMAGE_URL_TYPE): void => {
    const { localGetImageUrl, localGetImageUrlFromDefinition } = this.props;
    const { isLoadingEmptyAvenueImageUrl } = this;

    if (isLoadingEmptyAvenueImageUrl || (!imageUri && !imageUrl)) {
      return;
    }

    this.isLoadingEmptyAvenueImageUrl = true;

    const getImagePromise = imageUri
      ? // New way of retrieving image, via URI
        localGetImageUrl(imageUri, EMPTY_AVENUE_IMAGE_WIDTH, EMPTY_AVENUE_IMAGE_HEIGHT, Theme.Dark)
      : // Legacy way, via URL definition
        localGetImageUrlFromDefinition({
          authent: false,
          params: imageUrl?.params ?? [],
          uri: imageUrl?.uri ?? '',
        });

    getImagePromise
      .then((emptyAvenueImageUrl: string) => this.setState({ emptyAvenueImageUrl }))
      .catch(() => this.setState({ emptyAvenueImageUrl: '' }))
      .finally(() => {
        this.isLoadingEmptyAvenueImageUrl = false;
      });
  };

  renderMyVideosEmpty = (): React.Element<any> | null => {
    const { type } = this.props;
    const { avenue, emptyAvenueImageUrl, isMyVideosEmptyVisible } = this.state;

    if (!avenue || !isMyVideosEmptyVisible) {
      // Avenue not empty
      return null;
    }

    const onEmptyAvenue = avenue.widgetConfig?.onEmptyAvenue;

    if (!onEmptyAvenue && type !== AvenueType.Myvideos) {
      return null;
    }

    if (onEmptyAvenue) {
      // Empty avenue is described by PTF
      const {
        widgetConfig: { imageUri, imageUrl, instructions, title },
      } = onEmptyAvenue;
      const localizedTitle = title ? getLocalizedString(title, Localizer.language) : Localizer.localize('my_videos.empty.title');
      const localizedInstructions = instructions ? getLocalizedString(instructions, Localizer.language) : '';

      if ((imageUri || imageUrl) && emptyAvenueImageUrl === null) {
        this.loadEmptyAvenueImageUrl(imageUri, imageUrl);
      }

      return (
        <div className='emptyMyVideos'>
          <div className='title'>{localizedTitle}</div>
          <div className='subtitle'>{localizedInstructions}</div>
          <div className='image' style={{ backgroundImage: `url(${emptyAvenueImageUrl ?? ''})` }} />
        </div>
      );
    }

    // Empty avenue special case: MY VIDEOS
    return (
      <div className='emptyMyVideos'>
        <div className='title'>{Localizer.localize('my_videos.empty.title')}</div>
        <div className='iconAndText'>
          <PictoNoContent hasHoverEffect={false} />
          <div>{this.renderMyVideosEmptyContent()}</div>
        </div>
      </div>
    );
  };

  renderSearchElement = (): React.Element<any> | null => {
    const { gridSectionId, type } = this.props;
    const { isLoadingSearchResults, newSearchString } = this.state;

    if (type !== AvenueType.Search || gridSectionId) {
      return null;
    }

    return (
      <div className={clsx('search', isLoadingSearchResults && 'loading')}>
        <div className='searchBox'>
          <PictoMagnifier onClick={this.handleSearchOnClick} />
          <input
            onChange={this.handleOnSearchChange}
            onKeyDown={this.handleOnSearchKeyDown}
            placeholder={Localizer.localize('search.placeholder')}
            ref={(instance) => {
              this.searchBox = instance;
            }}
            type='text'
            value={newSearchString}
          />
        </div>
        <SearchHistory onSearchStringClick={this.handleSearchStringOnClick} />
      </div>
    );
  };

  render(): React.Node {
    const { gridSectionId, hasBackButton, type } = this.props;
    const { avenue, avenueHeaderUrl, avenueImageUrl, sectionElements } = this.state;

    const isGridAvenue = avenue?.widgetConfig?.layout === WidgetLayoutType.Grid;
    const backButton = hasBackButton && (!gridSectionId || isGridAvenue) ? <ButtonBack className='backBar' hasText={avenueHeaderUrl === null} onClick={this.handleBackOnClick} /> : null;

    const avenueHeader = avenueHeaderUrl ? <div className='avenueHeader' style={{ backgroundImage: `url(${avenueHeaderUrl ?? ''})` }} /> : null;

    const avenueImage = avenueImageUrl && !avenueHeader ? <div className='avenueImage' style={{ backgroundImage: `url(${avenueImageUrl ?? ''})` }} /> : null;

    return (
      <div className={clsx('avenueView', (type: string).toLowerCase(), isGridAvenue && 'gridAvenue')}>
        {avenueHeader}
        {backButton}
        {avenueImage}
        <div className='slider'>
          {this.renderSearchElement()}
          {sectionElements}
          {this.renderMyVideosEmpty()}
          {this.renderNoResult()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxAvenueReducerStateType => {
  return {
    avenueList: state.ui.avenueList,
    features: state.appConfiguration.features,
    gridSectionId: state.ui.gridSectionId,
    searchString: state.ui.focusedAvenue?.searchString ?? null,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxAvenueDispatchToPropsType => {
  return {
    localGetImageUrl: (assetId: string, width: number, height: number, theme?: ThemeType, signal?: AbortSignal): Promise<any> =>
      dispatch(
        getImageUrl(
          {
            assetId,
            height,
            theme,
            width,
          },
          signal,
        ),
      ),

    localGetImageUrlFromDefinition: (urlDefinition: NETGEM_API_V8_URL_DEFINITION): Promise<any> => dispatch(getImageUrlFromDefinition(urlDefinition)),

    localGoBackToFocusedAvenue: () => dispatch(goBackToFocusedAvenue()),

    localUpdateGridSectionId: (gridSectionId: string | null): void => dispatch(updateGridSectionId(gridSectionId)),

    localUpdateSearchString: (searchString: string) => dispatch(updateSearchString(searchString)),
  };
};

const Avenue: React.ComponentType<AvenuePropType> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AvenueView);

export default Avenue;
