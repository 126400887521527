/* @flow */

import './CardModalAvenue.css';
import * as React from 'react';
import { GroupItemsBy, type NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import type { CARD_DATA_MODAL_TYPE } from './CardModalConstsAndTypes';
import type { Dispatch } from '../../../redux/types/types';
import { type NETGEM_API_V8_AVENUE } from '../../../libs/netgemLibrary/v8/types/Avenue';
import { type NETGEM_API_V8_FEED_ITEM } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import RegularSection from '../../avenue/section/Section';
import SeriesSection from '../../avenue/sectionSeries/Section';
import type { Undefined } from '@ntg/utils/dist/types';
import { connect } from 'react-redux';
import { filterSections } from '../../../helpers/ui/metadata/Exclusion';
import { ignoreIfAborted } from '../../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import { isVodItem } from '../../../helpers/ui/item/metadata';
import sendV8CustomStrategyRequest from '../../../redux/netgemApi/actions/v8/customStrategyRequest';

type ReduxAvenueDispatchToPropsType = {|
  +localSendV8CustomStrategyRequest: (urlDefinition: NETGEM_API_V8_URL_DEFINITION, item: NETGEM_API_V8_FEED_ITEM, signal?: AbortSignal) => Promise<any>,
|};

type AvenuePropType = {|
  +cardData: CARD_DATA_MODAL_TYPE,
  +displayedSectionCount: ?number,
|};

type CompleteAvenuePropType = {|
  ...AvenuePropType,
  ...ReduxAvenueDispatchToPropsType,
|};

type AvenueStateType = {|
  avenue: NETGEM_API_V8_AVENUE | null,
|};

const InitialState = Object.freeze({
  avenue: null,
});

class CardModalAvenue extends React.PureComponent<CompleteAvenuePropType, AvenueStateType> {
  abortController: AbortController;

  constructor(props: CompleteAvenuePropType) {
    super(props);

    this.abortController = new AbortController();

    this.state = { ...InitialState };
  }

  componentDidMount() {
    this.loadAvenue();
  }

  componentDidUpdate(prevProps: CompleteAvenuePropType) {
    const {
      cardData: { item },
    } = this.props;
    const {
      cardData: { item: prevItem },
    } = prevProps;

    if (prevItem !== item) {
      this.loadAvenue();
    }
  }

  componentWillUnmount() {
    const { abortController } = this;

    abortController.abort('Component CardModalAvenue will unmount');
  }

  loadAvenueFromUrlDefinition = (urlDefinition: NETGEM_API_V8_URL_DEFINITION) => {
    const {
      cardData: { item },
      localSendV8CustomStrategyRequest,
    } = this.props;
    const {
      abortController: { signal },
    } = this;

    localSendV8CustomStrategyRequest(urlDefinition, item, signal)
      .then((response) => {
        signal.throwIfAborted();

        this.setState({ avenue: filterSections(response) });
      })
      .catch((error) => ignoreIfAborted(signal, error, this.destroyAvenue));
  };

  loadAvenue = () => {
    const {
      cardData: { urlDefinition },
    } = this.props;

    if (urlDefinition) {
      this.loadAvenueFromUrlDefinition(urlDefinition);
    }
  };

  dataLoadedCallback = () => {
    // Nothing to do at the moment
  };

  createAvenue = (displayedSectionCount: ?number): Array<React.Element<any>> | null => {
    const { cardData } = this.props;
    const { avenue } = this.state;

    if (!avenue) {
      return null;
    }

    const {
      sections,
      sections: { length },
    } = avenue;
    const sectionCount = typeof displayedSectionCount === 'number' ? Math.min(displayedSectionCount, length) : length;
    const sectionContainer = [];

    for (let i: number = 0; i < sectionCount; ++i) {
      const { [i]: section } = sections;
      const { id, widgetConfig } = section;
      const {
        item,
        item: {
          id: itemId,
          seriesId,
          selectedLocation: { id: selectedLocationId },
        },
        seriesMetadata,
      } = cardData;
      let groupItemsBy: ?GroupItemsBy = null;
      let onItemClick: Undefined<NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE> = undefined;

      if (widgetConfig) {
        ({ groupItemsBy, onItemClick } = widgetConfig);
      }

      if (seriesId && groupItemsBy === GroupItemsBy.Program) {
        sectionContainer.push(
          <SeriesSection
            cardData={cardData}
            focusedLocationId={selectedLocationId}
            isVod={isVodItem(item)}
            key={`section_${id}`}
            onItemClick={onItemClick}
            section={section}
            seriesId={seriesId}
            seriesMetadata={seriesMetadata}
          />,
        );
      } else {
        sectionContainer.push(
          <RegularSection
            avenueIndex={0}
            cardData={cardData}
            dataLoadedCallback={this.dataLoadedCallback}
            key={`section_${id}_${itemId}`}
            onItemClick={onItemClick}
            section={section}
            sectionIndex={i}
          />,
        );
      }
    }

    return sectionContainer;
  };

  destroyAvenue = () => {
    this.setState({ ...InitialState });
  };

  render(): React.Node {
    const { displayedSectionCount } = this.props;

    if (typeof displayedSectionCount === 'number' && displayedSectionCount === 0) {
      return null;
    }

    return <div className='cardAvenueView'>{this.createAvenue(displayedSectionCount)}</div>;
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxAvenueDispatchToPropsType => {
  return {
    localSendV8CustomStrategyRequest: (urlDefinition: NETGEM_API_V8_URL_DEFINITION, item: NETGEM_API_V8_FEED_ITEM, signal?: AbortSignal): Promise<any> =>
      dispatch(sendV8CustomStrategyRequest(urlDefinition, item, signal)),
  };
};

const CardAvenueView: React.ComponentType<AvenuePropType> = connect(null, mapDispatchToProps, null, { forwardRef: true })(CardModalAvenue);

export default CardAvenueView;
