/* @flow */

import {
  NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_CARD,
  type NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE,
  type NETGEM_API_V8_WIDGET_TILE_CONFIG_TYPE,
  type TILE_CONFIG_DESCRIPTION,
  type TILE_CONFIG_TYPE,
  TileConfig,
  TileConfigTileType,
  TileContentImageKind,
  TileImageLayout,
  TileOnFocus,
} from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import { ItemType } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_SECTION } from '../../../libs/netgemLibrary/v8/types/Section';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import { isUndefinedOrNull } from '@ntg/utils/dist/types';

const DefaultTileConfig = {
  caption: null,
  channelLogo: true,
  contentImage: TileContentImageKind.Program,
  hoverContent: null,
  imageLayout: TileImageLayout.Stretched,
  onFocus: TileOnFocus.Details,
  type: TileConfigTileType.Gemtv,
};

// Temporary function until tileConfig is used everywhere and widgetConfig.tileType completely deprecated
const completeIfNeeded: (tileConfig: NETGEM_API_V8_WIDGET_TILE_CONFIG_TYPE, sectionId: string) => TILE_CONFIG_TYPE =
  // eslint-disable-next-line max-lines-per-function
  (tileConfig, sectionId) => {
    const { caption, channelLogo, contentImage, contentImageVariant, hoverContent, imageLayout, onFocus, type } = tileConfig;
    const completeTileConfig: TILE_CONFIG_TYPE = {
      caption,
      channelLogo,
      contentImage,
      contentImageVariant,
      hoverContent,
      imageLayout,
      onFocus,
      type: type ?? TileConfigTileType.Gemtv,
    };

    if (!contentImage) {
      completeTileConfig.contentImage = TileContentImageKind.Program;
    }

    if (!imageLayout) {
      completeTileConfig.imageLayout = TileImageLayout.Stretched;
    }

    if (!onFocus) {
      completeTileConfig.onFocus = TileOnFocus.Details;
    }

    if (!type) {
      completeTileConfig.type = TileConfigTileType.Gemtv;
    }

    if ((type === TileConfigTileType.Portrait || type === TileConfigTileType.LandscapeVod) && isUndefinedOrNull(hoverContent)) {
      completeTileConfig.channelLogo = false;
      completeTileConfig.contentImage = TileContentImageKind.Series;
      completeTileConfig.hoverContent = {
        program: [TileConfig.ProgramTitle, TileConfig.ProgramSynopsis, TileConfig.Duration, TileConfig.Year, TileConfig.Genre, TileConfig.Language, TileConfig.ParentalGuidance],
        series: [TileConfig.SeriesTitle, TileConfig.SeriesSynopsis, TileConfig.Duration, TileConfig.Year, TileConfig.Genre, TileConfig.ParentalGuidance],
      };
    }
    if (completeTileConfig.type === TileConfigTileType.Gemtv && isUndefinedOrNull(hoverContent)) {
      completeTileConfig.contentImage = TileContentImageKind.Series;
      completeTileConfig.hoverContent = {
        program: [TileConfig.ProgramTitle, TileConfig.ProgramSynopsis, TileConfig.Duration, TileConfig.Year],
        series: [TileConfig.SeriesTitle, TileConfig.ProgramTitle, TileConfig.ProgramSynopsis, TileConfig.Duration, TileConfig.Year],
      };
      completeTileConfig.caption = {
        program: [TileConfig.ProgramTitle, TileConfig.Duration, TileConfig.Year],
        series: [TileConfig.SeriesTitle, TileConfig.ProgramTitle, TileConfig.Duration, TileConfig.Year],
      };
    }
    if (type === TileConfigTileType.Landscape && isUndefinedOrNull(hoverContent)) {
      completeTileConfig.contentImage = TileContentImageKind.Series;
      completeTileConfig.hoverContent = {
        program: [TileConfig.ProgramTitle, TileConfig.ProgramSynopsis, TileConfig.Duration, TileConfig.Year, TileConfig.Genre, TileConfig.Language, TileConfig.ParentalGuidance],
        series: [TileConfig.SeriesTitle, TileConfig.SeriesSynopsis, TileConfig.Duration, TileConfig.Year, TileConfig.Genre, TileConfig.ParentalGuidance],
      };
    }

    if (sectionId === 'jeunesse_vod_universe_section_id' || sectionId === 'universcine_vod_thematic_universe_section_id') {
      completeTileConfig.onFocus = TileOnFocus.Selection;
      completeTileConfig.contentImage = TileContentImageKind.Other;
    }

    if (type && (type: string).endsWith('.fill')) {
      completeTileConfig.imageLayout = TileImageLayout.Cover;
    }

    if (
      [
        TileConfigTileType.Channel,
        TileConfigTileType.ChannelGroup,
        TileConfigTileType.ChannelGroupBig,
        TileConfigTileType.ChannelGroupFill,
        TileConfigTileType.ChannelGroupBigFill,
        TileConfigTileType.Deeplink3x1,
        TileConfigTileType.Deeplink5x1,
      ].includes(type)
    ) {
      completeTileConfig.onFocus = TileOnFocus.Selection;
    }

    return completeTileConfig;
  };

const getTileConfig: (section: NETGEM_API_V8_SECTION) => TILE_CONFIG_TYPE = (section) => {
  const { id, widgetConfig } = section;

  if (!widgetConfig?.tileConfig) {
    return completeIfNeeded(DefaultTileConfig, id);
  }

  const { tileConfig } = widgetConfig;
  return completeIfNeeded(tileConfig, id);
};

const getTileTypeClass: (type: ?TileConfigTileType) => string = (type) =>
  type
    ? (type: string)
        .replace(/deeplink\.(\d+x\d+)/gu, 'deeplink$1')
        .replace(/\./gu, ' ')
        .replace('fill', 'cover')
    : '';

const getTileCaption: (tileConfig: TILE_CONFIG_TYPE, itemType: ?ItemType) => Set<TileConfig> = (tileConfig, itemType) => {
  const { caption } = tileConfig;
  return getTileTextDescription(caption, itemType);
};

const getTileHoverContent: (tileConfig: TILE_CONFIG_TYPE, itemType: ?ItemType) => Set<TileConfig> = (tileConfig, itemType) => {
  const { hoverContent } = tileConfig;
  return getTileTextDescription(hoverContent, itemType);
};

const getTileTextDescription: (textDescription: ?TILE_CONFIG_DESCRIPTION, itemType: ?ItemType) => Set<TileConfig> = (textDescription, itemType) => {
  if (!textDescription || !itemType) {
    // No description
    return new Set<TileConfig>();
  }

  let descriptionByType: ?Array<TileConfig> = null;

  if (itemType === ItemType.Program) {
    ({ program: descriptionByType } = textDescription);
  } else if (itemType === ItemType.Series) {
    ({ series: descriptionByType } = textDescription);
  }

  if (!descriptionByType) {
    // No description for this item type
    return new Set<TileConfig>();
  }

  return new Set(descriptionByType);
};

const getSeriesCardUrlDefinition: (onItemClick?: NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE, defaultOnItemClick: ?NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE) => NETGEM_API_V8_URL_DEFINITION | null = (
  onItemClick,
  defaultOnItemClick,
) => {
  const localOnItemClick = onItemClick ?? defaultOnItemClick;
  if (!localOnItemClick || !Array.isArray(localOnItemClick.params)) {
    return null;
  }

  const seriesCardParam = localOnItemClick.params.find((param) => param.prefix === `${(ItemType.Series: string)}://` && param.action.action === NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_CARD);

  if (!seriesCardParam) {
    return null;
  }

  const {
    action: { params },
  } = seriesCardParam;
  return ((params: any): NETGEM_API_V8_URL_DEFINITION);
};

export { getSeriesCardUrlDefinition, getTileCaption, getTileConfig, getTileHoverContent, getTileTypeClass };
