/* @flow */

import type { AllTracks, AudioTrack, TextTrack, VideoTrack } from './shakaTypes';

const cloneAudioTracks: (tracks: Array<AudioTrack>) => Array<AudioTrack> = (tracks) =>
  tracks.map((track) => {
    return {
      ...track,
      roles: [...track.roles],
    };
  });

const cloneTextTracks: (tracks: Array<TextTrack>) => Array<TextTrack> = (tracks) =>
  tracks.map((track) => {
    return {
      ...track,
      roles: [...track.roles],
    };
  });

const cloneVideoTracks: (tracks: Array<VideoTrack>) => Array<VideoTrack> = (tracks) =>
  tracks.map((track) => {
    return {
      ...track,
      roles: [...track.roles],
    };
  });

const cloneAllTracks: (tracks: AllTracks) => AllTracks = (tracks) => {
  const { audio, text, video } = tracks;

  return {
    audio: cloneAudioTracks(audio),
    text: cloneTextTracks(text),
    video: cloneVideoTracks(video),
  };
};

export { cloneAllTracks };
