/* @flow */

import './VideoCarousel.css';
import * as React from 'react';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { logWarning, showDebug } from '../../helpers/debug/debug';
import { updateVideoCarouselMuted, updateVideoCarouselPaused, updateVideoCarouselPlaying, updateVideoCarouselUnmuted } from '../../redux/ui/actions';
import type { BasicCallbackFunction } from '@ntg/utils/dist/types';
import ButtonBack from '../buttons/ButtonBack';
import type { CombinedReducers } from '../../redux/reducers';
import type { Dispatch } from '../../redux/types/types';
import HotKeys from '../../helpers/hotKeys/hotKeys';
import { SkipDirection } from '../../helpers/ui/types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import fscreen from 'fscreen';
import { getSkippingTypeAndValue } from '../../helpers/ui/player';

const ONE_HUNDRED: number = 100;

type VideoCarouselPropType = {||};

type ReduxVideoCarouselDispatchToPropsType = {|
  +localUpdateVideoCarouselMuted: BasicCallbackFunction,
  +localUpdateVideoCarouselPaused: BasicCallbackFunction,
  +localUpdateVideoCarouselPlaying: BasicCallbackFunction,
  +localUpdateVideoCarouselUnmuted: BasicCallbackFunction,
|};

type ReduxVideoCarouselReducerStateType = {|
  +gridSectionId: string | null,
  +isDebugModeEnabled: boolean,
  +isMuted: boolean,
  +isPlaying: boolean,
|};

type CompleteVideoCarouselPropType = {|
  ...VideoCarouselPropType,
  ...ReduxVideoCarouselReducerStateType,
  ...ReduxVideoCarouselDispatchToPropsType,
|};

type VideoCarouselStateType = {|
  currentIndex: number,
  isHidden: boolean,
  isInitializingPlay: boolean,
  isMaskHidden: boolean,
  isMaskReduced: boolean,
  trailerUrlList: ?Array<string>,
|};

const InitialState = Object.freeze({
  currentIndex: -1,
  isHidden: false,
  isInitializingPlay: false,
  isMaskHidden: false,
  isMaskReduced: false,
  trailerUrlList: null,
});

class VideoCarouselView extends React.PureComponent<CompleteVideoCarouselPropType, VideoCarouselStateType> {
  isPlayPromiseCancelled: boolean;

  video: HTMLMediaElement | null;

  videoContainer: HTMLElement | null;

  wasTrailerPlayingBeforePause: boolean;

  constructor(props: CompleteVideoCarouselPropType) {
    super(props);

    this.isPlayPromiseCancelled = false;
    this.video = null;
    this.videoContainer = null;
    this.wasTrailerPlayingBeforePause = false;

    const { gridSectionId } = props;

    this.state = {
      ...InitialState,
      isHidden: gridSectionId !== null,
    };
  }

  componentDidMount() {
    fscreen.addEventListener('fullscreenchange', this.onFullscreenChange, { passive: true });

    HotKeys.register('left', this.handleBackwardHotKey, { name: 'VideoCarousel.backward' });
    HotKeys.register('right', this.handleForwardHotKey, { name: 'VideoCarousel.forward' });

    Messenger.on(MessengerEvents.CAROUSEL_SHOW_DEBUG, this.showDebug);
    Messenger.on(MessengerEvents.REDUCE_VIDEO_CAROUSEL_MASK, this.reduceMask);
    Messenger.on(MessengerEvents.ENLARGE_VIDEO_CAROUSEL_MASK, this.enlargeMask);
    Messenger.on(MessengerEvents.CAROUSEL_SET_CURRENT_INDEX, this.setCurrentIndex);
    Messenger.on(MessengerEvents.CAROUSEL_SHOW, this.show);
    Messenger.on(MessengerEvents.CAROUSEL_HIDE, this.hide);
    Messenger.on(MessengerEvents.VIDEO_CAROUSEL_SET, this.setTrailers);
    Messenger.on(MessengerEvents.VIDEO_CAROUSEL_STOP, this.stopVideo);
    Messenger.on(MessengerEvents.VIDEO_CAROUSEL_ENTER_FULLSCREEN, this.enterFullscreen);
    Messenger.on(MessengerEvents.VIDEO_CAROUSEL_EXIT_FULLSCREEN, this.exitFullscreen);
    Messenger.on(MessengerEvents.VIDEO_CAROUSEL_TOGGLE_FULLSCREEN, this.toggleFullscreen);
    Messenger.on(MessengerEvents.VIDEO_CAROUSEL_TRAILER_HIDDEN, this.handleTrailerHidden);
    Messenger.on(MessengerEvents.VIDEO_CAROUSEL_TRAILER_VISIBLE, this.handleTrailerVisible);
  }

  componentDidUpdate(prevProps: CompleteVideoCarouselPropType) {
    const { isMuted, isPlaying } = this.props;
    const { isMuted: prevIsMuted, isPlaying: prevIsPlaying } = prevProps;

    if (isPlaying !== prevIsPlaying) {
      if (isPlaying) {
        this.playVideo();
      } else {
        this.pauseVideo();
      }
    }

    if (isMuted !== prevIsMuted) {
      if (isMuted) {
        this.muteVideo();
      } else {
        this.unmuteVideo();
      }
    }
  }

  componentWillUnmount() {
    this.isPlayPromiseCancelled = true;

    fscreen.removeEventListener('fullscreenchange', this.onFullscreenChange, { passive: true });

    HotKeys.unregister('left', this.handleBackwardHotKey);
    HotKeys.unregister('right', this.handleForwardHotKey);

    Messenger.off(MessengerEvents.CAROUSEL_SHOW_DEBUG, this.showDebug);
    Messenger.off(MessengerEvents.REDUCE_VIDEO_CAROUSEL_MASK, this.reduceMask);
    Messenger.off(MessengerEvents.ENLARGE_VIDEO_CAROUSEL_MASK, this.enlargeMask);
    Messenger.off(MessengerEvents.CAROUSEL_SET_CURRENT_INDEX, this.setCurrentIndex);
    Messenger.off(MessengerEvents.CAROUSEL_SHOW, this.show);
    Messenger.off(MessengerEvents.CAROUSEL_HIDE, this.hide);
    Messenger.off(MessengerEvents.VIDEO_CAROUSEL_SET, this.setTrailers);
    Messenger.off(MessengerEvents.VIDEO_CAROUSEL_STOP, this.stopVideo);
    Messenger.off(MessengerEvents.VIDEO_CAROUSEL_ENTER_FULLSCREEN, this.enterFullscreen);
    Messenger.off(MessengerEvents.VIDEO_CAROUSEL_EXIT_FULLSCREEN, this.exitFullscreen);
    Messenger.off(MessengerEvents.VIDEO_CAROUSEL_TOGGLE_FULLSCREEN, this.toggleFullscreen);
    Messenger.off(MessengerEvents.VIDEO_CAROUSEL_TRAILER_HIDDEN, this.handleTrailerHidden);
    Messenger.off(MessengerEvents.VIDEO_CAROUSEL_TRAILER_VISIBLE, this.handleTrailerVisible);
  }

  showDebug: () => void = () => {
    const { props, state } = this;
    const { isDebugModeEnabled } = props;

    if (!isDebugModeEnabled) {
      return;
    }

    showDebug('Background Trailers', {
      instance: this,
      instanceFields: ['wasTrailerPlayingBeforePause'],
      props,
      propsFields: ['isMuted', 'isPlaying'],
      state,
      stateFields: ['currentIndex', 'isHidden', 'isInitializingPlay', 'isMaskHidden', 'isMaskReduced', 'trailerUrlList'],
    });
  };

  reduceMask: () => void = () => {
    this.setState({ isMaskReduced: true });
  };

  enlargeMask: () => void = () => {
    this.setState({ isMaskReduced: false });
  };

  setTrailers = (trailerUrlList: Array<string>) => {
    this.setState({ trailerUrlList });
  };

  setCurrentIndex = (currentIndex: number) => {
    this.setState({ currentIndex }, this.playVideo);
  };

  show: () => void = () => {
    this.setState({ isHidden: false });
  };

  hide: () => void = () => {
    this.setState({ isHidden: true });
  };

  onFullscreenChange: () => void = () => {
    const { videoContainer } = this;

    if (videoContainer && fscreen.fullscreenEnabled) {
      if (fscreen.fullscreenElement !== null) {
        // Fullscreen activated
        videoContainer.classList.add('fullscreen');
        this.setState({ isMaskHidden: true });
      } else {
        // Fullscreen deactivated
        videoContainer.classList.remove('fullscreen');
        Messenger.emit(MessengerEvents.VIDEO_CAROUSEL_FULLSCREEN_EXITED);
        this.setState({ isMaskHidden: false });
      }
    }
  };

  isInFullscreen = (): boolean => fscreen.fullscreenElement !== null && fscreen.fullscreenEnabled;

  enterFullscreen: () => void = () => {
    const { video, videoContainer } = this;

    if (videoContainer && fscreen.fullscreenEnabled && fscreen.fullscreenElement === null) {
      if (video) {
        // Show video controls
        video.controls = true;
      }

      fscreen.requestFullscreen(videoContainer).catch((error) => logWarning(`Fullscreen unavailable at the moment (${error.message})`));
    }
  };

  exitFullscreen: () => void = () => {
    const { video } = this;

    if (this.isInFullscreen()) {
      if (video) {
        // Hide video controls
        video.controls = false;
      }

      fscreen.exitFullscreen();
      this.setState({ isMaskHidden: false });
    }
  };

  toggleFullscreen: () => void = () => {
    if (this.isInFullscreen()) {
      this.exitFullscreen();
    } else {
      this.enterFullscreen();
    }
  };

  // Skip backward
  handleBackwardHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.skipVideo(SkipDirection.Backward, event);
  };

  handleForwardHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.skipVideo(SkipDirection.Forward, event);
  };

  handleOnEnded: () => void = () => {
    const { currentIndex } = this.state;

    this.exitFullscreen();
    Messenger.emit(MessengerEvents.VIDEO_CAROUSEL_UPDATE_PROGRESS, currentIndex, 0);
    Messenger.emit(MessengerEvents.VIDEO_CAROUSEL_ENDED);
  };

  handleOnPause: () => void = () => {
    const { localUpdateVideoCarouselPaused } = this.props;

    localUpdateVideoCarouselPaused();
  };

  handleOnPlay: () => void = () => {
    const { localUpdateVideoCarouselPlaying } = this.props;

    localUpdateVideoCarouselPlaying();
  };

  handleOnVolumeChange: () => void = () => {
    const { localUpdateVideoCarouselMuted, localUpdateVideoCarouselUnmuted } = this.props;
    const { video } = this;

    if (!video) {
      return;
    }

    if (video.muted) {
      localUpdateVideoCarouselMuted();
    } else {
      localUpdateVideoCarouselUnmuted();
    }
  };

  handleOnTimeUpdate: () => void = () => {
    const { currentIndex } = this.state;
    const { video } = this;

    if (!video || isNaN(video.duration)) {
      return;
    }

    const percent = Math.floor((video.currentTime / video.duration) * ONE_HUNDRED);
    Messenger.emit(MessengerEvents.VIDEO_CAROUSEL_UPDATE_PROGRESS, currentIndex, percent);
  };

  handleOnDoubleClick: () => void = this.toggleFullscreen;

  playVideo: () => void = () => {
    const { video } = this;

    if (!video) {
      return;
    }

    if (video.paused) {
      const playPromise = video.play();

      if (playPromise) {
        this.setState({ isInitializingPlay: true });
        playPromise
          .then(() => {
            if (!this.isPlayPromiseCancelled) {
              this.setState({ isInitializingPlay: false });
            }
          })
          .catch(() => {
            // Nothing to do (user probably navigated or changed video)
          });
      }
    }
  };

  pauseVideo: () => void = () => {
    const { isInitializingPlay } = this.state;
    const { video } = this;

    if (!video) {
      return;
    }

    if (!isInitializingPlay && !video.paused) {
      video.pause();
    }
  };

  stopVideo: () => void = () => {
    this.pauseVideo();
    this.setState({ currentIndex: 0 });
  };

  handleTrailerHidden: () => void = () => {
    const { video } = this;

    if (!video) {
      return;
    }

    this.wasTrailerPlayingBeforePause = !video.paused;
    this.pauseVideo();
  };

  handleTrailerVisible: () => void = () => {
    const { video, wasTrailerPlayingBeforePause } = this;

    if (!video) {
      return;
    }

    if (wasTrailerPlayingBeforePause) {
      this.wasTrailerPlayingBeforePause = false;
      this.playVideo();
    }
  };

  muteVideo: () => void = () => {
    const { video } = this;

    if (video) {
      video.muted = true;
    }
  };

  unmuteVideo: () => void = () => {
    const { video } = this;

    if (video) {
      video.muted = false;
    }
  };

  handleBackOnClick: () => void = () => {
    this.exitFullscreen();
  };

  skipVideo = (direction: SkipDirection, event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { video } = this;

    if (!video || isNaN(video.duration)) {
      return;
    }

    const { currentTime, duration } = video;
    const { value } = getSkippingTypeAndValue(direction, event);

    let time = currentTime + value;
    if (time < 0) {
      time = 0;
    } else if (time > duration) {
      time = duration;
    }

    video.currentTime = time;
  };

  render(): React.Node {
    const { isMuted } = this.props;
    const { currentIndex, isHidden, isMaskHidden, isMaskReduced, trailerUrlList } = this.state;

    if (isHidden || !trailerUrlList || currentIndex === -1) {
      return false;
    }

    const url = trailerUrlList[currentIndex];

    const maskElement = isMaskHidden ? null : <div className={clsx('mask', isMaskReduced && 'reduced')} />;

    return (
      <div
        className='videoCarousel'
        ref={(instance) => {
          this.videoContainer = instance;
        }}
      >
        <video
          controlsList='nofullscreen nodownload noremoteplayback'
          muted={isMuted}
          onDoubleClick={this.handleOnDoubleClick}
          onEnded={this.handleOnEnded}
          onPause={this.handleOnPause}
          onPlay={this.handleOnPlay}
          onTimeUpdate={this.handleOnTimeUpdate}
          onVolumeChange={this.handleOnVolumeChange}
          ref={(instance) => {
            this.video = instance;
          }}
          src={url}
        />
        {maskElement}
        <ButtonBack className='backBar' hasText={false} onClick={this.handleBackOnClick} />
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxVideoCarouselReducerStateType => {
  return {
    gridSectionId: state.ui.gridSectionId,
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
    isMuted: state.ui.videoCarousel.isMuted,
    isPlaying: state.ui.videoCarousel.isPlaying,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxVideoCarouselDispatchToPropsType => {
  return {
    localUpdateVideoCarouselMuted: () => dispatch(updateVideoCarouselMuted()),

    localUpdateVideoCarouselPaused: () => dispatch(updateVideoCarouselPaused()),

    localUpdateVideoCarouselPlaying: () => dispatch(updateVideoCarouselPlaying()),

    localUpdateVideoCarouselUnmuted: () => dispatch(updateVideoCarouselUnmuted()),
  };
};

const VideoCarousel: React.ComponentType<VideoCarouselPropType> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(VideoCarouselView);

export default VideoCarousel;
