/* @flow */

import './VodPurchaseModal.css';
import * as React from 'react';
import { type BO_API_PURCHASE_STATUS_SUCCESS_TYPE, Purchase, type PurchaseType } from '../../../redux/netgemApi/actions/videofutur/types/purchase';
import {
  BO_PURCHASE_ALREADY_PURCHASED,
  BO_PURCHASE_DEVICE_NOT_FOUND,
  BO_PURCHASE_INVALID_CONTENT,
  BO_PURCHASE_INVALID_PROMOCODE,
  BO_PURCHASE_INVALID_PROMOCODE_FOR_TITLE,
  BO_PURCHASE_INVALID_USER_IP,
  BO_PURCHASE_STATUS_OK,
} from '../../../libs/netgemLibrary/videofutur/types/ErrorCodes';
import { Currency, getCurrencySymbol } from '../../../helpers/ui/metadata/Types';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { PictoCheck, PictoInfo } from '@ntg/components/dist/pictos/Element';
import { SECONDS_PER_DAY, SECONDS_PER_HOUR, getIso8601DurationInSeconds } from '../../../helpers/dateTime/Format';
import { SentryTagName, SentryTagValue } from '../../../helpers/debug/sentryTypes';
import { type VOD_PURCHASE_DATA_TYPE, clearPendingPurchase } from '../../../helpers/rights/pendingOperations';
import { getEpisodeIndexAndTitle, getTitle, renderProgramLanguage, renderProgramParentalGuidance } from '../../../helpers/ui/metadata/Format';
import { getPriceAsFloat, getPriceAsInteger } from '../../../helpers/maths/maths';
import type { BO_API_ERROR_TYPE } from '../../../redux/netgemApi/actions/videofutur/types/common';
import type { BO_API_REQUEST_RESPONSE_BASE } from '../../../libs/netgemLibrary/videofutur/types/RequestResponseBase';
import ButtonFX from '../../buttons/ButtonFX';
import type { CombinedReducers } from '../../../redux/reducers';
import type { CustomNetworkError } from '../../../libs/netgemLibrary/helpers/CustomNetworkError';
import type { Dispatch } from '../../../redux/types/types';
import HotKeys from '../../../helpers/hotKeys/hotKeys';
import { Localizer } from '@ntg/utils/dist/localization';
import Modal from '../modal';
import { ModalIcon } from '../modalTypes';
import type { ModalState } from '../../../redux/modal/reducers';
import type { NETGEM_API_V8_METADATA_SCHEDULE } from '../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import SentryWrapper from '../../../helpers/debug/sentry';
import { TIPPY_DEFAULT_OPTIONS } from '@ntg/ui/dist/tooltip';
import Tippy from '@tippyjs/react';
import { WidthKind } from '../../buttons/types';
import { buildErrorResponse } from '../../../redux/netgemApi/actions/helpers/bo';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { getBOSetting } from '../../../redux/netgemApi/actions/helpers/boSettings';
import { ignoreIfAborted } from '../../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import { logError } from '../../../helpers/debug/debug';
import { openCheckOut } from '../../../helpers/applicationCustomization/externalPaymentSystem';
import { purchase } from '../../../redux/netgemApi/actions/videofutur/helpers/purchase';
import { renderPurchaseSummary } from '../../../helpers/videofutur/metadata';
import sendBOPurchaseStatusRequest from '../../../redux/netgemApi/actions/videofutur/purchaseStatus';
import sendV8MetadataLocationRequest from '../../../redux/netgemApi/actions/v8/metadataLocation';
import { stopOpenStreams } from '../../../redux/netgemApi/actions/videofutur/helpers/stopOpenStreams';

// If duration is greater than 48h, display it as days (in seconds)
const MAX_HOURS_DISPLAYED = 172_800;

// Time before modal can be closed again by clicking on overlay after the information tooltip has been closed (in ms)
const OVERLAY_CLICK_REENABLING_DELAY = 300;

const DEFAULT_RENT_VALIDITY = '48h';

const STEP_1: number = 1;
const STEP_2: number = 2;

type ReduxVodPurchaseModalReducerStateType = {|
  +identity: string,
  +isExternalCheckOutEnabled: boolean,
|};

type ReduxVodPurchaseModalDispatchToPropsType = {|
  +localGetPurchaseStatus: (distributorId: string, vtiId: number, promocode: string, signal?: AbortSignal) => Promise<any>,
  +localPurchase: (distributorId: string, vtiId: number, promocode: string | null, signal?: AbortSignal) => Promise<any>,
  +localSendV8MetadataLocationRequest: (assetId: string, signal?: AbortSignal) => Promise<any>,
  +localStopOpenStreams: (distributorId: string, signal?: AbortSignal) => Promise<any>,
|};

type CompleteVodPurchaseModalPropType = {|
  ...ReduxVodPurchaseModalReducerStateType,
  ...ReduxVodPurchaseModalDispatchToPropsType,
  ...ModalState,
|};

type VodPurchaseModalStateType = {|
  appliedPromocode: string | null,
  discountPrice: string | null,
  errorMessageKey: string | null,
  isApplyingPromocode: boolean,
  isPaying: boolean,
  promocode: string,
  promocodeError: string | null,
  rentValidity: string,
  step: number,
  title: {| line1: string | null, line2: string | null |} | null,
  updatedPrice: string | null,
  vodLocationMetadata: NETGEM_API_V8_METADATA_SCHEDULE | null,
|};

const InitialState = Object.freeze({
  appliedPromocode: null,
  discountPrice: null,
  errorMessageKey: null,
  isApplyingPromocode: false,
  isPaying: false,
  promocode: '',
  promocodeError: null,
  rentValidity: '48h',
  step: STEP_1,
  title: null,
  updatedPrice: null,
  vodLocationMetadata: null,
});

class VodPurchaseModalView extends React.PureComponent<CompleteVodPurchaseModalPropType, VodPurchaseModalStateType> {
  abortController: AbortController;

  closingEnableTimer: TimeoutID | null;

  modal: React.ElementRef<any> | null;

  helpTooltip: any;

  constructor(props: CompleteVodPurchaseModalPropType) {
    super(props);

    this.abortController = new AbortController();
    this.closingEnableTimer = null;
    this.helpTooltip = null;
    this.modal = null;

    this.state = { ...InitialState };
  }

  componentDidMount() {
    this.initialize();

    // Clear any pending guest purchase since it's being displayed right now
    clearPendingPurchase();
  }

  componentDidUpdate(prevProps: CompleteVodPurchaseModalPropType) {
    const { vodPurchaseData } = this.props;
    const { vodPurchaseData: prevVodPurchaseData } = prevProps;

    if (vodPurchaseData !== prevVodPurchaseData) {
      this.initialize();
    }
  }

  componentWillUnmount() {
    const { abortController } = this;

    abortController.abort('Component VodPurchaseModal will unmount');

    if (this.closingEnableTimer) {
      clearTimeout(this.closingEnableTimer);
    }
  }

  initialize = () => {
    const { vodPurchaseData } = this.props;

    if (!vodPurchaseData) {
      return;
    }

    this.buildPurchaseTitle(vodPurchaseData);

    const { locationId, vodLocationMetadata } = vodPurchaseData;

    if (!vodLocationMetadata && locationId) {
      // Query VOD location metadata to get viewingHistoryId and available audio/subtitles (first episode is used for pack purchase)
      this.loadVodLocationMetadata(locationId);
    }

    // Check VIP account (and bad configuration)
    this.applyPromocode('');
  };

  buildPurchaseTitle = (vodPurchaseData: VOD_PURCHASE_DATA_TYPE) => {
    const { itemCount, programMetadata, purchaseType, seriesMetadata } = vodPurchaseData;

    const seriesTitles = getTitle(seriesMetadata, Localizer.language);

    let programTitle = null;
    let episodeCount = null;

    if (purchaseType === Purchase.BuyPack) {
      episodeCount = Localizer.localize('modal.vod_purchase.pack_item_count', { count: itemCount });
    } else if (programMetadata) {
      const { episodeIndex, episodeTitle } = getEpisodeIndexAndTitle(programMetadata, seriesTitles, Localizer.language);
      const fixedEpisodeTitle = episodeTitle ?? Localizer.localize('modal.vod_purchase.episode_index', { index: episodeIndex });
      programTitle = seriesTitles ? fixedEpisodeTitle : getTitle(programMetadata, Localizer.language);
    }

    this.setState({
      title: {
        line1: seriesTitles ?? programTitle,
        line2: seriesTitles ? episodeCount ?? programTitle : null,
      },
    });
  };

  getFullTitle = (): string => {
    const { title } = this.state;

    if (!title) {
      return Localizer.localize('vod.untitled');
    }

    const { line1, line2 } = title;

    if (line1 === null && line2 === null) {
      return Localizer.localize('vod.untitled');
    }

    // NOTE: Flow thinks line1 could be null here, which is obviously wrong

    if (line2 === null) {
      // Full title: <Program title>
      return line1 ?? '';
    }

    // Full title: <Series titles - episode title> or <Series title - episode count>
    return `${line1 ?? ''} - ${line2}`;
  };

  loadVodLocationMetadata = (locationId: string) => {
    const { localSendV8MetadataLocationRequest } = this.props;
    const {
      abortController: { signal },
    } = this;

    localSendV8MetadataLocationRequest(locationId, signal)
      .then(({ result: vodLocationMetadata }) => {
        signal.throwIfAborted();

        const rentValidity = this.formatLicenceDuration(vodLocationMetadata?.location.purchaseInfo?.licenseDuration) ?? DEFAULT_RENT_VALIDITY;
        this.setState({ rentValidity, vodLocationMetadata });
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  handlePlayNowButtonOnClick = () => {
    const { vodPurchaseData } = this.props;
    const { vodLocationMetadata: vodLocationMetadataState } = this.state;
    const { modal } = this;

    if (!modal || !vodPurchaseData) {
      logError('Cannot play because of missing modal or purchase data');
      return;
    }

    const { locationId, viewingHistoryId, vodLocationMetadata, vtiId } = vodPurchaseData;
    const localVodLocation = vodLocationMetadata ?? vodLocationMetadataState?.location;

    // Play movie or first episode of season
    modal.close({
      locationId,
      playNow: true,
      viewingHistoryId: localVodLocation?.providerInfo?.viewingHistoryId ?? viewingHistoryId,
      // Use vtiId from vodLocationMetadata because in case of pack purchase, the one from vodPurchaseData is the pack's one
      vtiId: localVodLocation?.purchaseInfo?.vtiId ?? vtiId,
    });
  };

  handlePlayLaterButtonOnClick = () => {
    const { modal } = this;

    if (modal) {
      modal.close({ playNow: false });
    }
  };

  handlePromocodeOnChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const {
      currentTarget: { validity, value },
    } = event;

    if (validity.valid || value === '') {
      this.setState({ promocode: value });
    }
  };

  handlePromocodeOK = (response: BO_API_PURCHASE_STATUS_SUCCESS_TYPE, vtiId: number, displayPrice: string, appliedPromocode: string) => {
    const { price } = response;

    const discountPrice = getPriceAsFloat(price);
    const originalPrice = getPriceAsFloat(displayPrice);

    if (typeof discountPrice === 'number' && typeof originalPrice === 'number' && discountPrice !== originalPrice) {
      if (discountPrice < originalPrice) {
        // Active discount or promocode applied
        this.setState({
          appliedPromocode,
          discountPrice: `${price.replace('.', ',')}${getCurrencySymbol(Currency.Euro)}`,
          promocode: '',
          promocodeError: null,
        });
      } else {
        /*
         * Current price is greater than original price (expired discount but cache issue)
         * See https://netgem.atlassian.net/browse/GM-251
         */
        const updatedPrice = `${price.replace('.', ',')}${getCurrencySymbol(Currency.Euro)}`;
        this.setState({ updatedPrice });

        SentryWrapper.warning({
          context: {
            appliedPromocode,
            discountPrice,
            originalPrice,
            updatedPrice,
            vtiId,
          },
          message: 'Real price higher than display price',
          tagName: SentryTagName.Component,
          tagValue: SentryTagValue.VodPurchase,
        });
      }
    }
  };

  getLocalizedErrorMessage = (status: string): string => {
    if (status === BO_PURCHASE_INVALID_CONTENT) {
      return 'modal.vod_purchase.invalid_content';
    }

    if (status === BO_PURCHASE_INVALID_USER_IP) {
      return 'modal.vod_purchase.geolocked_content';
    }

    // Generic error
    return 'modal.vod_purchase.promocode_error';
  };

  promocodeAppliedCallback = (appliedPromocode: string, vtiId: number, response: BO_API_REQUEST_RESPONSE_BASE = {}, displayPrice: string = '') => {
    const { modal } = this;
    const { status } = response;

    this.setState({ isApplyingPromocode: false });

    switch (status) {
      case BO_PURCHASE_STATUS_OK: {
        // Promocode applied
        this.handlePromocodeOK((response: BO_API_PURCHASE_STATUS_SUCCESS_TYPE), vtiId, displayPrice, appliedPromocode);
        break;
      }

      case BO_PURCHASE_INVALID_PROMOCODE:
      case BO_PURCHASE_INVALID_PROMOCODE_FOR_TITLE: {
        // Invalid promocode
        this.setState({ promocodeError: Localizer.localize('modal.vod_purchase.invalid_promocode') });
        break;
      }

      case BO_PURCHASE_ALREADY_PURCHASED: {
        // Item has already been purchased: refresh purchase list
        Messenger.emit(MessengerEvents.REFRESH_PURCHASE_LIST);
        if (modal) {
          modal.close();
        }
        break;
      }

      case BO_PURCHASE_DEVICE_NOT_FOUND: {
        // Token expired (device probably disconnected)
        Messenger.emit(MessengerEvents.AUTHENTICATION_REQUIRED);
        break;
      }

      case BO_PURCHASE_INVALID_CONTENT:
      case BO_PURCHASE_INVALID_USER_IP:
      default: {
        /*
         * Potential BO errors at this point:
         *  - BO_PURCHASE_INVALID_CONTENT: content should have never been visible
         *  - BO_PURCHASE_INVALID_USER_IP: content is geolocked
         *  - unexpected error
         *
         * In any case, purchase is prevented
         */
        this.setState({ errorMessageKey: this.getLocalizedErrorMessage(status) });
        break;
      }
    }
  };

  applyPromocode = (promocode: string) => {
    const { localGetPurchaseStatus, vodPurchaseData } = this.props;
    const {
      abortController: { signal },
    } = this;

    if (!vodPurchaseData) {
      return;
    }

    const {
      displayPrice,
      distributorId,
      item: { id },
      vtiId,
    } = vodPurchaseData;

    if (!vtiId) {
      logError(`No VTI Id for ${id}`);
      return;
    }

    if (!distributorId) {
      logError(`No distributor Id for ${id}`);
      return;
    }

    this.setState({
      isApplyingPromocode: true,
      promocodeError: null,
    });

    localGetPurchaseStatus(distributorId, vtiId, promocode, signal)
      .then((response: BO_API_REQUEST_RESPONSE_BASE) => {
        signal.throwIfAborted();

        this.promocodeAppliedCallback(promocode, vtiId, response, displayPrice);
      })
      .catch((error: CustomNetworkError) => ignoreIfAborted(signal, error, () => this.promocodeAppliedCallback(promocode, vtiId, buildErrorResponse(null, error), displayPrice)));
  };

  handleApplyPromocodeButtonOnClick = () => {
    const { vodPurchaseData } = this.props;
    const { isApplyingPromocode, promocode } = this.state;

    if (isApplyingPromocode || !promocode || !vodPurchaseData || !vodPurchaseData.vtiId) {
      return;
    }

    this.applyPromocode(promocode);
  };

  paidCallback = (response: BO_API_REQUEST_RESPONSE_BASE = {}) => {
    const { modal } = this;
    const { status } = response;

    if (!status || status === BO_PURCHASE_STATUS_OK) {
      // Payment OK
      this.setState({
        isPaying: false,
        step: STEP_2,
      });
      return;
    }

    if (status === BO_PURCHASE_ALREADY_PURCHASED) {
      // Item has already been purchased: refresh purchase list
      Messenger.emit(MessengerEvents.REFRESH_PURCHASE_LIST);
      if (modal) {
        modal.close();
      }
      return;
    }

    if (status === BO_PURCHASE_DEVICE_NOT_FOUND) {
      // Token expired (device probably disconnected)
      Messenger.emit(MessengerEvents.AUTHENTICATION_REQUIRED);
      return;
    }

    // Error paying
    const { errorMsg } = (response: BO_API_ERROR_TYPE);

    Messenger.emit(MessengerEvents.NOTIFY_ERROR, <div>{errorMsg || Localizer.localize('common.messages.errors.retry')}</div>);

    this.setState({ isPaying: false });
  };

  isNotFree = (price: string): boolean => {
    const n = getPriceAsInteger(price);
    return n !== null ? n > 0 : true;
  };

  handlePayButtonOnClick = () => {
    const { localStopOpenStreams, vodPurchaseData } = this.props;
    const { isApplyingPromocode, isPaying } = this.state;
    const {
      abortController: { signal },
    } = this;

    if (isApplyingPromocode || isPaying || !vodPurchaseData || !vodPurchaseData.vtiId) {
      return;
    }

    const {
      distributorId,
      item: { id },
    } = vodPurchaseData;

    if (!distributorId) {
      logError(`No distributor Id for ${id}`);
      return;
    }

    this.setState({ isPaying: true }, () => {
      localStopOpenStreams(distributorId, signal)
        .then(() => {
          signal.throwIfAborted();
          this.purchaseInternal(vodPurchaseData, distributorId);
        })
        .catch((error) => ignoreIfAborted(signal, error, () => this.paidCallback(buildErrorResponse(null, error))));
    });
  };

  purchaseInternal = (vodPurchaseData: VOD_PURCHASE_DATA_TYPE, distributorId: string) => {
    const { identity, isExternalCheckOutEnabled, localPurchase } = this.props;
    const { appliedPromocode, discountPrice, updatedPrice } = this.state;

    const {
      abortController: { signal },
      modal,
    } = this;

    const { displayPrice, vtiId } = vodPurchaseData;

    if (isExternalCheckOutEnabled && this.isNotFree(discountPrice || updatedPrice || displayPrice)) {
      // External payment system (e.g. Chargebee)

      if (modal) {
        // Prevent from closing modal by clicking outside when the user only wants to close the tooltip
        modal.disableClosing();
      }

      const title = this.getFullTitle();

      openCheckOut(identity, vtiId, title, updatedPrice || displayPrice, appliedPromocode, discountPrice)
        .then((response?: BO_API_REQUEST_RESPONSE_BASE) => {
          /*
           * Refresh purchase list to update purchased item
           * NOTE: It's even refreshed when user closed the popup because we're not sure (yet)
           * if the transaction could finish without the app being aware of it
           */
          Messenger.emit(MessengerEvents.REFRESH_PURCHASE_LIST);

          if (typeof response === 'undefined') {
            // Checkout has been cancelled (probably by closing the popup)
            SentryWrapper.info({
              context: {
                appliedPromocode,
                discountPrice,
                displayPrice,
                identity,
                title,
                updatedPrice,
                vtiId,
              },
              message: 'User closed Chargebee popup',
              tagName: SentryTagName.Component,
              tagValue: SentryTagValue.VodPurchase,
            });
            if (modal) {
              modal.close({ playNow: false });
            }
            return;
          }

          this.paidCallback(response);
        })
        .catch((error: Error) => {
          this.paidCallback(buildErrorResponse(null, error));
        });
    } else {
      // Direct BO payment
      localPurchase(distributorId, vtiId, appliedPromocode, signal)
        .then((response: BO_API_REQUEST_RESPONSE_BASE) => {
          signal.throwIfAborted();

          this.paidCallback(response);
        })
        .catch((error) => ignoreIfAborted(signal, error, () => this.paidCallback(buildErrorResponse(null, error))));
    }
  };

  renderTitles = (): React.Element<any> | null => {
    const { title } = this.state;

    if (!title) {
      return null;
    }

    const { line1, line2 } = title;

    return (
      <div className='titles'>
        <div>{line1}</div>
        {line2 ? <div className='subtitle'>{line2}</div> : null}
      </div>
    );
  };

  formatLicenceDuration = (licenseDuration?: string): string | null => {
    if (!licenseDuration) {
      // Buy case
      return null;
    }

    const duration = getIso8601DurationInSeconds(licenseDuration);

    if (duration <= MAX_HOURS_DISPLAYED) {
      return Localizer.localize('modal.vod_purchase.licence_duration_hour', { count: duration / SECONDS_PER_HOUR });
    }

    return Localizer.localize('modal.vod_purchase.licence_duration_hour', { count: Math.round(duration / SECONDS_PER_DAY) });
  };

  handleInformationTooltipOnShow = (tip: any) => {
    const { modal } = this;

    this.helpTooltip = tip;
    HotKeys.register('escape', this.handleHideHotKey, {
      disableOthers: true,
      name: 'VodPurchase.hideTooltip',
    });

    if (modal) {
      // Prevent from closing modal by clicking outside when the user only wants to close the tooltip
      modal.disableClosing();
    }
  };

  handleInformationTooltipOnHide = () => {
    const { modal } = this;

    HotKeys.unregister('escape', this.handleHideHotKey);

    if (modal) {
      this.closingEnableTimer = setTimeout(modal.enableClosing, OVERLAY_CLICK_REENABLING_DELAY);
    }
  };

  handleHideHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { helpTooltip } = this;

    event.preventDefault();
    event.stopPropagation();

    if (helpTooltip) {
      helpTooltip.hide();
      this.helpTooltip = null;
    }
  };

  renderInformationTooltipContent = (validity: string): React.Element<any> => (
    <div className='tooltipContent vodInformation'>
      <div className='title'>{Localizer.localize('modal.vod_purchase.help.title')}</div>
      <div className='subtitle'>{Localizer.localize('modal.vod_purchase.help.rent_title')}</div>
      <div className='bullet'>{Localizer.localize('modal.vod_purchase.help.rent_validity', { validity })}</div>
      <div className='bullet'>{Localizer.localize('modal.vod_purchase.help.rent_plays')}</div>
      <div className='subtitle'>{Localizer.localize('modal.vod_purchase.help.buy_title')}</div>
      <div className='bullet'>{Localizer.localize('modal.vod_purchase.help.buy_validity')}</div>
      <div className='bullet'>{Localizer.localize('modal.vod_purchase.help.buy_plays')}</div>
      <div className='subtitle'>{Localizer.localize('modal.vod_purchase.help.video_quality_title')}</div>
      <div className='bullet'>{Localizer.localize('modal.vod_purchase.help.video_quality_best')}</div>
      <div className='bullet'>{Localizer.localize('modal.vod_purchase.help.video_quality_4k_hd')}</div>
      <div className='bullet'>{Localizer.localize('modal.vod_purchase.help.video_quality_4k_compatibility')}</div>
      <div className='subtitle'>{Localizer.localize('modal.vod_purchase.help.promocode_title')}</div>
      <div className='bullet'>{Localizer.localize('modal.vod_purchase.help.promocode_type')}</div>
      <div className='bullet'>{Localizer.localize('modal.vod_purchase.help.promocode_format')}</div>
      <div className='customerService'>{Localizer.localize('modal.vod_purchase.help.customer_service')}</div>
    </div>
  );

  renderPurchaseValidity = (purchaseType: PurchaseType, validity: string): React.Element<any> => {
    if (purchaseType === Purchase.Rent) {
      // Rent
      return (
        <>
          <div className='bullet'>{Localizer.localize('modal.vod_purchase.info.rent.validity', { validity })}</div>
          <div className='bullet'>{Localizer.localize('modal.vod_purchase.info.rent.concurrency')}</div>
        </>
      );
    }

    // Buy
    return (
      <>
        <div className='bullet'>{Localizer.localize('modal.vod_purchase.info.buy.validity')}</div>
        <div className='bullet'>{Localizer.localize('modal.vod_purchase.info.buy.concurrency')}</div>
      </>
    );
  };

  renderContent = (): React.Element<any> | null => {
    const { vodPurchaseData } = this.props;
    const { appliedPromocode, discountPrice, errorMessageKey, isApplyingPromocode, isPaying, promocode, promocodeError, rentValidity, updatedPrice, vodLocationMetadata } = this.state;

    if (errorMessageKey || !vodPurchaseData) {
      return <div className='invalidContent'>{Localizer.localize(errorMessageKey ?? 'modal.vod_purchase.promocode_error')}</div>;
    }

    const { definition, displayPrice, item, programMetadata, purchaseType } = vodPurchaseData;

    /* eslint-disable react/jsx-props-no-spreading */
    return (
      <div className='validation'>
        <div className='summary'>{renderPurchaseSummary(purchaseType, definition, displayPrice, discountPrice, updatedPrice)}</div>
        <div className='details other'>
          {renderProgramLanguage(vodLocationMetadata?.location ?? null)}
          {renderProgramParentalGuidance(item, programMetadata)}
        </div>
        <div className='validity'>{this.renderPurchaseValidity(purchaseType, rentValidity)}</div>
        <div className='codeAndButton'>
          <input
            disabled={isApplyingPromocode || appliedPromocode}
            onChange={this.handlePromocodeOnChange}
            pattern='[a-zA-Z0-9]+'
            placeholder={Localizer.localize('modal.vod_purchase.promocode')}
            type='text'
            value={appliedPromocode ? appliedPromocode : promocode}
          />
          {appliedPromocode ? (
            <PictoCheck hasHoverEffect={false} />
          ) : (
            <ButtonFX hasPadding isDisabled={promocode === ''} isLoading={isApplyingPromocode} onClick={this.handleApplyPromocodeButtonOnClick} widthKind={WidthKind.Small}>
              {Localizer.localize('modal.vod_purchase.apply_promocode')}
            </ButtonFX>
          )}
        </div>
        <div className={clsx('promocodeError', promocodeError !== null && 'visible')}>{promocodeError}</div>
        <ButtonFX isDisabled={isApplyingPromocode} isLoading={isPaying} onClick={this.handlePayButtonOnClick} widthKind={WidthKind.Stretched}>
          {Localizer.localize('modal.vod_purchase.pay')}
        </ButtonFX>
        <Tippy
          {...TIPPY_DEFAULT_OPTIONS}
          appendTo={document.body}
          content={this.renderInformationTooltipContent(rentValidity)}
          interactive
          onHide={this.handleInformationTooltipOnHide}
          onShow={this.handleInformationTooltipOnShow}
          placement='top'
          trigger='click'
        >
          <div className='information'>
            <PictoInfo />
            <div>{Localizer.localize('modal.vod_purchase.information')}</div>
          </div>
        </Tippy>
      </div>
    );
    /* eslint-enable react/jsx-props-no-spreading */
  };

  renderChildren = (): React.Node => {
    const { vodPurchaseData } = this.props;
    const { step } = this.state;

    if (step === STEP_2) {
      // Step 2: Purchase finished

      return (
        <div className='paid'>
          <div className='title'>{Localizer.localize('modal.vod_purchase.paid.title')}</div>
          <div className='message'>{Localizer.localize('modal.vod_purchase.paid.message')}</div>
          <div className='buttons'>
            <ButtonFX onClick={this.handlePlayNowButtonOnClick}>{Localizer.localize('modal.vod_purchase.paid.watch_now')}</ButtonFX>
            <ButtonFX onClick={this.handlePlayLaterButtonOnClick}>{Localizer.localize('modal.vod_purchase.paid.watch_later')}</ButtonFX>
          </div>
        </div>
      );
    }

    // Step 1: User optionally enters a code and pays

    if (!vodPurchaseData) {
      return null;
    }

    return (
      <>
        {this.renderTitles()}
        {this.renderContent()}
      </>
    );
  };

  render(): React.Node {
    const { index, vodPurchaseData } = this.props;

    if (!vodPurchaseData) {
      return null;
    }

    return (
      <Modal
        canCloseOnEnter={false}
        className='vodPurchase'
        icon={ModalIcon.Cart}
        index={index}
        ref={(instance) => {
          this.modal = instance;
        }}
      >
        {this.renderChildren()}
      </Modal>
    );
  }
}

const getIdentity = (state: CombinedReducers): string => {
  const { appConfiguration } = state;
  return getBOSetting('identity', appConfiguration) ?? '';
};

const mapStateToProps = (state: CombinedReducers) => {
  return {
    identity: getIdentity(state),
    isExternalCheckOutEnabled: state.appConfiguration.isExternalCheckOutEnabled,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxVodPurchaseModalDispatchToPropsType => {
  return {
    localGetPurchaseStatus: (distributorId: string, vtiId: number, promocode: string, signal?: AbortSignal): Promise<any> =>
      dispatch(sendBOPurchaseStatusRequest(distributorId, vtiId, promocode, signal)),

    localPurchase: (distributorId: string, vtiId: number, promocode: string | null, signal?: AbortSignal): Promise<any> => dispatch(purchase(distributorId, vtiId, promocode ?? '', signal)),

    localSendV8MetadataLocationRequest: (assetId: string, signal?: AbortSignal): Promise<any> => dispatch(sendV8MetadataLocationRequest(assetId, signal)),

    localStopOpenStreams: (distributorId: string, signal?: AbortSignal): Promise<any> => dispatch(stopOpenStreams(distributorId, signal)),
  };
};

const VodPurchaseModal: React.ComponentType<ModalState> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(VodPurchaseModalView);

export default VodPurchaseModal;
