/* @flow */

import * as React from 'react';
import type { BasicCallbackFunction, KeyValuePair } from '@ntg/utils/dist/types';
import { FEATURE_NPVR, FEATURE_SUBSCRIPTION } from '../../redux/appConf/constants';
import { HeightKind, WidthKind } from '../buttons/types';
import {
  ItemType,
  type NETGEM_API_V8_FEED_ITEM,
  type NETGEM_API_V8_ITEM_LOCATION,
  type NETGEM_API_V8_ITEM_LOCATION_TYPE,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT,
} from '../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_METADATA_PROGRAM, NETGEM_API_V8_METADATA_SERIES } from '../../libs/netgemLibrary/v8/types/MetadataProgram';
import {
  type NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND,
  NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_KEEP_FROM_REPLAY,
  NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SERIES,
  NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SINGLE,
  type NETGEM_RECORDINGS_MAP,
  type NETGEM_SCHEDULED_RECORDINGS_MAP,
} from '../../libs/netgemLibrary/v8/types/Npvr';
import { PendingOperationKind, clearPendingOperation, getPendingOperation } from '../../helpers/rights/pendingOperations';
import { PictoRecordingStart, PictoRecordingStop } from '@ntg/components/dist/pictos/Element';
import { type RECORD_BUTTON_ACTION_TYPE, RECORD_BUTTON_ACTION_TYPE_DELETE, RECORD_BUTTON_ACTION_TYPE_NONE, RECORD_BUTTON_ACTION_TYPE_RECORD } from '../../helpers/npvr/Types';
import { type RECORD_ITEM_TYPE, startRecord, stopRecord } from '../../redux/netgemApi/actions/v8/recorder';
import { Theme, type ThemeType } from '@ntg/ui/dist/theme';
import { channelHasKeepFromReplay, channelIsRecordable } from '../../helpers/channel/helper';
import { logError, showDebug } from '../../helpers/debug/debug';
import { BroadcastStatus } from '../../helpers/ui/location/Format';
import ButtonFX from '../buttons/ButtonFX';
import type { CARD_DATA_MODAL_TYPE } from '../modal/cardModal/CardModalConstsAndTypes';
import type { ChannelMap } from '../../libs/netgemLibrary/v8/types/Channel';
import type { CombinedReducers } from '../../redux/reducers';
import type { Dispatch } from '../../redux/types/types';
import { Localizer } from '@ntg/utils/dist/localization';
import { Messenger } from '@ntg/utils/dist/messenger';
import type { NETGEM_API_V8_METADATA_SCHEDULE_LOCATION } from '../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { NETGEM_API_V8_RIGHTS } from '../../libs/netgemLibrary/v8/types/Rights';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import { RegistrationType } from '../../redux/appRegistration/types/types';
import { TileConfigTileType } from '../../libs/netgemLibrary/v8/types/WidgetConfig';
import { connect } from 'react-redux';
import { getEpisodeAndSeriesRecordStatus } from '../../helpers/npvr/recording';
import { getItemType } from '../../libs/netgemLibrary/v8/helpers/Item';
import { hideModal } from '../../redux/modal/actions';
import { isRecordGranted } from '../../helpers/rights/record';

export enum DisplayMode {
  Button,
  Picto,
}

export enum ContextKind {
  Card,
  Player,
  Tile,
  TVEpisode,
}

type ReduxRecordButtonReducerStateType = {|
  +channels: ChannelMap,
  +commercialOffers: ?KeyValuePair<Array<string>>,
  +defaultRights: ?NETGEM_API_V8_RIGHTS,
  +isDebugModeEnabled: boolean,
  +isNpvrEnabled: boolean,
  +isRegisteredAsGuest: boolean,
  +isSubscriptionFeatureEnabled: boolean,
  +npvrRecordingsFuture: NETGEM_RECORDINGS_MAP,
  +npvrRecordingsList: NETGEM_RECORDINGS_MAP,
  +npvrScheduledRecordingsList: NETGEM_SCHEDULED_RECORDINGS_MAP,
  +userRights: Array<string> | null,
|};

type ReduxRecordButtonDispatchToPropsType = {|
  +localHideModal: BasicCallbackFunction,
  +localStartRecord: (recordItem: RECORD_ITEM_TYPE, isDebugModeEnabled: boolean) => void,
  +localStopRecord: (recordItem: RECORD_ITEM_TYPE) => void,
|};

type DefaultProps = {|
  +cardDataUrlDefinition?: NETGEM_API_V8_URL_DEFINITION,
  +isDisabled?: boolean,
  +kind?: NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND,
  +theme?: ThemeType,
|};

// If not defined, kind will be deduced from the item's metadata
type RecordButtonPropType = {|
  ...DefaultProps,
  +broadcastStatus: BroadcastStatus,
  +context: ContextKind,
  +displayMode: DisplayMode,
  +item: NETGEM_API_V8_FEED_ITEM,
  +location: NETGEM_API_V8_METADATA_SCHEDULE_LOCATION | null,
  +previewCatchupScheduledEventId: ?string,
  +programMetadata: NETGEM_API_V8_METADATA_PROGRAM | null,
  +seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null,
  +tileType: TileConfigTileType,
|};

type CompleteRecordButtonPropType = {|
  ...RecordButtonPropType,
  ...ReduxRecordButtonReducerStateType,
  ...ReduxRecordButtonDispatchToPropsType,
|};

type RecordButtonStateType = {|
  actionType: RECORD_BUTTON_ACTION_TYPE,
  hasScheduledRecording: boolean,
  hasSeriesScheduledRecording: boolean,
  isWaitingForDeleteConfirmation: boolean,
  isWaitingForRecordConfirmation: boolean,
|};

const InitialState = Object.freeze({
  actionType: RECORD_BUTTON_ACTION_TYPE_NONE,
  hasScheduledRecording: false,
  hasSeriesScheduledRecording: false,
  isWaitingForDeleteConfirmation: false,
  isWaitingForRecordConfirmation: false,
});

class RecordButtonView extends React.PureComponent<CompleteRecordButtonPropType, RecordButtonStateType> {
  deleteFinishedEventName: ?string;

  recordFinishedEventName: ?string;

  static defaultProps: DefaultProps = {
    cardDataUrlDefinition: undefined,
    isDisabled: false,
    kind: undefined,
    theme: Theme.Dark,
  };

  constructor(props: CompleteRecordButtonPropType) {
    super(props);

    this.deleteFinishedEventName = null;
    this.recordFinishedEventName = null;

    this.state = { ...InitialState };
  }

  componentDidMount() {
    this.refreshState();
  }

  componentDidUpdate(prevProps: CompleteRecordButtonPropType) {
    const {
      item: { selectedProgramId },
      broadcastStatus,
      location,
      npvrRecordingsFuture,
      npvrRecordingsList,
      npvrScheduledRecordingsList,
      previewCatchupScheduledEventId,
    } = this.props;
    const {
      item: { selectedProgramId: prevSelectedProgramId },
      broadcastStatus: prevBroadcastStatus,
      location: prevLocation,
      npvrRecordingsFuture: prevNpvrRecordingsFuture,
      npvrRecordingsList: prevNpvrRecordingsList,
      npvrScheduledRecordingsList: prevNpvrScheduledRecordingsList,
      previewCatchupScheduledEventId: prevPreviewCatchupScheduledEventId,
    } = prevProps;

    if (location && location !== prevLocation) {
      this.checkPendingRecording();
    }

    if (
      selectedProgramId !== prevSelectedProgramId ||
      broadcastStatus !== prevBroadcastStatus ||
      previewCatchupScheduledEventId !== prevPreviewCatchupScheduledEventId ||
      npvrRecordingsFuture !== prevNpvrRecordingsFuture ||
      npvrRecordingsList !== prevNpvrRecordingsList ||
      npvrScheduledRecordingsList !== prevNpvrScheduledRecordingsList
    ) {
      this.refreshState();
    }
  }

  componentWillUnmount() {
    const { deleteFinishedEventName, recordFinishedEventName } = this;

    if (deleteFinishedEventName) {
      Messenger.off(deleteFinishedEventName, this.stopWaitingDeleteConfirmation);
      this.deleteFinishedEventName = null;
    }

    if (recordFinishedEventName) {
      Messenger.off(recordFinishedEventName, this.stopWaitingRecordConfirmation);
      this.recordFinishedEventName = null;
    }
  }

  showDebugInfo = () => {
    const { props, state } = this;

    showDebug('Record Button', {
      props,
      propsFields: ['cardDataUrlDefinition', 'broadcastStatus', 'displayMode', 'item', 'kind', 'location', 'previewCatchupScheduledEventId', 'programMetadata', 'seriesMetadata', 'theme', 'tileType'],
      state,
      stateFields: ['actionType', 'hasScheduledRecording', 'hasSeriesScheduledRecording', 'isWaitingForDeleteConfirmation', 'isWaitingForRecordConfirmation'],
    });
  };

  isRecordable = (
    kind?: NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND,
    locType: ?NETGEM_API_V8_ITEM_LOCATION_TYPE,
    selectedLocation: NETGEM_API_V8_ITEM_LOCATION,
    broadcastStatus: BroadcastStatus,
    hasRecording: boolean,
    hasScheduledRecording: boolean,
    hasSeriesScheduledRecording: boolean,
    previewCatchupScheduledEventId: ?string,
    isChannelRecordable: boolean,
    hasChannelKeepFromReplay: boolean,
  ): boolean => {
    if (
      kind === NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_KEEP_FROM_REPLAY &&
      hasChannelKeepFromReplay &&
      locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP &&
      broadcastStatus === BroadcastStatus.Past
    ) {
      // Catchup with keep from replay enabled (keep from replay has been checked before)
      return true;
    }

    if (!isChannelRecordable) {
      // Channel not recordable
      return false;
    }

    if (kind === NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SERIES) {
      // Series
      return true;
    }

    if (!selectedLocation || hasRecording || hasScheduledRecording || hasSeriesScheduledRecording) {
      // No location or (scheduled) recording already existing
      return false;
    }

    if (
      locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT &&
      (broadcastStatus === BroadcastStatus.Live || broadcastStatus === BroadcastStatus.Future || (broadcastStatus === BroadcastStatus.Preview && previewCatchupScheduledEventId))
    ) {
      // Scheduled event with right broadcast status
      return true;
    }

    // All other cases
    return false;
  };

  refreshState = () => {
    const {
      broadcastStatus,
      channels,
      isNpvrEnabled,
      item,
      item: {
        locType,
        selectedLocation,
        selectedLocation: { channelId },
        selectedProgramId,
      },
      kind,
      npvrRecordingsFuture,
      npvrRecordingsList,
      npvrScheduledRecordingsList,
      previewCatchupScheduledEventId,
      seriesMetadata,
    } = this.props;

    let actionType: RECORD_BUTTON_ACTION_TYPE = RECORD_BUTTON_ACTION_TYPE_NONE;
    let hasScheduledRecording = false;
    let hasSeriesScheduledRecording = false;

    const isChannelRecordable = isNpvrEnabled && channelIsRecordable(channels, channelId);
    const hasChannelKeepFromReplay = channelHasKeepFromReplay(channels, channelId);

    if (isChannelRecordable || hasChannelKeepFromReplay) {
      const status = getEpisodeAndSeriesRecordStatus(
        selectedProgramId,
        seriesMetadata?.id,
        item,
        npvrRecordingsList,
        npvrRecordingsFuture,
        npvrScheduledRecordingsList,
        previewCatchupScheduledEventId,
      );
      const { hasRecording } = status;
      ({ hasScheduledRecording, hasSeriesScheduledRecording } = status);

      const isDeletable =
        (kind === NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SERIES && hasSeriesScheduledRecording) ||
        ((locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT || locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING || locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP) &&
          (hasRecording || hasScheduledRecording || hasSeriesScheduledRecording));

      if (isDeletable && (kind !== NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SERIES || hasSeriesScheduledRecording)) {
        actionType = RECORD_BUTTON_ACTION_TYPE_DELETE;
      } else if (
        this.isRecordable(
          kind,
          locType,
          selectedLocation,
          broadcastStatus,
          hasRecording,
          hasScheduledRecording,
          hasSeriesScheduledRecording,
          previewCatchupScheduledEventId,
          isChannelRecordable,
          hasChannelKeepFromReplay,
        )
      ) {
        actionType = RECORD_BUTTON_ACTION_TYPE_RECORD;
      }
    }

    this.setState(
      {
        actionType,
        hasScheduledRecording,
        hasSeriesScheduledRecording,
      },
      this.checkPendingRecording,
    );
  };

  checkPendingRecording = () => {
    const {
      isRegisteredAsGuest,
      item: { id },
      location,
    } = this.props;

    const pendingOperation = getPendingOperation();

    if (isRegisteredAsGuest || !pendingOperation) {
      // User not logged in or no pending operation
      return;
    }

    const {
      cardData: {
        item: { id: targetId },
        pendingOperation: operation,
        recordKind,
      },
    } = pendingOperation;
    if (operation?.operationType !== PendingOperationKind.Record) {
      // Wrong operation type: ignore
      return;
    }

    if (!location) {
      // Too early: location is not defined yet
      return;
    }

    if (recordKind === NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SINGLE && id !== targetId) {
      return;
    }

    clearPendingOperation();

    // If recordKind is 'Series' or 'undefined' or 'Single' and program Id matches, starts recording
    this.startRecording(true, recordKind);
  };

  stopWaitingRecordConfirmation = () => {
    const { recordFinishedEventName } = this;

    if (recordFinishedEventName) {
      this.recordFinishedEventName = null;
    }

    this.setState({ isWaitingForRecordConfirmation: false });
  };

  stopWaitingDeleteConfirmation = () => {
    const { deleteFinishedEventName } = this;

    if (deleteFinishedEventName) {
      this.deleteFinishedEventName = null;
    }

    this.setState({ isWaitingForDeleteConfirmation: false });
  };

  handleStartRecordingButtonOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    const { isDebugModeEnabled } = this.props;
    const { altKey, ctrlKey } = event;

    event.preventDefault();
    event.stopPropagation();

    if (isDebugModeEnabled && (ctrlKey || altKey)) {
      this.showDebugInfo();
      return;
    }

    this.startRecording(false);
  };

  getPendingRecordKind = (): ?NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND => {
    const {
      item: { id },
      kind,
    } = this.props;

    if (typeof kind !== 'undefined') {
      // Record kind is known (Single or Series)
      return kind;
    }

    if (getItemType(id) === ItemType.Program) {
      // Single record button on card
      return NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SINGLE;
    }

    // Record button on series tile: user will be asked later
    return undefined;
  };

  startRecording = (isPendingOperation?: boolean, pendingRecordKind: ?NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND) => {
    const {
      broadcastStatus,
      cardDataUrlDefinition,
      channels,
      commercialOffers,
      defaultRights,
      isDebugModeEnabled,
      isRegisteredAsGuest,
      isSubscriptionFeatureEnabled,
      item,
      item: {
        selectedLocation: { id },
      },
      kind,
      localHideModal,
      localStartRecord,
      location,
      previewCatchupScheduledEventId,
      programMetadata,
      seriesMetadata,
      tileType,
      userRights,
    } = this.props;

    if (!programMetadata || !location) {
      return;
    }

    const recordKind = isPendingOperation && typeof pendingRecordKind === 'undefined' ? undefined : this.getPendingRecordKind();

    const cardData: CARD_DATA_MODAL_TYPE = {
      item,
      programMetadata,
      recordKind,
      seriesMetadata,
      tileType,
      urlDefinition: cardDataUrlDefinition,
    };

    if (!isRecordGranted(isRegisteredAsGuest, isSubscriptionFeatureEnabled, cardData, location, channels, commercialOffers, defaultRights, userRights)) {
      // Record denied: log-in or subscribe page has been requested
      localHideModal();
      return;
    }

    this.recordFinishedEventName = `RECORD_FINISHED_TILE_${id ?? 'no-location-id'}`;
    const { recordFinishedEventName: finishedOperationEventName } = this;
    Messenger.once(this.recordFinishedEventName, this.stopWaitingRecordConfirmation);

    this.setState({ isWaitingForRecordConfirmation: true }, () =>
      localStartRecord(
        {
          broadcastStatus,
          finishedOperationEventName,
          item,
          kind: isPendingOperation ? pendingRecordKind : kind,
          previewCatchupScheduledEventId,
          programMetadata,
          seriesMetadata,
        },
        isDebugModeEnabled,
      ),
    );
  };

  handleStopRecordingButtonOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    const {
      broadcastStatus,
      isDebugModeEnabled,
      item,
      item: {
        selectedLocation: { id },
      },
      kind,
      localStopRecord,
      previewCatchupScheduledEventId,
      programMetadata,
      seriesMetadata,
    } = this.props;
    const { hasSeriesScheduledRecording } = this.state;
    const { altKey, ctrlKey } = event;

    event.preventDefault();
    event.stopPropagation();

    if (isDebugModeEnabled && (ctrlKey || altKey)) {
      this.showDebugInfo();
      return;
    }

    if (!programMetadata) {
      return;
    }

    this.deleteFinishedEventName = `DELETE_FINISHED_TILE_${id ?? 'no-location-id'}`;
    const { deleteFinishedEventName: finishedOperationEventName } = this;
    Messenger.once(this.deleteFinishedEventName, this.stopWaitingDeleteConfirmation);

    const localKind = typeof kind === 'undefined' && broadcastStatus === BroadcastStatus.Live && hasSeriesScheduledRecording ? NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SERIES : kind;

    this.setState({ isWaitingForDeleteConfirmation: true }, () =>
      localStopRecord({
        broadcastStatus,
        finishedOperationEventName,
        item,
        kind: localKind,
        previewCatchupScheduledEventId,
        programMetadata,
        seriesMetadata,
      }),
    );
  };

  renderStartRecordButton = (): React.Element<any> => {
    const { context, isDisabled, kind, theme = Theme.Dark } = this.props;
    const { isWaitingForRecordConfirmation } = this.state;

    const recordKey = kind === NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SERIES ? 'common.actions.record_series' : 'common.actions.record';

    return (
      <ButtonFX
        heightKind={context === ContextKind.Card ? HeightKind.Large : HeightKind.Small}
        isDisabled={isDisabled}
        isLoading={isWaitingForRecordConfirmation}
        key='startRecording'
        onClick={this.handleStartRecordingButtonOnClick}
        theme={theme}
        widthKind={WidthKind.Stretched}
      >
        {Localizer.localize(recordKey)}
      </ButtonFX>
    );
  };

  renderStopRecordButton = (): React.Element<any> => {
    const {
      context,
      isDisabled,
      item: { locType },
      kind,
      theme = Theme.Dark,
    } = this.props;
    const { isWaitingForDeleteConfirmation } = this.state;

    let deleteKey = 'common.actions.delete_recording';
    if (locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP) {
      deleteKey = 'tv.actions.delete_keep_from_replay';
    } else if (kind === NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SERIES) {
      deleteKey = 'common.actions.delete_series_recording';
    }

    return (
      <ButtonFX
        heightKind={context === ContextKind.Card ? HeightKind.Large : HeightKind.Small}
        isDisabled={isDisabled}
        isLoading={isWaitingForDeleteConfirmation}
        key='stopRecording'
        onClick={this.handleStopRecordingButtonOnClick}
        theme={theme}
        widthKind={WidthKind.Stretched}
      >
        {Localizer.localize(deleteKey)}
      </ButtonFX>
    );
  };

  renderButton = (): React.Element<any> => {
    const { actionType } = this.state;

    return actionType === RECORD_BUTTON_ACTION_TYPE_RECORD ? this.renderStartRecordButton() : this.renderStopRecordButton();
  };

  renderStartRecordPicto = (): React.Element<any> => {
    const { isDisabled } = this.props;
    const { isWaitingForRecordConfirmation } = this.state;

    return <PictoRecordingStart className='recording' isDisabled={isDisabled || isWaitingForRecordConfirmation} key='startRecording' onClick={this.handleStartRecordingButtonOnClick} />;
  };

  renderStopRecordPicto = (): React.Element<any> => {
    const {
      isDisabled,
      item: { locType },
      kind,
    } = this.props;
    const { hasSeriesScheduledRecording, hasScheduledRecording, isWaitingForDeleteConfirmation } = this.state;

    const isDisabledAggregated =
      isDisabled ||
      isWaitingForDeleteConfirmation ||
      (locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT && kind === NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SINGLE && hasSeriesScheduledRecording && !hasScheduledRecording);

    return <PictoRecordingStop className='recording' isDisabled={isDisabledAggregated} key='stopRecording' onClick={this.handleStopRecordingButtonOnClick} />;
  };

  renderPicto = (): React.Element<any> => {
    const { actionType } = this.state;

    if (actionType === RECORD_BUTTON_ACTION_TYPE_NONE) {
      logError(`actionType: ${RECORD_BUTTON_ACTION_TYPE_NONE}`);
    }

    return actionType === RECORD_BUTTON_ACTION_TYPE_RECORD ? this.renderStartRecordPicto() : this.renderStopRecordPicto();
  };

  render(): React.Node {
    const { displayMode } = this.props;
    const { actionType } = this.state;

    if (actionType === RECORD_BUTTON_ACTION_TYPE_NONE) {
      return null;
    }

    return displayMode === DisplayMode.Button ? this.renderButton() : this.renderPicto();
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxRecordButtonReducerStateType => {
  return {
    channels: state.appConfiguration.deviceChannels,
    commercialOffers: state.appConfiguration.rightsCommercialOffers,
    defaultRights: state.appConfiguration.rightsDefault,
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
    isNpvrEnabled: state.appConfiguration.features[FEATURE_NPVR] && state.npvr.isEnabled,
    isRegisteredAsGuest: state.appRegistration.registration === RegistrationType.RegisteredAsGuest,
    isSubscriptionFeatureEnabled: state.appConfiguration.features[FEATURE_SUBSCRIPTION],
    npvrRecordingsFuture: state.npvr.npvrRecordingsFuture,
    npvrRecordingsList: state.npvr.npvrRecordingsList,
    npvrScheduledRecordingsList: state.npvr.npvrScheduledRecordingsList,
    userRights: state.appConfiguration.rightsUser,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxRecordButtonDispatchToPropsType => {
  return {
    localHideModal: () => dispatch(hideModal()),
    localStartRecord: (recordItem: RECORD_ITEM_TYPE, isDebugModeEnabled: boolean) => dispatch(startRecord(recordItem, isDebugModeEnabled)),
    localStopRecord: (recordItem: RECORD_ITEM_TYPE) => dispatch(stopRecord(recordItem)),
  };
};

const RecordButton: React.ComponentType<RecordButtonPropType> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RecordButtonView);

export default RecordButton;
