/* @flow */

import './ItemIndex.css';
import 'core-js/features/string/pad-start';
import * as React from 'react';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import type { CombinedReducers } from '../../../redux/reducers';
import type { ItemData } from './Types';
import { Localizer } from '@ntg/utils/dist/localization';
import { TIPPY_DEFAULT_OPTIONS } from '@ntg/ui/dist/tooltip';
import TextScroller from '../../textScroller/TextScroller';
import Tippy from '@tippyjs/react';
import { buildTitle } from '../../../helpers/ui/metadata/Format';
import clsx from 'clsx';
import { connect } from 'react-redux';

type ItemIndexPropType = {|
  +currentIndex: number,
  +index: number,
  +itemData: ItemData | null,
  +onClick: (index: number) => void,
|};

type ReduxItemIndexReducerStateType = {|
  +isDebugModeEnabled: boolean,
|};

type CompleteItemIndexPropType = {|
  ...ItemIndexPropType,
  ...ReduxItemIndexReducerStateType,
|};

type ItemIndexStateType = {|
  isTooltipShown: boolean,
  progress: number,
|};

const InitialState = Object.freeze({
  isTooltipShown: false,
  progress: 0,
});

const PADDING_LENGTH = 2;

class ItemIndexView extends React.PureComponent<CompleteItemIndexPropType, ItemIndexStateType> {
  constructor(props: CompleteItemIndexPropType) {
    super(props);

    this.state = { ...InitialState };
  }

  componentDidMount() {
    Messenger.on(MessengerEvents.VIDEO_CAROUSEL_UPDATE_PROGRESS, this.updateProgress);
  }

  componentDidUpdate(prevProps: CompleteItemIndexPropType) {
    const { currentIndex } = this.props;
    const { currentIndex: prevCurrentIndex } = prevProps;

    if (currentIndex !== prevCurrentIndex) {
      this.resetProgress();
    }
  }

  componentWillUnmount() {
    Messenger.off(MessengerEvents.VIDEO_CAROUSEL_UPDATE_PROGRESS, this.updateProgress);
  }

  updateProgress: (updateIndex: number, percent: number) => void = (updateIndex, percent) => {
    const { index } = this.props;

    if (index === updateIndex) {
      this.setState({ progress: percent });
    }
  };

  resetProgress: () => void = () => {
    this.setState({ progress: 0 });
  };

  handleOnClick: () => void = () => {
    const { index, onClick } = this.props;

    onClick(index);
  };

  renderTitleElement: () => React.Element<any> = () => {
    const { itemData } = this.props;

    if (!itemData) {
      return <div className='title'>&nbsp;</div>;
    }

    const { programMetadata, seriesMetadata } = itemData;
    const title = buildTitle(Localizer.language, programMetadata ?? null, seriesMetadata ?? null, true);

    return (
      <div className='titleContainer'>
        <TextScroller scrollOnMount style={{ font: '14px var(--regular-font)', maxHeight: '16px' }}>
          <div className='title'>{title}</div>
        </TextScroller>
      </div>
    );
  };

  render(): React.Node {
    const { currentIndex, index, isDebugModeEnabled, itemData } = this.props;
    const { isTooltipShown, progress } = this.state;

    const indexText = (index + 1).toString().padStart(PADDING_LENGTH, '0');
    const progressStyle = { width: `${progress}%` };

    const options = {
      ...TIPPY_DEFAULT_OPTIONS,
      content: isTooltipShown ? (
        <div className={clsx('tooltipContent', 'carouselIndex', itemData?.tileType)}>
          <img alt='' className={itemData?.tileType ?? ''} src={itemData?.imageUrl ?? ''} />
          {this.renderTitleElement()}
        </div>
      ) : null,
      onHidden: () => this.setState({ isTooltipShown: false }),
      onShown: () => this.setState({ isTooltipShown: true }),
    };

    const indexElement = (
      <div className={clsx('itemIndex', index === currentIndex && 'focused')} onClick={this.handleOnClick}>
        <div className='videoProgressContainer'>
          <div className='videoProgress' style={progressStyle} />
        </div>
        <div className='index'>
          <p>{indexText}</p>
        </div>
      </div>
    );

    if (isDebugModeEnabled) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Tippy {...options}>{indexElement}</Tippy>;
    }

    return indexElement;
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxItemIndexReducerStateType => {
  return {
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
  };
};

const ItemIndex: React.ComponentType<ItemIndexPropType> = connect(mapStateToProps, null, null, { forwardRef: true })(ItemIndexView);

export default ItemIndex;
