/* @flow */

import './KeyboardShortcutsModal.css';
import * as React from 'react';
import { FEATURE_TV, type FEATURE_TYPE } from '../../../redux/appConf/constants';
import type { AppConfigurationFeatures } from '../../../redux/appConf/types/types';
import { type CombinedReducers } from '../../../redux/reducers';
import { Localizer } from '@ntg/utils/dist/localization';
import Modal from '../modal';
import { ModalIcon } from '../modalTypes';
import { type ModalState } from '../../../redux/modal/reducers';
import { connect } from 'react-redux';

type ShortcutTrigger = {|
  altKey?: boolean,
  ctrlKey?: boolean,
  key: string,
  shiftKey?: boolean,
|};

type Shortcut = {|
  action: string,
  feature?: FEATURE_TYPE,
  id: string,
  trigger: ShortcutTrigger,
|};

const PLAYER_SHORTCUT_LIST: Array<Shortcut> = Object.freeze([
  {
    action: 'modal.keyboard_shortcuts.toggle_play',
    id: 'ptp',
    trigger: { key: 'key.space' },
  },
  {
    action: 'modal.keyboard_shortcuts.rewind_10seconds',
    id: 'prw10',
    trigger: { key: 'LEFT_ARROW' },
  },
  {
    action: 'modal.keyboard_shortcuts.rewind_30seconds',
    id: 'prw30',
    trigger: {
      ctrlKey: true,
      key: 'LEFT_ARROW',
    },
  },
  {
    action: 'modal.keyboard_shortcuts.rewind_60seconds',
    id: 'prw60',
    trigger: {
      key: 'LEFT_ARROW',
      shiftKey: true,
    },
  },
  {
    action: 'modal.keyboard_shortcuts.forward_10seconds',
    id: 'pfw10',
    trigger: { key: 'RIGHT_ARROW' },
  },
  {
    action: 'modal.keyboard_shortcuts.forward_30seconds',
    id: 'pfw30',
    trigger: {
      ctrlKey: true,
      key: 'RIGHT_ARROW',
    },
  },
  {
    action: 'modal.keyboard_shortcuts.forward_60seconds',
    id: 'pfw60',
    trigger: {
      key: 'RIGHT_ARROW',
      shiftKey: true,
    },
  },
  {
    action: 'modal.keyboard_shortcuts.toggle_sound',
    id: 'pts',
    trigger: { key: 'M' },
  },
  {
    action: 'modal.keyboard_shortcuts.decrease_volume',
    id: 'pdv',
    trigger: { key: '-' },
  },
  {
    action: 'modal.keyboard_shortcuts.increase_volume',
    id: 'piv',
    trigger: { key: '+' },
  },
  {
    action: 'modal.keyboard_shortcuts.toggle_fullscreen',
    id: 'ptf',
    trigger: { key: 'F' },
  },
  {
    action: 'modal.keyboard_shortcuts.show_info',
    id: 'psi',
    trigger: { key: 'I' },
  },
  {
    action: 'modal.keyboard_shortcuts.hide_controller',
    id: 'phc',
    trigger: { key: 'H' },
  },
  {
    action: 'modal.keyboard_shortcuts.close_player',
    id: 'pcp',
    trigger: { key: 'key.escape' },
  },
  {
    action: 'modal.keyboard_shortcuts.previous_channel',
    feature: FEATURE_TV,
    id: 'ppc',
    trigger: { key: 'DOWN_ARROW' },
  },
  {
    action: 'modal.keyboard_shortcuts.next_channel',
    feature: FEATURE_TV,
    id: 'pnc',
    trigger: { key: 'UP_ARROW' },
  },
  {
    action: 'modal.keyboard_shortcuts.back_to_live',
    feature: FEATURE_TV,
    id: 'pbl',
    trigger: { key: 'L' },
  },
]);

const TRAILER_SHORTCUT_LIST: Array<Shortcut> = Object.freeze([
  {
    action: 'modal.keyboard_shortcuts.toggle_play',
    id: 'ttp',
    trigger: { key: 'key.space' },
  },
  {
    action: 'modal.keyboard_shortcuts.rewind_10seconds',
    id: 'trw',
    trigger: { key: 'LEFT_ARROW' },
  },
  {
    action: 'modal.keyboard_shortcuts.forward_10seconds',
    id: 'tfw',
    trigger: { key: 'RIGHT_ARROW' },
  },
  {
    action: 'modal.keyboard_shortcuts.toggle_sound',
    id: 'tts',
    trigger: { key: 'M' },
  },
  {
    action: 'modal.keyboard_shortcuts.toggle_fullscreen',
    id: 'ttf',
    trigger: { key: 'F' },
  },
  {
    action: 'modal.keyboard_shortcuts.exit_fullscreen',
    id: 'tcp',
    trigger: { key: 'key.escape' },
  },
]);

type ReduxKeyboardShortcutsModalReducerStateType = {|
  +features: AppConfigurationFeatures,
|};

type CompleteKeyboardShortcutsModalPropType = {|
  ...ReduxKeyboardShortcutsModalReducerStateType,
  ...ModalState,
|};

class KeyboardShortcutsModalView extends React.PureComponent<CompleteKeyboardShortcutsModalPropType> {
  getKeyRepresentation = (key: string): string => {
    const localizedKey = Localizer.localize(key);

    switch (key) {
      case 'DOWN_ARROW':
        return '\u2193';
      case 'LEFT_ARROW':
        return '\u2190';
      case 'RIGHT_ARROW':
        return '\u2192';
      case 'UP_ARROW':
        return '\u2191';
      default:
        return localizedKey !== key ? localizedKey : key;
    }
  };

  renderShortcutTrigger = (trigger: ShortcutTrigger): React.Element<any> => {
    const { altKey, ctrlKey, key, shiftKey } = trigger;

    const keys = [];
    if (altKey) {
      keys.push('Alt');
    }
    if (ctrlKey) {
      keys.push('Ctrl');
    }
    if (shiftKey) {
      keys.push('Shift');
    }

    keys.push(this.getKeyRepresentation(key));

    return (
      <>
        {keys.map((k) => (
          <span key={k}>{k}</span>
        ))}
      </>
    );
  };

  renderShortcut = (shortcut: Shortcut): React.Element<any> | null => {
    const { features } = this.props;
    const { action, feature, id, trigger } = shortcut;

    if (feature && !features[feature]) {
      return null;
    }

    return (
      <li key={id}>
        <div className='shortcut'>{this.renderShortcutTrigger(trigger)}</div>
        <div className='action'>{Localizer.localize(action)}</div>
      </li>
    );
  };

  renderShortcutList = (titleKey: string, list: Array<Shortcut>): React.Element<any> => (
    <div className='column'>
      <div className='sectionTitle'>{Localizer.localize(titleKey)}</div>
      <ul>{list.map((shortcut) => this.renderShortcut(shortcut))}</ul>
    </div>
  );

  render(): React.Node {
    const { index } = this.props;

    const children = (
      <>
        <div className='header'>{Localizer.localize('modal.keyboard_shortcuts.title')}</div>
        <div className='keyboardShortcutsContent'>
          {this.renderShortcutList('modal.keyboard_shortcuts.header.player', PLAYER_SHORTCUT_LIST)}
          {this.renderShortcutList('modal.keyboard_shortcuts.header.trailer', TRAILER_SHORTCUT_LIST)}
        </div>
      </>
    );

    return (
      <Modal className='keyboardShortcuts' icon={ModalIcon.Info} index={index}>
        {children}
      </Modal>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxKeyboardShortcutsModalReducerStateType => {
  return {
    features: state.appConfiguration.features,
  };
};

const KeyboardShortcutsModal: React.ComponentType<ModalState> = connect(mapStateToProps, null, null, { forwardRef: true })(KeyboardShortcutsModalView);

export default KeyboardShortcutsModal;
