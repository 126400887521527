/* @flow */

import '../ProgressBar.css';
import './StandardProgressBar.css';
import * as React from 'react';
import { getBoundedValue, getRealPercentage } from '../../../../../helpers/maths/maths';
import { getDurationDisplay, getTimeRangesAsString, getTimestampDisplay, showDebug } from '../../../../../helpers/debug/debug';
import AccurateTimestamp from '../../../../../helpers/dateTime/AccurateTimestamp';
import { DRAG_TIMEOUT_DELAY } from '../Common';
import { PictoDot } from '@ntg/components/dist/pictos/Element';
import { formatSecondsToHHMMSS } from '../../../../../helpers/dateTime/Format';

/*
 * Role of the props:
 *
 * Case 1: program fully recorded
 * +-------------------------+---+-----------------------+---+---------------------+
 * +-------- startMargin --------+        program        +------- endMargin -------+
 * +-- userViewStartOffset --+        user view window       +- userViewEndOffset -+
 *
 * Case 2: program partially recorded
 * +---------------------+---+---------------------------+---+---------------------+
 * +---- startMargin ----+                program        +------- endMargin -------+
 * +-- userViewStartOffset --+        user view window       +- userViewEndOffset -+
 */

/*
 * To avoid the case where the progress bar is mostly filled with margins (think 5-minute programs),
 * start and end margins always represent 10% and 20% of the progress bar
 */
const PERCENT_START_MARGIN = 0.1;
const PERCENT_END_MARGIN = 0.2;

// Small amount of time used to compare target time to now when navigating in an in-progress recording (in seconds)
const LIVE_DELTA = 5;

type PartsWidth = {|
  contentWidth: number,
  endMarginWidth: number,
  startMarginWidth: number,
|};

type StandardProgressBarPropType = {|
  +bufferedTimeRanges: TimeRanges | null,
  +duration: number,
  +endMargin: number,
  +endTime: number,
  +isLiveRecording: boolean,
  +onPlayingInMargin: (value: boolean) => void,
  +onSeekChanged: (time: number) => void,
  +playheadPosition: number,
  +realEnd: number,
  +realStart: number,
  +startMargin: number,
  +startTime: number,
  +totalDuration: number,
  +userViewEndOffset: number,
  +userViewStartOffset: number,
|};

type StandardProgressBarStateType = {|
  availableBarWidth: string | null,
  bufferedBarWidth: number | null,
  hoverPosition: number,
  hoverTime: string | null,
  isHoveringMargin: boolean,
  isThumbDragged: boolean,
  progressBarWidth: number | null,
|};

const InitialState = Object.freeze({
  availableBarWidth: null,
  bufferedBarWidth: null,
  hoverPosition: 0,
  hoverTime: null,
  isHoveringMargin: false,
  isThumbDragged: false,
  progressBarWidth: null,
});

class StandardProgressBar extends React.PureComponent<StandardProgressBarPropType, StandardProgressBarStateType> {
  dragSeekPosition: number;

  dragTimer: TimeoutID | null;

  mainContainer: HTMLElement | null;

  // Only used when dragging progress bar thumb
  realDuration: number;

  constructor(props: StandardProgressBarPropType) {
    super(props);

    this.dragSeekPosition = 0;
    this.dragTimer = null;
    this.mainContainer = null;
    this.realDuration = 0;

    this.state = { ...InitialState };
  }

  componentDidUpdate(prevProps: StandardProgressBarPropType) {
    const { bufferedTimeRanges, duration, endMargin, playheadPosition, startMargin, totalDuration, userViewEndOffset, userViewStartOffset } = this.props;
    const {
      bufferedTimeRanges: prevBufferedTimeRanges,
      duration: prevDuration,
      endMargin: prevEndMargin,
      playheadPosition: prevPlayheadPosition,
      startMargin: prevStartMargin,
      totalDuration: prevTotalDuration,
      userViewEndOffset: prevUserViewEndOffset,
      userViewStartOffset: prevUserViewStartOffset,
    } = prevProps;

    if (
      bufferedTimeRanges === prevBufferedTimeRanges &&
      playheadPosition === prevPlayheadPosition &&
      duration === prevDuration &&
      endMargin === prevEndMargin &&
      startMargin === prevStartMargin &&
      totalDuration === prevTotalDuration &&
      userViewEndOffset === prevUserViewEndOffset &&
      userViewStartOffset === prevUserViewStartOffset
    ) {
      return;
    }

    // Duration actually used to draw the progress bar
    const realDuration = this.getRealDuration();

    this.updateProgressBar(realDuration);

    // Available part of the video
    this.updateAvailableBar(realDuration);

    // Buffered part of the video
    this.updateBufferedBar(realDuration);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleThumbMouseMove, { capture: true });
    window.removeEventListener('mouseup', this.handleThumbMouseUp, { capture: true });
    this.resetDragTimer();
  }

  showDebugInfo = () => {
    const {
      props,
      props: { bufferedTimeRanges, duration, endMargin, endTime, playheadPosition, realEnd, realStart, startMargin, startTime, totalDuration, userViewEndOffset, userViewStartOffset },
      realDuration,
      state,
    } = this;

    showDebug('Standard progress bar', {
      instance: this,
      instanceFields: ['dragSeekPosition'],
      misc: {
        bufferedTimeRanges: getTimeRangesAsString(bufferedTimeRanges),
        duration: getDurationDisplay(duration),
        endMargin: getDurationDisplay(endMargin),
        endTime: getTimestampDisplay(endTime),
        playheadPosition: getTimestampDisplay(playheadPosition),
        realDuration: getDurationDisplay(realDuration),
        realEnd: getTimestampDisplay(realEnd),
        realStart: getTimestampDisplay(realStart),
        startMargin: getDurationDisplay(startMargin),
        startTime: getTimestampDisplay(startTime),
        totalDuration: getDurationDisplay(totalDuration),
        userViewEndOffset: getDurationDisplay(userViewEndOffset),
        userViewStartOffset: getDurationDisplay(userViewStartOffset),
      },
      props,
      propsFields: ['isLiveRecording'],
      state,
      stateFields: ['availableBarWidth', 'bufferedBarWidth', 'hoverPosition', 'hoverTime', 'isHoveringMargin', 'isThumbDragged', 'progressBarWidth'],
    });
  };

  resetDragTimer: () => void = () => {
    if (this.dragTimer) {
      clearTimeout(this.dragTimer);
      this.dragTimer = null;
    }
  };

  getRealDuration = (): number => {
    const { duration, totalDuration } = this.props;

    if (duration > 0 && duration < Infinity) {
      // Real duration set by the player, including margins
      return duration;
    }

    if (totalDuration > 0) {
      // Duration from metadata where margins have been added
      return totalDuration;
    }

    return 0;
  };

  updateProgressBar = (realDuration: number): void => {
    const { playheadPosition, endMargin, onPlayingInMargin, startMargin } = this.props;
    const { isThumbDragged } = this.state;

    if (isThumbDragged) {
      return;
    }

    let progressBarWidth: ?number = null;

    if (realDuration > 0) {
      onPlayingInMargin(playheadPosition < startMargin || playheadPosition > realDuration - endMargin);

      progressBarWidth = this.calculateProgressWidth(playheadPosition, realDuration);
    }

    this.setState({ progressBarWidth });
  };

  updateAvailableBar = (realDuration: number): void => {
    const { duration, endMargin, endTime, isLiveRecording, startMargin, startTime, userViewEndOffset, userViewStartOffset } = this.props;

    const localDuration = duration < Infinity ? duration : realDuration;
    let availableBarWidth = localDuration > 0 ? '100%' : null;

    if (isLiveRecording) {
      // In-progress recording: available bar is limited by current time
      const partsWidth = this.calculatePartsWidth();

      if (!partsWidth) {
        return;
      }

      const { contentWidth, endMarginWidth, startMarginWidth } = partsWidth;

      const now = AccurateTimestamp.nowInSeconds();

      const viewableEndMarginDuration = endMargin - userViewEndOffset;
      if (now < endTime + viewableEndMarginDuration) {
        // In-progress recording and current time is within the user view window
        let adjustedWidth = startMarginWidth;

        if (now <= endTime) {
          // Before program end
          const missedContent = userViewStartOffset - startMargin;
          adjustedWidth += contentWidth * getRealPercentage(now, startTime + (missedContent > 0 ? missedContent : 0), endTime);
        } else {
          // In end margin
          adjustedWidth += contentWidth + endMarginWidth * getRealPercentage(now, endTime, endTime + viewableEndMarginDuration);
        }

        availableBarWidth = `${adjustedWidth}px`;
      }
    }

    this.setState({ availableBarWidth });
  };

  updateBufferedBar = (realDuration: number): void => {
    const { bufferedTimeRanges, playheadPosition, endMargin, startMargin } = this.props;

    let bufferedBarWidth = 0;

    if (realDuration > 0 && bufferedTimeRanges && bufferedTimeRanges.length > 0 && startMargin <= playheadPosition && playheadPosition <= realDuration - endMargin) {
      let end = bufferedTimeRanges.end(bufferedTimeRanges.length - 1);

      if (end > realDuration - endMargin) {
        // Sometimes buffered time ranges returns wrong value (very high)
        end = realDuration - endMargin;
      }

      bufferedBarWidth = this.calculateProgressWidth(end, realDuration);
    }

    this.setState({ bufferedBarWidth });
  };

  // Margins are included in 'duration'
  calculateProgressWidth = (position: number, realDuration: number): number => {
    const { endMargin, realEnd, realStart, startMargin, userViewEndOffset, userViewStartOffset } = this.props;
    const { mainContainer } = this;

    const mainContainerWidth = mainContainer?.offsetWidth ?? 0;

    let width = 0;

    if (position < userViewStartOffset) {
      // Before userView: width = 0
    } else if (position < startMargin) {
      // Inside the first 10%
      const relativePosition = position - userViewStartOffset;
      const relativeDuration = startMargin - userViewStartOffset;
      width = (mainContainerWidth * PERCENT_START_MARGIN * relativePosition) / relativeDuration;
    } else if (position > realDuration - endMargin) {
      // Inside the last 20%
      const relativePosition = position - (realDuration - endMargin);
      const relativeDuration = endMargin - userViewEndOffset;
      width = mainContainerWidth * (1 - PERCENT_END_MARGIN);
      width += (mainContainerWidth * PERCENT_END_MARGIN * relativePosition) / relativeDuration;
    } else {
      // Inside the middle 70%
      const relativePosition = position - realStart;
      const relativeDuration = realDuration - (realStart + realEnd);
      let relativeWidth = mainContainerWidth;

      if (userViewStartOffset <= startMargin && startMargin > 0) {
        // Start margin is only displayed when it's greater than userViewStartOffset
        relativeWidth -= mainContainerWidth * PERCENT_START_MARGIN;
        width += mainContainerWidth * PERCENT_START_MARGIN;
      }
      if (userViewEndOffset <= endMargin && endMargin > 0) {
        // End margin is only displayed when it's greater than userViewEndOffset
        relativeWidth -= mainContainerWidth * PERCENT_END_MARGIN;
      }
      width += (relativeWidth * relativePosition) / relativeDuration;
    }

    // Ensure returned width stays valid
    return getBoundedValue(width, 0, mainContainerWidth);
  };

  calculatePartsWidth = (): PartsWidth | null => {
    const { endMargin, startMargin, userViewEndOffset, userViewStartOffset } = this.props;
    const { mainContainer } = this;

    if (!mainContainer) {
      return null;
    }

    const mainContainerWidth = mainContainer.offsetWidth;
    const startMarginWidth = startMargin > 0 && startMargin >= userViewStartOffset ? mainContainerWidth * PERCENT_START_MARGIN : 0;
    const endMarginWidth = endMargin > 0 && endMargin >= userViewEndOffset ? mainContainerWidth * PERCENT_END_MARGIN : 0;
    const contentWidth = mainContainerWidth - startMarginWidth - endMarginWidth;

    return {
      contentWidth,
      endMarginWidth,
      startMarginWidth,
    };
  };

  getRelativeMousePosition = (mouseX: number): number => {
    const { mainContainer } = this;

    if (!mainContainer) {
      return mouseX;
    }

    const { left, width } = mainContainer.getBoundingClientRect();
    const relativePosition = mouseX - left;

    if (relativePosition < 0) {
      return 0;
    }

    if (relativePosition > width) {
      return width;
    }

    return relativePosition;
  };

  calculateTimeFromMousePosition = (mousePos: number): number => {
    const { duration, endMargin, realStart, startMargin, userViewEndOffset, userViewStartOffset } = this.props;
    const partsWidth = this.calculatePartsWidth();

    if (!partsWidth) {
      return 0;
    }

    const { contentWidth, endMarginWidth, startMarginWidth } = partsWidth;

    const programDuration = duration - realStart - endMargin;
    const startMarginDuration = Math.max(0, startMargin - userViewStartOffset);
    const endMarginDuration = Math.max(0, endMargin - userViewEndOffset);

    let time = 0;
    let isHoveringMargin = true;

    if (mousePos < startMarginWidth) {
      // Mouse is over start margin
      time = (mousePos * startMarginDuration) / startMarginWidth;
    } else if (mousePos > startMarginWidth + contentWidth) {
      // Mouse is over end margin
      const endMousePos = mousePos - startMarginWidth - contentWidth;
      time = startMarginDuration + programDuration + (endMousePos * endMarginDuration) / endMarginWidth;
    } else {
      // Mouse is between margins
      isHoveringMargin = false;
      const mousePosInProgram = mousePos - startMarginWidth;
      time = startMarginDuration + (mousePosInProgram * programDuration) / contentWidth;
    }

    this.setState({ isHoveringMargin });
    return time;
  };

  handleMainContainerOnClick = (event: SyntheticMouseEvent<HTMLElement>): void => {
    const { onSeekChanged, userViewStartOffset } = this.props;
    const { altKey, clientX, ctrlKey } = event;

    if (ctrlKey || altKey) {
      this.showDebugInfo();
      return;
    }

    const mousePos = this.getRelativeMousePosition(clientX);
    onSeekChanged(this.calculateTimeFromMousePosition(mousePos) + userViewStartOffset);
  };

  handleMouseMove = (event: SyntheticMouseEvent<HTMLElement>): void => {
    const { clientX } = event;

    this.displayTimeBadge(this.getRelativeMousePosition(clientX));
  };

  displayTimeBadge = (mousePosition: number): void => {
    const { startMargin, userViewStartOffset } = this.props;

    const startMarginDuration = Math.max(0, startMargin - userViewStartOffset);
    this.setState({
      hoverPosition: mousePosition,
      hoverTime: formatSecondsToHHMMSS(this.calculateTimeFromMousePosition(mousePosition) - startMarginDuration),
    });
  };

  handleMouseLeave: () => void = () => {
    this.setState({
      hoverPosition: 0,
      hoverTime: null,
    });
  };

  handleThumbMouseDown = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isThumbDragged: true });
    this.realDuration = this.getRealDuration();

    window.addEventListener('mousemove', this.handleThumbMouseMove, { capture: true });
    window.addEventListener('mouseup', this.handleThumbMouseUp, { capture: true });
  };

  handleThumbMouseMove = (event: SyntheticMouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    const { startMargin, startTime, userViewStartOffset } = this.props;
    const { isThumbDragged } = this.state;
    const { realDuration } = this;
    const { clientX } = event;

    if (!isThumbDragged) {
      return;
    }

    const mousePosition = this.getRelativeMousePosition(clientX);
    const mouseTime = this.calculateTimeFromMousePosition(mousePosition);
    const now = AccurateTimestamp.nowInSeconds();
    let absoluteTime = startTime + mouseTime;

    const missedContent = userViewStartOffset - startMargin;
    if (missedContent > 0) {
      // Partial recording
      absoluteTime += missedContent;
    } else {
      // Full recording
      absoluteTime -= missedContent;
    }

    if (absoluteTime + LIVE_DELTA > now) {
      // Cannot move beyond live (obviously)
      return;
    }

    this.dragSeekPosition = mouseTime + userViewStartOffset;

    if (realDuration > 0) {
      const progressBarWidth = this.calculateProgressWidth(this.dragSeekPosition, realDuration);
      this.setState({ progressBarWidth });
    }

    // Throttling: only the last seek of each 300ms window will be performed
    if (!this.dragTimer) {
      this.dragTimer = setTimeout(this.performSeek, DRAG_TIMEOUT_DELAY);
    }

    this.displayTimeBadge(mousePosition);
  };

  performSeek: () => void = () => {
    const { onSeekChanged } = this.props;
    const { dragSeekPosition } = this;

    this.resetDragTimer();
    onSeekChanged(dragSeekPosition);
  };

  handleThumbMouseUp = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    if (this.dragTimer) {
      this.resetDragTimer();
      this.performSeek();
    }

    this.setState({ isThumbDragged: false });
    this.realDuration = 0;

    window.removeEventListener('mousemove', this.handleThumbMouseMove, { capture: true });
    window.removeEventListener('mouseup', this.handleThumbMouseUp, { capture: true });
  };

  renderAvailableBar = (): React.Element<any> | null => {
    const { availableBarWidth } = this.state;

    if (!availableBarWidth) {
      return null;
    }

    return <div className='available' style={{ width: availableBarWidth }} />;
  };

  renderBufferedBar = (): React.Element<any> | null => {
    const { bufferedBarWidth } = this.state;

    if (!bufferedBarWidth) {
      return null;
    }

    return <div className='buffered' style={{ width: `${bufferedBarWidth}px` }} />;
  };

  renderProgressBar = (): React.Element<any> | null => {
    const { isThumbDragged, progressBarWidth } = this.state;

    if (!progressBarWidth) {
      return null;
    }

    return (
      <div className='progress' style={{ width: `${progressBarWidth}px` }}>
        <PictoDot className={`thumb ${isThumbDragged ? 'dragged' : ''}`} draggable hasHoverEffect={false} onMouseDown={this.handleThumbMouseDown} />
      </div>
    );
  };

  renderTimeBadge = (): React.Element<any> | null => {
    const { hoverPosition, hoverTime, isHoveringMargin } = this.state;

    if (!hoverTime) {
      return null;
    }

    const translate = `translateX(calc(${hoverPosition}px - 50%))`;

    return (
      <div className={`timeBadge ${isHoveringMargin ? 'inMargin' : ''}`} draggable={false} style={{ transform: translate }}>
        {hoverTime}
      </div>
    );
  };

  render(): React.Node {
    const { isLiveRecording } = this.props;

    return (
      <div className='progressBar standard'>
        <div className='reactiveBackground'>
          <div
            className={`mainContainer ${!isLiveRecording ? 'allowed' : ''}`}
            onClick={this.handleMainContainerOnClick}
            onMouseLeave={this.handleMouseLeave}
            onMouseMove={this.handleMouseMove}
            ref={(instance) => {
              this.mainContainer = instance;
            }}
          >
            {this.renderAvailableBar()}
            {this.renderBufferedBar()}
            {this.renderProgressBar()}
            {this.renderTimeBadge()}
          </div>
        </div>
      </div>
    );
  }
}

export default (StandardProgressBar: React.ComponentType<StandardProgressBarPropType>);
