/* @flow */

import { type NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_ENTITLEMENT_PARAM, StreamType } from '../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import { Drm } from '../../../helpers/jsHelpers/Drm';
import { type NETGEM_API_V8_HEADERS } from '../../../libs/netgemLibrary/v8/types/Headers';
import { type NETGEM_API_V8_URL_DEFINITION } from '../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import type { ShakaMetrics } from './shakaTypes';

export enum SubtitlesMimeType {
  TTML = 'application/ttml+xml',
  WebVTT = 'text/vtt',
}

export enum VideoPlayerMediaType {
  Audio = 'audio',
  Subtitles = 'subtitles',
  Video = 'video',
}

export const VIDEOPLAYER_ERRORS = Object.freeze({
  // DRM
  VD001DrmGenericError: 'VD001',
  VD002DrmNotSupportedError: 'VD002',
  // Platform
  VP001MetadataLocationRequestError: 'VP001',
  VP002NtgEntitlementRequestError: 'VP002',
  VP003MetadataRecordingsRequestError: 'VP003',
  VP004NtgEntitlementNoSubscriptionError: 'VP004',
  VP005NtgEntitlementReleaseError: 'VP005',
  // Stream
  VS001NoStreamSupported: 'VS001',
  VS002StreamNotFound: 'VS002',
  VS004StreamRequestPending: 'VS004',
  VS005StreamStopError: 'VS005',
  VS006ManifestError: 'VS006',
  // Other
  VS995SessionTimeOut: 'VS995',
  VS996StartupCancelled: 'VS996',
  VS997NoTrailerError: 'VS997',
  VS998InternalError: 'VS998',
  VS999StreamUnknownError: 'VS999',
});

export type VideoPlayerInitData = {|
  audioLanguage?: string,
  channelId?: string,
  customHeaders?: NETGEM_API_V8_HEADERS,
  drm?: Drm,
  fairplayCertificateUrl?: string | null,
  laUrl?: string,
  manifestUpdatePeriod?: number,
  // In Mbps (undefined means unlimited bitrate)
  maxBitrate?: number,
  ntgEntitlement?: NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_ENTITLEMENT_PARAM,
  subtitlesLanguage?: string,
  trackingStart?: NETGEM_API_V8_URL_DEFINITION,
  type: StreamType,
  url: string,
  vuDrmToken?: string,
|};

export type VideoPlayerMediaInfo = {|
  accessibility?: Array<string>,
  autoselect?: boolean,
  bitrateList?: Array<any>,
  codec?: string,
  default?: boolean,
  forced?: boolean,
  id?: number,
  // Internal index
  index: number,
  kind?: string,
  // ISO language
  lang: string,
  mimeType?: string,
  name?: string,
  roles?: Array<string>,
  // Can be 'audio', 'video', 'text' or empty string for subtitles (Dash), or 'AUDIO', 'VIDEO' or 'SUBTITLES' (Hls)
  type: string,
|};

export type VideoPlayerExternalSubtitles = {|
  captions?: boolean,
  format?: string,
  language: string,
  mimeType?: SubtitlesMimeType,
  url: string,
|};

export type VideoPlayerBitrateInfo = {|
  bitrate: number,
  height: number,
  mediaType: VideoPlayerMediaType,
  qualityIndex: number,
  scanType: ?string,
  width: number,
|};

export type VideoPlayerPlaybackInfo = {|
  timeToEnd: number,
  time: number,
  type: string,
|};

export type VideoPlayerStreamInfo = {|
  duration: number,
  id?: string,
  index?: number,
  isLast?: boolean,
  manifestInfo?: {},
  start?: number,
|};

export type VideoPlayerStreamInitialized = {|
  error?: Error,
  streamInfo: VideoPlayerStreamInfo,
  type?: string,
|};

export type VideoPlayerBufferEvent = {|
  mediaType: VideoPlayerMediaType,
|};

export type VideoPlayerQualityChangeRendered = {|
  mediaType: VideoPlayerMediaType,
  newQuality: 3,
  oldQuality: 0,
  type: string,
|};

export type VideoPlayerBufferMetrics = {|
  level: number,
  state: string,
  target: number,
|};

export type VideoPlayerHtmlLiveMetrics = {|
  audioBitrate?: number | null,
  videoBitrate?: number | null,
|};

export type VideoPlayerLiveMetrics = {|
  shakaMetrics: ShakaMetrics,
|};

export type VideoPlayerCommonMetrics = {|
  bufferChunkCount?: number,
  bufferLength?: number,
  elementHeight?: number,
  elementWidth?: number,
  playbackRate?: number,
  videoHeight?: number,
  videoWidth?: number,
|};

export type VideoPlayerDebugInfo = {|
  bufferChunkCount?: number,
  bufferLength?: number,
  elementHeight?: number,
  elementWidth?: number,
  isPlayheadPositionFallback: boolean,
  laUrl: string,
  playbackRate?: number,
  playerName: string,
  playerVersion: string,
  shakaMetrics?: ShakaMetrics,
  streamType?: StreamType,
  streamUrl: string,
  state: string,
  time: number,
  totalTime: number,
  url?: string,
  videoHeight?: number,
  videoWidth?: number,
|};

export const VIDEOPLAYER_NAME = 'Shaka';

export const VIDEOPLAYER_DEBUG = Object.freeze({
  BufferFlushed: 'flushed',
  BufferLoaded: 'loaded',
  BufferStalled: 'stalled',
  NoKeepAlive: 'none',
  NotApplicable: 'n/a',
  StateEnded: 'ended',
  StateInitializing: 'initializing',
  StatePaused: 'paused',
  StatePlaying: 'playing',
  UnknownMetric: '<unknown>',
});

export const VIDEOPLAYER_BUFFER_DEFAULT_METRICS = Object.freeze({
  level: 0,
  state: VIDEOPLAYER_DEBUG.UnknownMetric,
  target: 0,
});

// Apple WebKit errors (see https://developer.apple.com/documentation/webkitjs/mediaerror)
/* eslint-disable no-magic-numbers */
export enum HtmlMediaError {
  // Added to handle undefined cast
  Unknown = 0,
  // MEDIA_ERR_ABORTED
  Aborted = 1,
  // MEDIA_ERR_NETWORK
  Network = 2,
  // MEDIA_ERR_DECODE
  Decode = 3,
  // MEDIA_ERR_SRC_NOT_SUPPORTED
  SrcNotSupported = 4,
}

export type MediaKeyEvent = {|
  +initData: Uint8Array,
  +initDataType: string,
|};

export type MediaKeyMessageEvent = {|
  +message: ArrayBuffer,
|};
