/* @flow */

import './Section.css';
import * as React from 'react';
import type { NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE, TILE_CONFIG_TYPE } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import { getTileConfig, getTileTypeClass } from '../../../helpers/ui/section/tile';
import type { ChannelMap } from '../../../libs/netgemLibrary/v8/types/Channel';
import type { CombinedReducers } from '../../../redux/reducers';
import Item from '../item/Item';
import { Localizer } from '@ntg/utils/dist/localization';
import { type NETGEM_API_V8_SECTION } from '../../../libs/netgemLibrary/v8/types/Section';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { createItemFromChannel } from '../../../helpers/ui/item/channel';
import getTranslatedText from '../../../libs/netgemLibrary/v8/helpers/Lang';

type ReduxSectionGridDispatchToPropsType = {||};

type ReduxSectionGridReducerStateType = {|
  +channels: ChannelMap,
|};

type DefaultProps = {|
  +isInExploreModal?: boolean,
  +onItemClick?: NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE,
|};

type SectionGridPropType = {|
  ...DefaultProps,
  +section: NETGEM_API_V8_SECTION,
|};

type CompleteSectionGridPropType = {|
  ...SectionGridPropType,
  ...ReduxSectionGridReducerStateType,
  ...ReduxSectionGridDispatchToPropsType,
|};

type SectionGridStateType = {|
  items: Array<React.Element<typeof Item>>,
|};

class SectionGridView extends React.PureComponent<CompleteSectionGridPropType, SectionGridStateType> {
  sectionName: string;

  tileConfig: TILE_CONFIG_TYPE;

  static defaultProps: DefaultProps = {
    isInExploreModal: false,
    onItemClick: undefined,
  };

  constructor(props: CompleteSectionGridPropType) {
    super(props);

    const {
      section,
      section: { id },
    } = props;

    this.sectionName = id;
    this.tileConfig = getTileConfig(section);

    this.state = { items: [] };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { channels, isInExploreModal, onItemClick } = this.props;
    const { tileConfig } = this;
    const items = [];

    const sortedChannels = Object.keys(channels)
      .map((key) => channels[key])
      .sort((c1, c2) => c1.number - c2.number);

    for (const channel of sortedChannels) {
      const item = createItemFromChannel(channel);

      if (item) {
        items.push(<Item isInExploreModal={isInExploreModal} item={item} key={item.id} onItemClick={onItemClick} tileConfig={tileConfig} />);
      }
    }

    this.setState({ items });
  };

  render(): React.Node {
    const { section } = this.props;
    const { items } = this.state;
    const {
      tileConfig: { imageLayout, type },
    } = this;

    if (items.length === 0) {
      // Do not display empty section
      return null;
    }

    // Tile type to CSS classe conversion (e.g. 'gemtv.big' -> 'gemtv big')
    const tileTypeClass = getTileTypeClass(type);

    return (
      <div className={clsx('section', 'channelSection', tileTypeClass, imageLayout)} id={`sectionGrid_${this.sectionName}`}>
        <div className='header'>{getTranslatedText(section.title, Localizer.language)}</div>
        <div className='itemGrid'>{items}</div>
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxSectionGridReducerStateType => {
  return {
    channels: state.appConfiguration.deviceChannels,
  };
};

const SectionGrid: React.ComponentType<SectionGridPropType> = connect(mapStateToProps, null, null, { forwardRef: true })(SectionGridView);

export default SectionGrid;
