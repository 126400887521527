/* @flow */

import type { BO_API_ERROR_TYPE } from '../../../../redux/netgemApi/actions/videofutur/types/common';
import type { BasicCallbackFunction } from '@ntg/utils/dist/types';
import { CustomNetworkError } from '../CustomNetworkError';
import { PlayerError } from '../../../../helpers/playerError/PlayerError';
import { logError } from '../../../../helpers/debug/debug';

/*
 * Called from a catch() block to filter out cancelled promises (i.e. aborted signals)
 * If promise was cancelled, nothing is done
 * Otherwise, error is logged and given callback is called
 */
const ignoreIfAborted: (signal: AbortSignal, error: Error | PlayerError | CustomNetworkError | BO_API_ERROR_TYPE, callback?: BasicCallbackFunction) => void = (signal, error, callback) => {
  // $FlowFixMe: flow doesn't know DOMException
  if (signal.aborted || (error instanceof DOMException && error.name === 'AbortError')) {
    return;
  }

  logError('Rejected promise:');
  logError(error instanceof CustomNetworkError ? error.networkError : error);

  if (callback) {
    // Callback is only executed if promise has been rejected, but not cancelled
    callback();
  }
};

export { ignoreIfAborted };
