/* @flow */

import './Item.css';
import * as React from 'react';
import { BroadcastStatus, WebAppHelpersLocationStatus, getBroadcastStatus, getLocationStatus } from '../../../helpers/ui/location/Format';
import {
  type CompleteItemPropType,
  type DefaultProps,
  IMAGE_LOAD_TIMEOUT,
  IMAGE_URL_COUNT,
  type ItemPropType,
  type ItemStateType,
  MetadataStatus,
  REFRESH_TIMEOUT,
  type ReduxItemDispatchToPropsType,
  type ReduxItemReducerStateType,
  TRANSPARENT_PIXEL,
} from './ItemConstsAndTypes';
import { ExtendedItemType, FAKE_EPG_LIVE_PREFIX } from '../../../helpers/ui/item/types';
import { type ImageUrlType, getImageUrl } from '../../../redux/netgemApi/actions/v8/metadataImage';
import {
  ItemContent,
  ItemType,
  type NETGEM_API_V8_FEED_ITEM,
  type NETGEM_API_V8_ITEM_LOCATION,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT,
} from '../../../libs/netgemLibrary/v8/types/FeedItem';
import { Luminosity, Theme, type ThemeType } from '@ntg/ui/dist/theme';
import {
  METADATA_KIND_PROGRAM,
  METADATA_KIND_SERIES,
  type MetadataKind,
  type NETGEM_API_V8_METADATA,
  type NETGEM_API_V8_METADATA_PROGRAM,
  type NETGEM_API_V8_METADATA_SERIES,
} from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { TILE_CHANNEL_IMAGE_HEIGHT, TILE_CHANNEL_IMAGE_WIDTH } from '../../../helpers/ui/constants';
import { TileConfig, TileContentImageKind, TileOnFocus } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import { channelHasStartover, getChannelImageId, getChannelName } from '../../../helpers/channel/helper';
import { extractDistributorId, getVodStatusFromLocations } from '../../../helpers/videofutur/metadata';
import { getItemContentType, getItemType } from '../../../libs/netgemLibrary/v8/helpers/Item';
import { getTileCaption, getTileHoverContent } from '../../../helpers/ui/section/tile';
import { getTimeText, getWatchingStatus, renderProgramTitle, renderSeriesTitle } from './helper';
import { logError, showDebug } from '../../../helpers/debug/debug';
import sendV8MetadataLocationRequest, { getVodLocations } from '../../../redux/netgemApi/actions/v8/metadataLocation';
import AccurateTimestamp from '../../../helpers/dateTime/AccurateTimestamp';
import type { CombinedReducers } from '../../../redux/reducers';
import type { Dispatch } from '../../../redux/types/types';
import ItemDecoration from './ItemDecoration';
import ItemOverlay from './ItemOverlay';
import { Localizer } from '@ntg/utils/dist/localization';
import { MILLISECONDS_PER_HOUR } from '../../../helpers/dateTime/Format';
import type { NETGEM_API_V8_PURCHASE_INFO } from '../../../libs/netgemLibrary/v8/types/PurchaseInfo';
import type { NETGEM_API_V8_REQUEST_RESPONSE } from '../../../libs/netgemLibrary/v8/types/RequestResponse';
import { RegistrationType } from '../../../redux/appRegistration/types/types';
import TextScroller from '../../textScroller/TextScroller';
import { type Undefined } from '@ntg/utils/dist/types';
import { arePurchaseListsDifferent } from '../../../helpers/ui/section/comparisons';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { filterCredits } from '../../../helpers/ui/metadata/Exclusion';
import { formatSeasonEpisodeNbr } from '../../../helpers/ui/metadata/Format';
import { generateDeeplink } from '../../../helpers/debug/deeplink';
import { getClosestEvenInteger } from '../../../helpers/maths/maths';
import { getDistributorId } from '../../../helpers/ui/item/distributor';
import { getPurchaseInfoPerAsset } from '../../../redux/netgemApi/actions/v8/purchaseInfo';
import { getStartOverItem } from '../../../libs/netgemLibrary/v8/helpers/CatchupForAsset';
import getTranslatedText from '../../../libs/netgemLibrary/v8/helpers/Lang';
import { hideModal } from '../../../redux/modal/actions';
import { ignoreIfAborted } from '../../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import { isItemLiveOrAboutToStart } from '../../../libs/netgemLibrary/v8/helpers/Feed';
import sendV8LocationCatchupForAssetRequest from '../../../redux/netgemApi/actions/v8/catchupForAsset';
import sendV8MetadataRequest from '../../../redux/netgemApi/actions/v8/metadata';
import { sendV8RecordingsMetadataRequest } from '../../../redux/netgemApi/actions/v8/recordings';

const InitialState = Object.freeze({
  authority: undefined,
  backgroundImageDisplayIndex: 1,
  broadcastStatus: BroadcastStatus.Unknown,
  caption: new Set<TileConfig>(),
  channelImageUrl: null,
  channelName: null,
  contentType: ItemContent.Unknown,
  hoverContent: new Set<TileConfig>(),
  imageUrls: [TRANSPARENT_PIXEL, TRANSPARENT_PIXEL],
  isDebugModePlusEnabled: false,
  isFocused: false,
  previewCatchupScheduledEventId: null,
  programMetadata: null,
  programMetadataStatus: MetadataStatus.Loading,
  programTitle: '',
  purchaseInfo: null,
  seriesMetadata: null,
  seriesMetadataStatus: MetadataStatus.Loading,
  seriesTitle: '',
  startoverItem: null,
  tvLocationMetadata: null,
  vodLocationsMetadata: null,
  vodStatus: null,
});

class Item extends React.PureComponent<CompleteItemPropType, ItemStateType> {
  abortController: AbortController;

  broadcastStatusRefreshTimer: TimeoutID | null;

  imageElement: HTMLElement | null;

  imageHeight: number;

  imageWidth: number;

  imageLoadTimer: TimeoutID | null;

  itemOverlay: React.ElementRef<any> | null;

  preloadedImage: ?Image;

  timeRefreshTimer: TimeoutID | null;

  // Location Id of purchased location
  viewingHistoryId: ?string;

  // VtiId of purchased location
  vtiId: ?number;

  static defaultProps: DefaultProps = {
    cardData: null,
    isDebugModePlusForced: false,
    isInExploreModal: false,
    isInLiveSection: false,
    isSwiping: false,
    onItemClick: undefined,
  };

  constructor(props: CompleteItemPropType) {
    super(props);

    this.abortController = new AbortController();
    this.broadcastStatusRefreshTimer = null;
    this.imageElement = null;
    this.imageHeight = 0;
    this.imageLoadTimer = null;
    this.imageWidth = 0;
    this.itemOverlay = null;
    this.preloadedImage = null;
    this.timeRefreshTimer = null;
    this.viewingHistoryId = null;
    this.vtiId = null;

    this.state = {
      ...InitialState,
      now: AccurateTimestamp.nowInSeconds(),
    };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps: CompleteItemPropType, prevState: ItemStateType) {
    const { authenticationToken, isInLiveSection, item, purchaseList } = this.props;
    const { broadcastStatus, contentType } = this.state;
    const { authenticationToken: prevAuthenticationToken, item: prevItem, purchaseList: prevPurchaseList } = prevProps;
    const { broadcastStatus: prevBroadcastStatus, contentType: prevContentType } = prevState;
    const { broadcastStatusRefreshTimer } = this;

    if (authenticationToken !== prevAuthenticationToken) {
      this.handleTokenUpdate(authenticationToken);
    }

    if (item.selectedProgramId !== prevItem.selectedProgramId) {
      this.update();
    }

    if (contentType !== prevContentType) {
      if (!broadcastStatusRefreshTimer) {
        this.updateBroadcastStatus();
      }

      this.loadVodLocations();
    }

    if (broadcastStatus !== prevBroadcastStatus) {
      if (!isInLiveSection) {
        if (broadcastStatus === BroadcastStatus.Live) {
          // Item became live
          this.startTimeRefreshTimer();
        } else {
          // Item is not live anymore
          this.resetTimeRefreshTimer();
        }
      }

      if (prevBroadcastStatus === BroadcastStatus.Unknown) {
        this.loadTVLocationMetadata();
      }
    }

    if (arePurchaseListsDifferent(purchaseList, prevPurchaseList)) {
      this.loadVodLocations();
    }
  }

  componentWillUnmount() {
    const { abortController } = this;

    abortController.abort('Component Item will unmount');

    if (this.preloadedImage) {
      this.preloadedImage.src = '';
      this.preloadedImage.onload = undefined;
      this.preloadedImage = null;
    }

    this.resetTimeRefreshTimer();
    this.resetBroadcastStatusRefreshTimer();
    this.resetImageLoadTimer();
  }

  resetTimeRefreshTimer: () => void = () => {
    if (this.timeRefreshTimer) {
      clearTimeout(this.timeRefreshTimer);
      this.timeRefreshTimer = null;
    }
  };

  resetImageLoadTimer = () => {
    if (this.imageLoadTimer) {
      clearTimeout(this.imageLoadTimer);
      this.imageLoadTimer = null;
    }
  };

  resetBroadcastStatusRefreshTimer = () => {
    if (this.broadcastStatusRefreshTimer) {
      clearTimeout(this.broadcastStatusRefreshTimer);
      this.broadcastStatusRefreshTimer = null;
    }
  };

  startTimeRefreshTimer = () => {
    this.resetTimeRefreshTimer();
    this.timeRefreshTimer = setTimeout(this.updateTime, REFRESH_TIMEOUT);
  };

  startBroadcastStatusRefreshTimer = () => {
    const { broadcastStatus } = this.state;

    this.resetBroadcastStatusRefreshTimer();

    if (broadcastStatus === BroadcastStatus.Past) {
      // A past item will never change, no need to check again
      return;
    }

    this.broadcastStatusRefreshTimer = setTimeout(this.updateBroadcastStatus, REFRESH_TIMEOUT);
  };

  updateTime = () => {
    this.setState({ now: AccurateTimestamp.nowInSeconds() }, this.startTimeRefreshTimer);
  };

  updateBroadcastStatus = () => {
    const {
      item,
      item: { type },
    } = this.props;
    const { contentType } = this.state;

    // Item is an SVOD but is displayed as a catchup (e.g. Okoo)
    this.setState({ broadcastStatus: contentType === ItemContent.NetgemSVOD && type !== ItemType.Series ? BroadcastStatus.Past : getBroadcastStatus(item) }, this.startBroadcastStatusRefreshTimer);
  };

  handleTokenUpdate = (token: string | null) => {
    const { isInLiveSection } = this.props;

    if (token && isInLiveSection) {
      this.updateTime();
      this.updateBroadcastStatus();
    }
  };

  showDebugInfo = () => {
    const { itemOverlay, props, state } = this;

    showDebug('Section Item', {
      instance: this,
      instanceFields: ['broadcastStatusRefreshTimer', 'imageElement', 'imageHeight', 'imageWidth', 'imageLoadTimer', 'preloadedImage', 'timeRefreshTimer', 'viewingHistoryId', 'vtiId'],
      misc: { watchingStatus: this.localGetWatchingStatus() },
      props,
      propsFields: ['cardData', 'isInLiveSection', 'item', 'onItemClick', 'tileConfig', 'titleFilter'],
      state,
      stateFields: [
        'authority',
        'backgroundImageDisplayIndex',
        'broadcastStatus',
        'caption',
        'channelImageUrl',
        'contentType',
        'hoverContent',
        'imageUrls',
        'isDebugModePlusEnabled',
        'isFocused',
        'now',
        'previewCatchupScheduledEventId',
        'programMetadata',
        'programMetadataStatus',
        'programTitle',
        'purchaseInfo',
        'seriesMetadata',
        'seriesMetadataStatus',
        'seriesTitle',
        'startoverItem',
        'tvLocationMetadata',
        'vodLocationsMetadata',
        'vodStatus',
      ],
    });

    itemOverlay?.showDebugInfo();
  };

  localGetWatchingStatus = (): number | null => {
    const { item, viewingHistory, viewingHistoryStatus } = this.props;
    const { contentType, programMetadata, seriesMetadata, vodStatus } = this.state;

    return getWatchingStatus(item, viewingHistory, viewingHistoryStatus, contentType, programMetadata, seriesMetadata, vodStatus);
  };

  getImageWidth = (): number => {
    const { imageElement, imageWidth } = this;

    if (imageWidth === 0 && imageElement) {
      this.imageWidth = getClosestEvenInteger(imageElement.getBoundingClientRect().width);
    }

    return this.imageWidth;
  };

  getImageHeight = (): number => {
    const { imageElement, imageHeight } = this;

    if (imageHeight === 0 && imageElement) {
      this.imageHeight = getClosestEvenInteger(imageElement.getBoundingClientRect().height);
    }

    return this.imageHeight;
  };

  unfocus = () => {
    this.setState({ isFocused: false });
  };

  update = () => {
    const {
      channels,
      isInLiveSection,
      item: {
        id,
        selectedProgramId,
        selectedLocation: { channelId },
      },
      tileConfig,
      tileConfig: { onFocus },
    } = this.props;
    const { backgroundImageDisplayIndex, imageUrls } = this.state;

    this.resetImageLoadTimer();
    this.resetTimeRefreshTimer();

    this.vtiId = null;

    const itemType = getItemType(id);
    const hoverContent = getTileHoverContent(tileConfig, itemType);
    const caption = getTileCaption(tileConfig, itemType);

    // We copy imageUrls (which is empty at startup) because we transition between previous and new image
    this.setState({
      ...InitialState,
      backgroundImageDisplayIndex,
      caption,
      channelName: null,
      hoverContent,
      imageUrls,
    });

    this.loadChannelImage();

    if (selectedProgramId.startsWith(FAKE_EPG_LIVE_PREFIX)) {
      // Live program without info (missing PTF channel)
      this.setState({ channelName: getChannelName(channels, channelId) });
      return;
    }

    this.loadImage();

    if (onFocus === TileOnFocus.Selection) {
      this.setState({
        programMetadataStatus: MetadataStatus.Loaded,
        seriesMetadataStatus: MetadataStatus.Loaded,
      });
      return;
    }

    this.loadProgramMetadata();
    this.loadSeriesMetadata();
    this.loadStartoverItem();

    if (isInLiveSection) {
      // Items in a live section will always be considered 'live', even when starting or finishing
      this.setState({ broadcastStatus: BroadcastStatus.Live });
    }
  };

  updateTitles = () => {
    const { programMetadata, seriesMetadata } = this.state;

    const seriesTitle = getTranslatedText(seriesMetadata?.titles, Localizer.language);
    const index = formatSeasonEpisodeNbr(programMetadata);
    let title = getTranslatedText(programMetadata?.titles, Localizer.language);
    if (title.toLowerCase() === seriesTitle.toLowerCase()) {
      title = '';
    }
    const programTitle = `${index ?? ''}${index && title !== '' ? ' : ' : ''}${title}`;

    this.setState({
      programTitle,
      seriesTitle,
    });
  };

  loadChannelImage = () => {
    const {
      channels,
      item: { selectedLocation },
      localGetImageUrl,
      tileConfig: { channelLogo },
    } = this.props;
    const {
      abortController: { signal },
    } = this;

    if (!channelLogo) {
      return;
    }

    const { channelId } = selectedLocation;
    const channelImageId = getChannelImageId(channels, channelId);

    if (!channelImageId) {
      // Universe, channel, channel group, channel without image, etc.
      return;
    }

    localGetImageUrl(
      {
        assetId: channelImageId,
        height: TILE_CHANNEL_IMAGE_HEIGHT,
        luminosity: Luminosity.Light,
        width: TILE_CHANNEL_IMAGE_WIDTH,
      },
      signal,
    )
      .then((channelImageUrl: string) => {
        signal.throwIfAborted();

        this.setState({ channelImageUrl });
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  loadProgramMetadata = () => {
    const {
      isInLiveSection,
      item,
      item: { selectedProgramId, seriesId },
      localSendV8MetadataRequest,
    } = this.props;
    const {
      abortController: { signal },
    } = this;

    localSendV8MetadataRequest(selectedProgramId, METADATA_KIND_PROGRAM, signal)
      .then((metadata: NETGEM_API_V8_METADATA) => {
        const programMetadata = ((filterCredits(metadata): any): NETGEM_API_V8_METADATA_PROGRAM);
        const metadataSeriesId = programMetadata.episodeOf?.id;

        // If the program is part of a series but no series was provided, check in the metadata
        if (!seriesId && metadataSeriesId) {
          this.loadSeriesMetadata(metadataSeriesId);
        }

        const { authority } = programMetadata;

        this.setState(
          {
            authority,
            contentType: getItemContentType(item, authority),
            programMetadata,
            programMetadataStatus: MetadataStatus.Loaded,
          },
          () => {
            this.updateTitles();
            this.loadTVLocationMetadata();
          },
        );

        // If the program is live, start a timer to update the time display
        if (isInLiveSection) {
          this.startTimeRefreshTimer();
        }
      })
      .catch((error) => ignoreIfAborted(signal, error, () => this.setState({ programMetadataStatus: MetadataStatus.Error })));
  };

  loadSeriesMetadata = (metadataSeriesId?: string) => {
    const {
      item: { seriesId },
      localSendV8MetadataRequest,
    } = this.props;
    const {
      abortController: { signal },
    } = this;

    const id = seriesId ?? metadataSeriesId;

    if (!id) {
      this.setState({ seriesMetadataStatus: MetadataStatus.Loaded });
      return;
    }

    localSendV8MetadataRequest(id, METADATA_KIND_SERIES, signal)
      .then((metadata: NETGEM_API_V8_METADATA) => {
        const seriesMetadata = ((filterCredits(metadata): any): NETGEM_API_V8_METADATA_SERIES);
        this.setState(
          {
            seriesMetadata,
            seriesMetadataStatus: MetadataStatus.Loaded,
          },
          this.updateTitles,
        );
      })
      .catch((error) => ignoreIfAborted(signal, error, () => this.setState({ seriesMetadataStatus: MetadataStatus.Error })));
  };

  loadImage = (isNewAttempt?: boolean) => {
    const {
      channels,
      item: {
        id,
        selectedLocation: { channelId },
        selectedProgramId,
      },
      localGetImageUrl,
      tileConfig: { contentImage, contentImageVariant: forcedVariant },
    } = this.props;
    const {
      abortController: { signal },
      imageLoadTimer,
    } = this;

    if (imageLoadTimer) {
      if (isNewAttempt) {
        this.imageLoadTimer = null;
      } else {
        return;
      }
    }

    const width = this.getImageWidth();
    const height = this.getImageHeight();

    if (width === 0 || height === 0) {
      // Image not ready yet
      this.imageLoadTimer = setTimeout(this.loadImage, IMAGE_LOAD_TIMEOUT, true);
      return;
    }

    let assetId: ?string = null;
    let theme: Undefined<ThemeType> = undefined;
    const itemType = getItemType(selectedProgramId);
    if (itemType === ItemType.Channel || contentImage === TileContentImageKind.Channel || selectedProgramId.startsWith(FAKE_EPG_LIVE_PREFIX)) {
      // Channel: Id is the channel Id if content image is 'channel' (e.g. FAST channels) and is item Id if item type is 'channel'
      assetId = getChannelImageId(channels, contentImage === TileContentImageKind.Channel || selectedProgramId.startsWith(FAKE_EPG_LIVE_PREFIX) ? channelId : id);
      theme = Theme.Dark;

      if (assetId === null) {
        // Channel (probably a FAST one) without image: display its name
        this.setState({ channelName: getChannelName(channels, id) });
      }
    } else if (itemType === ItemType.Program || itemType === ItemType.Series) {
      // Program or series
      assetId = contentImage === TileContentImageKind.Series ? id : selectedProgramId;
    } else if (itemType === ItemType.ChannelGroup || itemType === ItemType.Universe) {
      // Channel group or universe
      assetId = id;
      theme = Theme.Dark;
    } else {
      // Everything else (i.e. unknown types)
      assetId = id;
    }

    if (typeof assetId === 'undefined' || assetId === null) {
      return;
    }

    localGetImageUrl(
      {
        assetId,
        forcedVariant,
        height,
        theme,
        width,
      },
      signal,
    )
      .then((url: string) => {
        signal.throwIfAborted();

        if (url !== '') {
          this.preloadedImage = new Image(width, height);
          this.preloadedImage.onload = () => this.handleImageOnLoad(signal);
          this.preloadedImage.src = url;
        } else {
          // 404 HTTP code is generally not considered as an error for images but in the particular context of a channel image in the zapper, it is
          this.setState({ channelName: getChannelName(channels, id) });
        }
      })
      .catch((error) => {
        ignoreIfAborted(signal, error);

        // Channel (probably a FAST one) without image: display its name
        this.setState({ channelName: getChannelName(channels, id) });
      });
  };

  handleImageOnLoad = (signal: AbortSignal) => {
    const { backgroundImageDisplayIndex, imageUrls: previousImageUrls } = this.state;

    if (!this.preloadedImage || signal.aborted) {
      // Component unmounted while image was loading
      return;
    }

    this.preloadedImage.onload = undefined;
    const imageUrls = [...previousImageUrls];
    const index = (backgroundImageDisplayIndex + 1) % IMAGE_URL_COUNT;

    if (index === backgroundImageDisplayIndex) {
      // Already been there for this image
      return;
    }

    imageUrls[index] = this.preloadedImage.src;
    this.preloadedImage = null;

    this.setState({
      backgroundImageDisplayIndex: index,
      imageUrls,
    });
  };

  getImageUrl = (): string => {
    const { backgroundImageDisplayIndex, imageUrls } = this.state;

    return imageUrls[backgroundImageDisplayIndex];
  };

  loadPurchaseInfo = () => {
    const {
      item: {
        id,
        purchasable,
        selectedLocation: { channelId },
      },
      localGetPurchaseInfoPerAsset,
    } = this.props;
    const { contentType } = this.state;
    const {
      abortController: { signal },
    } = this;

    if ((contentType !== ItemContent.VODOrDeeplink && contentType !== ItemContent.NetgemSVOD) || !purchasable) {
      return;
    }

    if (typeof channelId === 'undefined') {
      logError(`Channel Id is undefined for item ${id}`);
      return;
    }

    localGetPurchaseInfoPerAsset(id, channelId, signal)
      .then((purchaseInfo: NETGEM_API_V8_PURCHASE_INFO) => {
        signal.throwIfAborted();
        this.setState({ purchaseInfo });
      })
      .catch((error) => ignoreIfAborted(signal, error, () => this.setState({ purchaseInfo: null })));
  };

  loadVodLocations = () => {
    const {
      item: { locations, seriesId },
      localGetVodLocations,
      purchaseList,
      usePackPurchaseApi,
    } = this.props;
    const { contentType } = this.state;
    const {
      abortController: { signal },
    } = this;

    // TODO: clean up code when BO API v2 is fully supported
    if (usePackPurchaseApi) {
      this.loadPurchaseInfo();
    }

    if (seriesId) {
      return;
    }

    if ((contentType !== ItemContent.VODOrDeeplink && contentType !== ItemContent.NetgemSVOD) || !locations) {
      return;
    }

    localGetVodLocations(locations, signal)
      .then((vodLocationsMetadata) => {
        signal.throwIfAborted();

        const vodStatus = getVodStatusFromLocations(vodLocationsMetadata, purchaseList);
        const { viewingHistoryId, vtiId } = vodStatus;
        this.viewingHistoryId = viewingHistoryId;
        this.vtiId = vtiId;

        this.setState({
          vodLocationsMetadata,
          vodStatus,
        });
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  loadStartoverItem = () => {
    const {
      channels,
      item,
      item: { locType, selectedLocation, selectedProgramId },
      localSendV8LocationCatchupForAssetRequest,
    } = this.props;
    const {
      abortController: { signal },
    } = this;

    if (
      locType !== NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT ||
      !selectedLocation ||
      !selectedProgramId ||
      !isItemLiveOrAboutToStart(item) ||
      !channelHasStartover(channels, selectedLocation.channelId)
    ) {
      return;
    }

    localSendV8LocationCatchupForAssetRequest(selectedProgramId, AccurateTimestamp.now(), MILLISECONDS_PER_HOUR, signal)
      .then((requestResponse: NETGEM_API_V8_REQUEST_RESPONSE) => {
        const startoverItem: NETGEM_API_V8_FEED_ITEM | null = getStartOverItem(item, requestResponse);
        this.setState({ startoverItem });
      })
      .catch((error) => ignoreIfAborted(signal, error, () => this.setState({ startoverItem: null })));
  };

  loadTVLocationMetadata = () => {
    const {
      item,
      item: {
        locType,
        selectedLocation: { id },
      },
      localSendV8MetadataLocationRequest,
      localSendV8RecordingsMetadataRequest,
    } = this.props;
    const { authority, broadcastStatus, contentType } = this.state;
    const {
      abortController: { signal },
    } = this;

    if (!id || contentType === ItemContent.VODOrDeeplink || contentType === ItemContent.NetgemSVOD || broadcastStatus === BroadcastStatus.Unknown) {
      return;
    }

    const locationStatus = getLocationStatus(item, authority);

    const request =
      locationStatus === WebAppHelpersLocationStatus.Recording || (locationStatus === WebAppHelpersLocationStatus.Live && locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING)
        ? localSendV8RecordingsMetadataRequest
        : localSendV8MetadataLocationRequest;

    request(id, signal)
      .then((requestResponse: NETGEM_API_V8_REQUEST_RESPONSE) => {
        signal.throwIfAborted();

        const {
          result,
          result: {
            location: { target },
          },
        } = requestResponse;

        this.setState({
          previewCatchupScheduledEventId: broadcastStatus === BroadcastStatus.Preview ? target : null,
          tvLocationMetadata: result,
        });
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  getDistributorId = (): string | null => {
    const {
      deviceOS,
      item: {
        selectedLocation: { id },
      },
    } = this.props;
    const { programMetadata, purchaseInfo, vodLocationsMetadata } = this.state;

    return getDistributorId(vodLocationsMetadata, purchaseInfo, programMetadata) ?? extractDistributorId(id, deviceOS);
  };

  handleTileMouseOver = () => {
    const { isFocused } = this.state;

    if (!isFocused) {
      this.setState({ isFocused: true });
    }
  };

  handleTileMouseLeave = () => {
    const { isFocused } = this.state;

    if (isFocused) {
      this.unfocus();
    }
  };

  handleTileOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const {
      cardData,
      isDebugModeEnabled,
      isInExploreModal,
      isSwiping,
      item,
      localHideModal,
      onItemClick,
      tileConfig: { type: tileType },
    } = this.props;
    const { deviceOS } = this.props;
    const { authority, contentType, isDebugModePlusEnabled: currentIsDebugModePlusEnabled, programMetadata, purchaseInfo, seriesMetadata, vodLocationsMetadata } = this.state;
    const { vtiId } = this;
    const { altKey, ctrlKey, shiftKey } = event;

    if (isSwiping) {
      return;
    }

    if (isDebugModeEnabled) {
      if (ctrlKey || altKey) {
        if (shiftKey) {
          this.setState({ isDebugModePlusEnabled: !currentIsDebugModePlusEnabled });
        } else {
          this.showDebugInfo();
        }
        return;
      } else if (shiftKey) {
        generateDeeplink({
          authority,
          contentType,
          deviceOS,
          item,
          programMetadata,
          purchaseInfo,
          seriesMetadata,
          vodLocationsMetadata,
        });
        return;
      }
    }

    const type = vtiId ? ExtendedItemType.VOD : ExtendedItemType.TV;
    const imageUrl = this.getImageUrl();

    Messenger.emit(MessengerEvents.ITEM_CLICKED, {
      cardData,
      imageUrl,
      item,
      onItemClick,
      programMetadata,
      seriesMetadata,
      tileType,
      type,
      vtiId,
    });

    if (isInExploreModal) {
      localHideModal();
    }
  };

  renderCaption = (): {| caption: React.Element<any> | null, isFiltered: boolean |} => {
    const { item, titleFilter } = this.props;
    const { caption, now, programMetadataStatus, programTitle, seriesMetadataStatus, seriesTitle } = this.state;

    const lcFilter = titleFilter?.toLowerCase();
    if (typeof lcFilter !== 'undefined' && programTitle.toLowerCase().indexOf(lcFilter) === -1 && seriesTitle.toLowerCase().indexOf(lcFilter) === -1) {
      return { caption: null, isFiltered: true };
    }

    if (caption.size === 0) {
      // No caption at all (e.g. portrait tile)
      return { caption: null, isFiltered: false };
    }

    let titleElement = null;
    let subtitleElement = null;

    if (caption.has(TileConfig.SeriesTitle)) {
      titleElement = renderSeriesTitle(programMetadataStatus, seriesMetadataStatus, seriesTitle);
    }

    const timeText = getTimeText(item, now, caption);

    if (caption.has(TileConfig.ProgramTitle)) {
      const programTitleElement = renderProgramTitle(
        programMetadataStatus,
        seriesMetadataStatus,
        programTitle,
        titleElement ? timeText : null,
        titleElement ? 'secondLineInfo' : '',
        titleElement === null,
      );
      if (titleElement) {
        subtitleElement = programTitleElement;
      } else {
        titleElement = programTitleElement;
      }
    }

    if (!titleElement && !subtitleElement && !timeText) {
      return {
        caption: (
          <div className='textContainer placeholder'>
            <div className='text' />
            <div className='text secondLineInfo' />
          </div>
        ),
        isFiltered: false,
      };
    }

    let secondLineElt = null;
    if (subtitleElement) {
      secondLineElt = <TextScroller style={{ font: '14px var(--regular-font)', height: 'calc(var(--tile-text-height) / 2 * 1px)' }}>{subtitleElement}</TextScroller>;
    } else if (timeText) {
      secondLineElt = <div className='text secondLineInfo'>{timeText}</div>;
    }

    return {
      caption: (
        <div className='textContainer' onClick={this.handleTileOnClick}>
          <TextScroller style={{ font: '16px var(--semibold-font)', height: 'calc(var(--tile-text-height) / 2 * 1px)' }}>{titleElement}</TextScroller>
          {secondLineElt}
        </div>
      ),
      isFiltered: false,
    };
  };

  renderSelectionTile = (): React.Element<any> => {
    const {
      item: {
        id,
        selectedLocation: { channelId },
      },
    } = this.props;

    const imageUrl = this.getImageUrl();
    const imageStyle = imageUrl ? { backgroundImage: `url(${imageUrl})` } : {};

    return (
      <div className='sectionItem'>
        <div className='selectionBorder'>
          <div
            className='tileContainer'
            data-qa-channel-id={channelId ?? id}
            onClick={this.handleTileOnClick}
            ref={(instance) => {
              this.imageElement = instance;
            }}
            style={imageStyle}
          />
        </div>
      </div>
    );
  };

  renderBackgroundImages = (): React.Element<any> => {
    const { backgroundImageDisplayIndex, imageUrls } = this.state;

    /* eslint-disable react/no-array-index-key */
    return (
      <>
        {imageUrls.map((url, i) => (
          <img alt='' className={clsx('backgroundImage', i === backgroundImageDisplayIndex ? 'visible' : 'hidden')} key={i} src={url} />
        ))}
      </>
    );
    /* eslint-enable react/no-array-index-key */
  };

  renderDetailsTile = (): React.Element<any> | null => {
    const {
      isDebugModeEnabled,
      isDebugModePlusForced = false,
      isInLiveSection,
      item,
      item: {
        selectedLocation: { channelId, id },
      },
      onItemClick,
      tileConfig,
      tileConfig: { onFocus },
    } = this.props;
    const {
      authority,
      broadcastStatus,
      channelImageUrl,
      channelName,
      contentType,
      hoverContent,
      isDebugModePlusEnabled,
      isFocused,
      now,
      previewCatchupScheduledEventId,
      programMetadata,
      programMetadataStatus,
      programTitle,
      purchaseInfo,
      seriesMetadata,
      seriesMetadataStatus,
      seriesTitle,
      startoverItem,
      tvLocationMetadata,
      vodLocationsMetadata,
      vodStatus,
    } = this.state;
    const { viewingHistoryId, vtiId } = this;

    const bgImageElements = this.renderBackgroundImages();

    // Information displayed below image, described by caption in tileConfig (series title, program title, date, etc.)
    const { caption, isFiltered } = this.renderCaption();

    if (isFiltered) {
      // User entered a something in the filter box and this item does not match it through its program or series title
      return null;
    }

    const distributorId = this.getDistributorId();

    // 2 possible states for a tile: normal or focused (i.e. hovered)
    return (
      <div className='sectionItem'>
        <div
          className={clsx('tileContainer', isFocused && 'focused')}
          data-qa-channel-id={channelId}
          data-qa-location-id={id}
          onClick={this.handleTileOnClick}
          onMouseLeave={this.handleTileMouseLeave}
          onMouseOver={this.handleTileMouseOver}
          ref={(instance) => {
            this.imageElement = instance;
          }}
        >
          {bgImageElements}
          <ItemDecoration
            broadcastStatus={broadcastStatus}
            channelImageUrl={channelImageUrl}
            contentType={contentType}
            isDebugModePlusEnabled={isDebugModePlusEnabled || isDebugModePlusForced}
            isInLiveSection={isInLiveSection}
            item={item}
            now={now}
            onFocus={onFocus}
            previewCatchupScheduledEventId={previewCatchupScheduledEventId}
            programMetadata={programMetadata}
            tvLocationMetadata={tvLocationMetadata}
            vodLocationsMetadata={vodLocationsMetadata}
            watchingStatus={this.localGetWatchingStatus()}
          />
          {isDebugModeEnabled && channelName !== null ? <div className='channelName'>{channelName}</div> : null}
          <ItemOverlay
            authority={authority}
            broadcastStatus={broadcastStatus}
            contentType={contentType}
            distributorId={distributorId}
            hoverContent={hoverContent}
            isFocused={isFocused}
            isInLiveSection={isInLiveSection}
            item={item}
            now={now}
            onClick={this.handleTileOnClick}
            onItemClick={onItemClick}
            previewCatchupScheduledEventId={previewCatchupScheduledEventId}
            programMetadata={programMetadata}
            programMetadataStatus={programMetadataStatus}
            programTitle={programTitle}
            purchaseInfo={purchaseInfo}
            ref={(instance) => {
              this.itemOverlay = instance;
            }}
            seriesMetadata={seriesMetadata}
            seriesMetadataStatus={seriesMetadataStatus}
            seriesTitle={seriesTitle}
            startoverItem={startoverItem}
            tileConfig={tileConfig}
            tvLocationMetadata={tvLocationMetadata}
            viewingHistoryId={viewingHistoryId}
            vodLocationsMetadata={vodLocationsMetadata}
            vodStatus={vodStatus}
            vtiId={vtiId}
          />
        </div>
        {caption}
      </div>
    );
  };

  render(): React.Node {
    const {
      item: { selectedProgramId },
      tileConfig: { onFocus },
    } = this.props;

    if (onFocus === TileOnFocus.Details || selectedProgramId.startsWith(FAKE_EPG_LIVE_PREFIX)) {
      // Program, series, etc.: details appear on hover
      return this.renderDetailsTile();
    }

    // Channel, channel group, universe, etc.: tile is selected on hover
    return this.renderSelectionTile();
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxItemReducerStateType => {
  return {
    authenticationToken: state.appRegistration.authenticationToken,
    channels: state.appConfiguration.deviceChannels,
    commercialOffers: state.appConfiguration.rightsCommercialOffers,
    defaultOnItemClick: state.ui.defaultActions ? state.ui.defaultActions.onItemClick : null,
    defaultRights: state.appConfiguration.rightsDefault,
    deviceOS: state.appConfiguration.deviceOS,
    favoriteList: state.ui.favoriteList || [],
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
    isRegisteredAsGuest: state.appRegistration.registration === RegistrationType.RegisteredAsGuest,
    purchaseList: state.appRegistration.registration === RegistrationType.RegisteredAsGuest ? {} : state.ui.purchaseList,
    usePackPurchaseApi: state.appConfiguration.usePackPurchaseApi,
    userRights: state.appConfiguration.rightsUser,
    viewingHistory: state.ui.viewingHistory,
    viewingHistoryStatus: state.ui.viewingHistoryStatus,
    wishlistStatus: state.ui.wishlistStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxItemDispatchToPropsType => {
  return {
    localGetImageUrl: (data: ImageUrlType, signal?: AbortSignal): Promise<any> => dispatch(getImageUrl(data, signal)),

    localGetPurchaseInfoPerAsset: (id: string, channelId: string, signal?: AbortSignal): Promise<any> => dispatch(getPurchaseInfoPerAsset(id, channelId, signal)),

    localGetVodLocations: (locations: Array<NETGEM_API_V8_ITEM_LOCATION>, signal?: AbortSignal): Promise<any> => dispatch(getVodLocations(locations, signal)),

    localHideModal: () => dispatch(hideModal()),

    localSendV8LocationCatchupForAssetRequest: (assetId: string, startDate: number, range: number, signal?: AbortSignal): Promise<any> =>
      dispatch(sendV8LocationCatchupForAssetRequest(assetId, startDate, range, undefined, signal)),

    localSendV8MetadataLocationRequest: (assetId: string, signal?: AbortSignal): Promise<any> => dispatch(sendV8MetadataLocationRequest(assetId, signal)),

    localSendV8MetadataRequest: (assetId: string, type: MetadataKind, signal?: AbortSignal): Promise<any> => dispatch(sendV8MetadataRequest(assetId, type, signal)),

    localSendV8RecordingsMetadataRequest: (recordId: string, signal?: AbortSignal): Promise<any> => dispatch(sendV8RecordingsMetadataRequest(recordId, signal)),
  };
};

const ItemView: React.ComponentType<ItemPropType> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Item);

export default ItemView;
