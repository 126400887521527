/* @flow */

import './SearchBox.css';
import * as React from 'react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import HotKeys from '../../helpers/hotKeys/hotKeys';
import { Localizer } from '@ntg/utils/dist/localization';
import { PictoMagnifier } from '@ntg/components/dist/pictos/Element';
import { SEARCH_STRING_MIN_LENGTH } from '../../helpers/search/constants';

type PropType = {|
  +onSearch: (searchString: string) => void,
|};

const SearchBox = ({ onSearch }: PropType, ref: React.ElementRef<any> | null): React.Node => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');

  const inputRef = useRef<HTMLElement | null>(null);

  const isClosed = useCallback(() => !isOpen, [isOpen]);
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);

  useImperativeHandle(ref, () => {
    return {
      isClosed,
      open,
    };
  });

  const reset: () => void = useCallback(() => {
    HotKeys.unregister('enter', handleSearchHotKey);
    HotKeys.unregister('escape', handleResetHotKey);

    setIsOpen(false);
    setSearchString('');
  }, [setIsOpen, setSearchString]);

  const launchSearch = useCallback(() => {
    const trimmedSearchString = searchString.trim();
    if (trimmedSearchString.length >= SEARCH_STRING_MIN_LENGTH) {
      onSearch(trimmedSearchString);
      reset();
    }
  }, [onSearch, reset, searchString]);

  const handleSearchHotKey = useCallback(
    (event: SyntheticKeyboardEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();

      launchSearch();
    },
    [launchSearch]
  );

  const handleResetHotKey = useCallback(
    (event: SyntheticKeyboardEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();

      reset();
    },
    [reset]
  );

  useEffect(() => {
    if (!isOpen || !inputRef.current) {
      // Returning undefined to avoid eslint consistent-return error
      return undefined;
    }

    inputRef.current.focus();

    HotKeys.register('enter', handleSearchHotKey, {
      allowEditableContext: true,
      name: 'Search.search',
    });
    HotKeys.register('escape', handleResetHotKey, {
      allowEditableContext: true,
      name: 'Search.reset',
    });

    return () => {
      HotKeys.unregister('enter', handleSearchHotKey);
      HotKeys.unregister('escape', handleResetHotKey);
    };
  }, [handleResetHotKey, handleSearchHotKey, isOpen]);

  const handleOnClick = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      launchSearch();
    }
  }, [isOpen, launchSearch, setIsOpen]);

  const handleOnChange = useCallback(
    (event: SyntheticInputEvent<HTMLInputElement>) => {
      setSearchString(event.currentTarget.value);
    },
    [setSearchString]
  );

  const handleOnBlur = useCallback(() => {
    if (searchString.trim() === '') {
      reset();
    }
  }, [reset, searchString]);

  const inputElt = useMemo(() => {
    if (!isOpen) {
      return null;
    }

    return <input onBlur={handleOnBlur} onChange={handleOnChange} placeholder={Localizer.localize('search.placeholder')} ref={inputRef} type='text' value={searchString} />;
  }, [handleOnBlur, handleOnChange, isOpen, searchString]);

  return (
    <div className='searchBox'>
      <PictoMagnifier onClick={handleOnClick} />
      {inputElt}
    </div>
  );
};

// $FlowFixMe: don't know how to annotate this export
export default forwardRef(SearchBox);
