/* @flow */

import type { BasicCallbackFunction, KeyValuePair } from '@ntg/utils/dist/types';

// After 1 second, display the header even if the logo is not loaded
export const LOGO_LOAD_TIMEOUT = 1_000;

export type HeaderPropType = {|
  +isScrolling: boolean,
  +onLogoLoaded: BasicCallbackFunction,
  +onSearch: (searchString: string) => void,
|};

export type SecurityInfoType = KeyValuePair<?string>;
