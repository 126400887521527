/* @flow */

import './Footer.css';
import * as React from 'react';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import type { CombinedReducers } from '../../redux/reducers';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import { PictoArrowUp } from '@ntg/components/dist/pictos/Element';
import clsx from 'clsx';
import { connect } from 'react-redux';

type ReduxFooterReducerStateType = {|
  +configuration: KeyValuePair<string>, // eslint-disable-line react/no-unused-prop-types
  +isDebugModeEnabled: boolean,
  +isInTheaterMode: boolean,
  +versionShort: string, // eslint-disable-line react/no-unused-prop-types
|};

type FooterPropType = {|
  +isVisible: boolean,
|};

type FooterStateType = {|
  content: React.Element<any> | null,
|};

type CompleteFooterPropType = {|
  ...FooterPropType,
  ...ReduxFooterReducerStateType,
|};

class FooterView extends React.PureComponent<CompleteFooterPropType, FooterStateType> {
  constructor(props: CompleteFooterPropType) {
    super(props);

    this.state = { content: null };
    this.setFooterContent(props);
  }

  componentDidUpdate(prevProps: CompleteFooterPropType) {
    const { isDebugModeEnabled } = this.props;
    const { isDebugModeEnabled: prevIsDebugModeEnabled } = prevProps;

    if (isDebugModeEnabled !== prevIsDebugModeEnabled) {
      this.setFooterContent(this.props);
    }
  }

  setFooterContent = (props: CompleteFooterPropType): void => {
    import('../../helpers/applicationCustomization/ui').then((module) => {
      module.AppFooterContent.then((FooterContent) => {
        if (!FooterContent) {
          return;
        }

        const { configuration, isDebugModeEnabled, versionShort } = props;

        this.setState({
          content: <FooterContent configuration={configuration} isDebugModeEnabled={isDebugModeEnabled} version={versionShort} />,
        });
      });
    });
  };

  handleTopButtonOnClick = (): void => {
    Messenger.emit(MessengerEvents.MOVE_TO_TOP);
  };

  render(): React.Node {
    const { isInTheaterMode, isVisible } = this.props;
    const { content } = this.state;

    return (
      <div className={clsx('footer', (isInTheaterMode && 'slideDown') || (isVisible && 'slideUp'))}>
        <div className='footerCommon'>
          <PictoArrowUp className='topButton' onClick={this.handleTopButtonOnClick} />
        </div>
        <React.Suspense fallback={<div>Error loading footer content</div>}>{content}</React.Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxFooterReducerStateType => {
  return {
    configuration: state.appConfiguration.configuration,
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
    isInTheaterMode: state.ui.isInTheaterMode,
    versionShort: state.appConfiguration.versionAppShort,
  };
};

const Footer: React.ComponentType<FooterPropType> = connect(mapStateToProps, null, null, { forwardRef: true })(FooterView);

export default Footer;
