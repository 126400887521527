/* @flow */

import { BOVodAssetStatus, getTitIdFromProviderInfo } from '../../../helpers/videofutur/metadata';
import { type CARD_DATA_MODAL_TYPE, type CardModalStateType, type CompleteCardModalPropType, ImageDisplayType } from './CardModalConstsAndTypes';
import {
  ItemContent,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT,
} from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_METADATA_PROGRAM, NETGEM_API_V8_METADATA_SERIES } from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import { BroadcastStatus } from '../../../helpers/ui/location/Format';
import { LoadableStatus } from '../../../helpers/loadable/loadable';
import { NETGEM_API_V8_AUTHENT_REALM_VIDEOFUTUR } from '../../../libs/netgemLibrary/v8/types/Realm';
import { RecordingOutcome } from '../../../libs/netgemLibrary/v8/types/Npvr';
import { TileConfigTileType } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import { getDistributorId } from '../../../helpers/ui/item/distributor';
import { getIntegerPercentage } from '../../../helpers/maths/maths';
import { getProgress } from '../../../helpers/viewingHistory/ViewingHistory';

const getDisplayType = (imageDisplayType: ImageDisplayType, tileType: TileConfigTileType): string => {
  if (imageDisplayType === ImageDisplayType.Unset) {
    return '';
  }

  if (tileType === TileConfigTileType.Deeplink) {
    return (TileConfigTileType.Deeplink: string);
  }

  if (imageDisplayType === ImageDisplayType.Portrait) {
    return (TileConfigTileType.Portrait: string);
  }

  return (TileConfigTileType.Landscape: string);
};

const getLiveProgress = (cardData?: CARD_DATA_MODAL_TYPE, now: number): number => {
  if (!cardData) {
    return 0;
  }

  const {
    item: { endTime, startTime },
  } = cardData;
  return getIntegerPercentage(now, startTime, endTime);
};

const getProgramMetadata = (cardData: CARD_DATA_MODAL_TYPE, state: CardModalStateType): NETGEM_API_V8_METADATA_PROGRAM | null => cardData.programMetadata ?? state.programMetadata;

const getSeriesMetadata = (cardData: CARD_DATA_MODAL_TYPE, state: CardModalStateType): NETGEM_API_V8_METADATA_SERIES | null => cardData.seriesMetadata ?? state.seriesMetadata;

const getWatchingStatus = (props: CompleteCardModalPropType, state: CardModalStateType): number | null => {
  const { cardData, purchaseList, viewingHistory, viewingHistoryStatus } = props;
  const { contentType, isSeries, vodStatus } = state;

  if (!cardData || isSeries) {
    return 0;
  }

  const {
    item,
    item: { locType },
  } = cardData;

  if (!vodStatus && locType !== NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP && locType !== NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING) {
    return 0;
  }

  if (vodStatus && contentType === ItemContent.VODOrDeeplink) {
    if (!purchaseList) {
      return 0;
    }

    const { status } = vodStatus;
    if (status === BOVodAssetStatus.Locked) {
      // Item has been watched (at least partially) but its rent has expired
      return 0;
    }
  }

  if (viewingHistoryStatus !== LoadableStatus.Loaded || locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT) {
    return 0;
  }

  const programMetadata = getProgramMetadata(cardData, state);
  const seriesMetadata = getSeriesMetadata(cardData, state);
  return getProgress(viewingHistory, item, programMetadata, seriesMetadata, false, vodStatus);
};

const isFavorite = (props: CompleteCardModalPropType, state: CardModalStateType): boolean => {
  const { cardData, favoriteList, wishlist, wishlistStatus } = props;
  const { authority, purchaseInfo, vodLocationsMetadata } = state;

  if (!cardData) {
    return false;
  }

  const programMetadata = getProgramMetadata(cardData, state);

  if (!programMetadata) {
    return false;
  }

  const { id: programId, providerInfo } = programMetadata;
  const distributorId = getDistributorId(vodLocationsMetadata, purchaseInfo, programMetadata);

  if (authority === NETGEM_API_V8_AUTHENT_REALM_VIDEOFUTUR) {
    if (!providerInfo || !distributorId) {
      return false;
    }

    const titId = getTitIdFromProviderInfo(providerInfo, getSeriesMetadata(cardData, state));

    return favoriteList.includes(titId) ?? false;
  }

  if (wishlistStatus !== LoadableStatus.Loaded) {
    return false;
  }

  const seriesMetadata = getSeriesMetadata(cardData, state);
  const assetId = seriesMetadata?.id ?? programId;

  return wishlist.has(assetId);
};

const isItemPlayable = (cardData: CARD_DATA_MODAL_TYPE, broadcastStatus: BroadcastStatus, contentType: ItemContent, isSeries: boolean, recordOutcome: ?RecordingOutcome): boolean => {
  const {
    isOpenFromPlayer,
    item: { locType },
  } = cardData;

  return (
    !isOpenFromPlayer &&
    !isSeries &&
    ((broadcastStatus === BroadcastStatus.Past && locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP) ||
      broadcastStatus === BroadcastStatus.Live ||
      broadcastStatus === BroadcastStatus.Preview ||
      (locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING && recordOutcome === RecordingOutcome.Recorded) ||
      contentType === ItemContent.NetgemSVOD)
  );
};

const updateTitle = (applicationName: string, title: ?string) => {
  document.title = title ? `${title} | ${applicationName}` : applicationName;
};

export { getDisplayType, getLiveProgress, getWatchingStatus, isFavorite, isItemPlayable, updateTitle };
