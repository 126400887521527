/* @flow */

import './infiniteCircleLoaderArc.css';
import * as React from 'react';
import { Theme, type ThemeType } from '@ntg/ui/dist/theme';
import clsx from 'clsx';

type InfiniteCircleLoaderArcPropType = {|
  +className?: string, // eslint-disable-line react/require-default-props
  +theme?: ThemeType, // eslint-disable-line react/require-default-props
|};

const InfiniteCircleLoaderArc = ({ className, theme = Theme.Dark }: InfiniteCircleLoaderArcPropType): React.Node => <div className={clsx('infiniteCircleLoaderArc', className, theme)} />;

export default InfiniteCircleLoaderArc;
