/* @flow */

import type { Dispatch, Store } from '../../redux/types/types';
import { FAKE_CHANNEL_ITEM, FAKE_LOCATION } from '../../components/avenue/section/SectionConstsAndTypes';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import type { NETGEM_API_V8_FEED, NETGEM_API_V8_FEED_ITEM } from '../../libs/netgemLibrary/v8/types/FeedItem';
import { addMissingChannels, filterDuplicates } from '../channel/helper';
import { filterChannels, filterLiveFeed, sortFeed, splitFeedByChannels } from '../../libs/netgemLibrary/v8/helpers/Feed';
import { logWarning, showFinishingLivePrograms } from '../debug/debug';
import AccurateTimestamp from '../dateTime/AccurateTimestamp';
import type { CombinedReducers } from '../../redux/reducers';
import { EPG } from '../ui/constants';
import type { NETGEM_API_V8_RAW_FEED } from '../../libs/netgemLibrary/v8/types/FeedResult';
import type { NETGEM_API_V8_SECTION } from '../../libs/netgemLibrary/v8/types/Section';
import { buildFeedItem } from '../../libs/netgemLibrary/v8/helpers/Item';
import { ignoreIfAborted } from '../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import { mapUnion } from '../jsHelpers/dataStructure';
import { searchInFeedRawItem } from './helper';
import sendV8LocationEpgRequest from '../../redux/netgemApi/actions/v8/epg';

class EpgManager {
  abortController: AbortController;

  cachedFeed: NETGEM_API_V8_RAW_FEED;

  // Keys are channel Ids and values are associated feed
  cachedFeedTemp: Map<string, NETGEM_API_V8_RAW_FEED>;

  dispatch: Dispatch;

  // eslint-disable-next-line no-use-before-define
  static instance: EpgManager;

  getState: () => CombinedReducers;

  feedCachedTimer: TimeoutID | null;

  feedLiveTimer: TimeoutID | null;

  isRefreshing: boolean;

  lastLiveFeed: NETGEM_API_V8_FEED;

  constructor(store: Store) {
    const { dispatch, getState } = store;

    this.abortController = new AbortController();
    this.cachedFeed = [];
    this.cachedFeedTemp = new Map();
    this.dispatch = dispatch;
    this.feedCachedTimer = null;
    this.feedLiveTimer = null;
    this.getState = getState;
    this.isRefreshing = false;
    this.lastLiveFeed = [];

    Messenger.on(MessengerEvents.AUTHENTICATION_TOKEN_CHANGED, this.refreshCachedFeed);
    Messenger.on(MessengerEvents.DEBUG_SHOW_FINISHING_LIVE_PROGRAMS, this.handleShowFinishingLivePrograms);
  }

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'EpgManager';
  }

  /*
   * Static methods
   */

  static initialize: (store: Store) => void = (store) => {
    if (EpgManager.instance) {
      return;
    }

    EpgManager.instance = new EpgManager(store);
  };

  static isRefreshing: () => boolean = () => EpgManager.instance?.isRefreshing ?? true;

  static initializeCachedFeed: () => void = () => {
    if (!EpgManager.instance) {
      logWarning('Cannot initialize live cached feed because EPG Manager has not been initialized');
      return;
    }

    EpgManager.instance.refreshCachedFeed();
  };

  static getLiveFeed: (section: NETGEM_API_V8_SECTION, sectionChannels: Set<string>, hubItem: ?NETGEM_API_V8_FEED_ITEM) => NETGEM_API_V8_FEED = (section, sectionChannels, hubItem) => {
    if (!EpgManager.instance) {
      logWarning('Cannot get live feed because EPG Manager has not been initialized');
      return [];
    }

    return EpgManager.instance.getLiveFeed(section, sectionChannels, hubItem);
  };

  // Find the program that satisfies start <= time <= end, with time in seconds
  static findItem: (channelId: string, time: number) => NETGEM_API_V8_FEED_ITEM | null = (channelId, time) => {
    if (!EpgManager.instance) {
      logWarning('Cannot find item because EPG Manager has not been initialized');
      return null;
    }

    return EpgManager.instance.findItem(channelId, time);
  };

  static buildFakeChannelsSection: (section: NETGEM_API_V8_SECTION, sectionChannels: Set<string>) => NETGEM_API_V8_FEED | null = (section, sectionChannels) => {
    if (!EpgManager.instance) {
      logWarning('Cannot build fake channel section because EPG Manager has not been initialized');
      return null;
    }

    return EpgManager.instance.buildFakeChannelsSection(section, sectionChannels);
  };

  /*
   * Class methods
   */

  getEpgChunk: (startTime: number, channelIds: Array<string>) => Promise<Map<string, NETGEM_API_V8_RAW_FEED>> = (startTime, channelIds) => {
    const {
      abortController: { signal },
      dispatch,
    } = this;

    return dispatch(sendV8LocationEpgRequest(startTime, EPG.FeedRange, undefined, channelIds, signal))
      .then((results) => {
        signal.throwIfAborted();

        const feedMap = splitFeedByChannels(((results.result.feed: any): NETGEM_API_V8_RAW_FEED));
        mapUnion(this.cachedFeedTemp, feedMap);

        return Promise.resolve();
      })
      .catch((error) => ignoreIfAborted(signal, error));
  };

  startTimer: () => void = () => {
    if (this.feedCachedTimer !== null) {
      clearTimeout(this.feedCachedTimer);
    }

    this.feedCachedTimer = setTimeout(this.refreshCachedFeed, EPG.IntervalCachedLiveFeed);
  };

  // Load EPG data for all channels, by chunks
  refreshCachedFeed: () => void = () => {
    const {
      appConfiguration: { deviceChannels },
    } = this.getState();

    const channels = Object.values(deviceChannels);

    if (channels.length === 0) {
      logWarning('Cannot initialize live cached feed because channel list is empty');
      this.cachedFeed = [];
      this.startTimer();
      return;
    }

    const visibleChannels = channels.filter(({ isHidden }) => !isHidden);

    if (visibleChannels.length === 0) {
      logWarning('No visible channel. Cached live feed is empty');
      this.cachedFeed = [];
      this.startTimer();
      return;
    }

    const startBase = new Date(AccurateTimestamp.now() - EPG.RewindPeriod);
    startBase.setMinutes(0);
    startBase.setSeconds(0);
    const startTime = startBase.getTime();

    this.isRefreshing = true;

    // Create temporary feed bucket
    this.cachedFeedTemp = new Map();

    const promises = [];
    for (let startIndex = 0; startIndex < visibleChannels.length; startIndex += EPG.ChannelChunkSize) {
      const channelIds = [];
      for (let j = startIndex; j < startIndex + EPG.ChannelChunkSize && j < visibleChannels.length; j++) {
        channelIds.push(visibleChannels[j].epgid);
      }
      promises.push(this.getEpgChunk(startTime, channelIds));
    }

    Promise.allSettled(promises).then(() => {
      this.isRefreshing = false;
      this.cachedFeed.length = 0;
      this.cachedFeedTemp.forEach((feed) => this.cachedFeed.push(...feed));

      this.startTimer();
    });
  };

  getLiveFeed: (section: NETGEM_API_V8_SECTION, sectionChannels: Set<string>, hubItem: ?NETGEM_API_V8_FEED_ITEM) => NETGEM_API_V8_FEED = (section, sectionChannels, hubItem) => {
    const {
      appConfiguration: { deviceChannels },
    } = this.getState();

    // Get live items from cached live feed
    const newFeed = filterLiveFeed(this.cachedFeed, deviceChannels, section.model.slice);

    // Only keep channels present in the section
    filterChannels(newFeed, deviceChannels, sectionChannels);

    if (!hubItem) {
      addMissingChannels(newFeed, sectionChannels);
    }

    // Could happen when programs overlap
    filterDuplicates(newFeed);

    if (section.model.scoring) {
      sortFeed(newFeed, section.model.scoring, { channels: deviceChannels });
    }

    this.lastLiveFeed = newFeed;

    return newFeed;
  };

  // Find the program that satisfies start <= time <= end, with time in seconds
  findItem: (channelId: string, time: number) => NETGEM_API_V8_FEED_ITEM | null = (channelId, time) => {
    const { length } = this.cachedFeed;
    if (length === 0) {
      return null;
    }

    let matchingItem: NETGEM_API_V8_FEED_ITEM | null = null;
    for (let i = 0; i < length; i++) {
      const { [i]: item } = this.cachedFeed;

      matchingItem = searchInFeedRawItem(item, channelId, time);

      if (matchingItem) {
        // Item found, no need to keep searching
        return matchingItem;
      }
    }

    return null;
  };

  buildFakeChannelsSection: (section: NETGEM_API_V8_SECTION, sectionChannels: Set<string>) => NETGEM_API_V8_FEED | null = (section, sectionChannels) => {
    const {
      appConfiguration: { deviceChannels: channels },
    } = this.getState();

    if (sectionChannels === null) {
      // Not ready
      return null;
    }

    if (sectionChannels.size === 0) {
      return [];
    }

    const feed: NETGEM_API_V8_FEED = [];

    // Create fake channel items
    sectionChannels.forEach((channelId) => {
      const fakeItem = {
        ...FAKE_CHANNEL_ITEM,
        id: channelId,
      };
      const fakeLocation = {
        ...FAKE_LOCATION,
        channelId,
      };
      fakeItem.locations = [fakeLocation];
      const feedItem = buildFeedItem(fakeItem);
      if (feedItem) {
        feed.push(feedItem);
      }
    });

    if (section.model.scoring) {
      sortFeed(feed, section.model.scoring, { channels });
    }

    return feed;
  };

  handleShowFinishingLivePrograms: (itemCountPerPage?: number, maxTime?: number, maxItems?: number) => void = (itemCountPerPage, maxTime, maxItems) => {
    showFinishingLivePrograms(this.lastLiveFeed, itemCountPerPage, maxTime, maxItems);
  };
}

export default EpgManager;
