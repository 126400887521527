/* @flow */

/*
 * Chrome's Global Media Controls
 */

import type { BasicCallbackFunction } from '@ntg/utils/dist/types';

const GMC_PLAYBACK_STATE_NONE = 'none';
const GMC_PLAYBACK_STATE_PAUSED = 'paused';
const GMC_PLAYBACK_STATE_PLAYING = 'playing';

export default class MediaController {
  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'MediaController';
  }

  static initialize: (applicationName: string) => void = (applicationName) => {
    const {
      navigator: { mediaSession },
    } = window;

    if (mediaSession) {
      mediaSession.metadata = new window.MediaMetadata({
        album: applicationName,
        // Space is crucial here, as an empty string causes the display of the default title
        title: ' ',
      });

      mediaSession.playbackState = GMC_PLAYBACK_STATE_NONE;
    }
  };

  static reset: () => void = () => {
    const {
      navigator: { mediaSession },
    } = window;

    if (mediaSession) {
      mediaSession.metadata = null;
      mediaSession.playbackState = GMC_PLAYBACK_STATE_NONE;
    }
  };

  static setPlayingState: () => void = () => {
    const {
      navigator: { mediaSession },
    } = window;

    if (mediaSession) {
      mediaSession.playbackState = GMC_PLAYBACK_STATE_PLAYING;
    }
  };

  static setPausedState: () => void = () => {
    const {
      navigator: { mediaSession },
    } = window;

    if (mediaSession) {
      mediaSession.playbackState = GMC_PLAYBACK_STATE_PAUSED;
    }
  };

  static setTitles: (programTitle: ?string, seriesTitle: ?string) => void = (programTitle, seriesTitle) => {
    const {
      navigator: { mediaSession },
    } = window;

    if (mediaSession && mediaSession.metadata) {
      mediaSession.metadata.title = seriesTitle ?? programTitle;
      if (seriesTitle && programTitle && seriesTitle !== programTitle) {
        mediaSession.metadata.artist = programTitle;
      }
    }
  };

  static setImage: (src: string, type: string, width: number, height: number) => void = (src, type, width, height) => {
    const {
      navigator: { mediaSession },
    } = window;

    if (mediaSession && mediaSession.metadata) {
      mediaSession.metadata.artwork = [
        {
          sizes: `${width}x${height}`,
          src,
          type,
        },
      ];
    }
  };

  static setPlayHandler: (callback: BasicCallbackFunction) => void = (callback) => {
    const {
      navigator: { mediaSession },
    } = window;

    if (mediaSession && mediaSession.metadata) {
      mediaSession.setActionHandler('play', callback);
    }
  };

  static setPauseHandler: (callback: BasicCallbackFunction) => void = (callback) => {
    const {
      navigator: { mediaSession },
    } = window;

    if (mediaSession && mediaSession.metadata) {
      mediaSession.setActionHandler('pause', callback);
    }
  };

  static setSeekBackwardHandler: (callback: BasicCallbackFunction) => void = (callback) => {
    const {
      navigator: { mediaSession },
    } = window;

    if (mediaSession && mediaSession.metadata) {
      mediaSession.setActionHandler('seekbackward', callback);
    }
  };

  static setSeekForwardHandler: (callback: BasicCallbackFunction) => void = (callback) => {
    const {
      navigator: { mediaSession },
    } = window;

    if (mediaSession && mediaSession.metadata) {
      mediaSession.setActionHandler('seekforward', callback);
    }
  };

  static setPreviousTrackHandler: (callback?: BasicCallbackFunction) => void = (callback) => {
    const {
      navigator: { mediaSession },
    } = window;

    if (mediaSession && mediaSession.metadata) {
      mediaSession.setActionHandler('previoustrack', callback);
    }
  };

  static setNextTrackHandler: (callback?: BasicCallbackFunction) => void = (callback) => {
    const {
      navigator: { mediaSession },
    } = window;

    if (mediaSession && mediaSession.metadata) {
      mediaSession.setActionHandler('nexttrack', callback);
    }
  };
}
